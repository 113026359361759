import React, {FunctionComponent} from "react";
import {Action, ButtonSize, ButtonStyle, ModalConfirmMessage, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

type ModalDeleteWarningProps = {
    active: boolean;
    message: string;
    onClose: () => void;
    onDelete: () => void;
}

const ModalDeleteWarning: FunctionComponent<ModalDeleteWarningProps> = ({active, onClose, onDelete, message}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <ModalConfirmMessage
            active={active}
            message={message}
            confirm={{size: ButtonSize.BIG, style: ButtonStyle.DEFAULT_MIDNIGHT, onClick: onDelete, children: textCommon(Action.DELETE.labelKey)}}
            cancel={{size: ButtonSize.BIG, style: ButtonStyle.PRIMARY_MIDNIGHT, onClick: onClose, children: textCommon(Action.CANCEL.labelKey)}}
        />
    );
};

export default ModalDeleteWarning;
