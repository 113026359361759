import {ApiModel} from "../../../common/api/model/ApiModel";

export class OrderLimitPerColumn extends ApiModel {
    column_index: number = 0;
    private _limits: Map<string, number> = new Map();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set limits(limits: Map<string, number>) {
        const values = Array.from(Object.entries(limits).length ? new Map(Object.entries(limits)) : limits);
        const newLimits: Map<string, number> = new Map();
        values.forEach((it) => newLimits.set(it[0], it[1]));
        this._limits = newLimits;
    }

    get limits(): Map<string, number> {
        return this._limits;
    }

    getLimitsJson(): {[key: string]: number} {
        let l: {[key: string]: number} = {};
        this._limits.forEach((value, key) => {
            l[key] = value;
        });

        return l;
    }

    getJsonParameters(): {} {
        return {
            column_index: this.column_index,
            limits: this.getLimitsJson()
        };
    }
}
