import {FunctionComponent, useEffect, useState} from "react";
import {ButtonBack, ButtonSize, ButtonValidate, FieldBlock, FormLayoutRows, InputText, ModalActions, ModalActionsAlignment, ModalContent} from "@sirdata/ui-lib";
import {STORAGE_FILTER_NAME_MAX_LENGTH, STORAGE_FILTER_NAME_MIN_LENGTH, StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";

type ModalProcessCountingValidationProps = {
    initCounting: StorageFilter;
    onSubmit: (isError: boolean) => void;
    onGoBack: () => void;
};

const ModalProcessCountingValidation: FunctionComponent<ModalProcessCountingValidationProps> = ({initCounting, onSubmit, onGoBack}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);

    const [countingName, setCountingName] = useState<string>("");
    const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

    useEffect(() => {
        const newCountingName = initCounting.name;
        setCountingName(newCountingName);
    }, [initCounting]);

    const handleSubmit = async () => {
        let isError: boolean = false;
        setLoadingSubmit(true);
        try {
            let newCounting = new StorageFilter();
            newCounting.load(initCounting);
            newCounting.name = countingName.trim();
            await session.restStorageFilter.createStorageFilter(newCounting);
        } catch (e) {
            isError = true;
        } finally {
            setLoadingSubmit(false);
            onSubmit(isError);
        }
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <FieldBlock label={textProcessCounting("validation.name.label")}>
                        <InputText
                            value={countingName}
                            placeholder={textProcessCounting("validation.name.placeholder")}
                            onChange={setCountingName}
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <ButtonBack onClick={onGoBack}/>
                <ButtonValidate
                    size={ButtonSize.MEDIUM}
                    onClick={handleSubmit}
                    disabled={countingName.trim().length < STORAGE_FILTER_NAME_MIN_LENGTH || countingName.trim().length > STORAGE_FILTER_NAME_MAX_LENGTH}
                    loading={isLoadingSubmit}
                />
            </ModalActions>
        </>
    );
};

export default ModalProcessCountingValidation;
