import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalTitle, ModalTitleSize, TranslationLibFile} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalUploadStorageConfirmationProps = {
    isUploadSuccess: boolean;
    onClose: () => void;
};

const ModalUploadStorageConfirmation: FunctionComponent<ModalUploadStorageConfirmationProps> = ({isUploadSuccess, onClose}) => {
    const {t: textStorageUpload} = useTranslation(TranslationPortalFile.STORAGE_UPLOAD);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <ModalContent>
            <ModalTitle size={ModalTitleSize.HUGE}>
                {textStorageUpload(`modal_confirmation.${isUploadSuccess ? "upload_success" : "upload_failed"}.title`)}
            </ModalTitle>
            <FormLayoutRows>
                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                    <span dangerouslySetInnerHTML={{__html: textStorageUpload(`modal_confirmation.${isUploadSuccess ? "upload_success" : "upload_failed"}.description`)}}/>
                </ModalDescription>
                <FormLayoutButtons>
                    <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={onClose}>
                        {textCommon(Action.CLOSE.labelKey)}
                    </Button>
                </FormLayoutButtons>
            </FormLayoutRows>
        </ModalContent>
    );
};

export default ModalUploadStorageConfirmation;
