export class SegmentFilterType {
    static NONE: SegmentFilterType = new SegmentFilterType("NONE", "all_segments");
    static SEGMENT: SegmentFilterType = new SegmentFilterType("SEGMENT", "selected_segments");
    static SEGMENT_TYPE: SegmentFilterType = new SegmentFilterType("SEGMENT_TYPE", "segment_types");

    name: string;
    label: string;

    constructor(name: string, label: string) {
        this.name = name;
        this.label = label;
    }

    static values(): SegmentFilterType[] {
        return [
            SegmentFilterType.NONE,
            SegmentFilterType.SEGMENT,
            SegmentFilterType.SEGMENT_TYPE
        ];
    }
}