import {ApiModel} from "../../../common/api/model/ApiModel";

export class DimensionValue extends ApiModel {
    value: string = "";
    count: number = 0;
    limit?: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

