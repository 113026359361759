import React, {FunctionComponent} from "react";
import {Tag, TagTextTransform} from "@sirdata/ui-lib";
import clsx from "clsx";
import {DataType} from "../../api/model/segment/DataType";

type TagDataTypeProps = {
    type: DataType | undefined;
    active?: boolean;
    huge?: boolean;
    onClick?: (type: string) => void;
};

const TagDataType: FunctionComponent<TagDataTypeProps> = ({type, active, huge, onClick}) => {
    if (!type) return (<></>);

    return (
        <Tag
            cssClass={clsx({"tag-huge": huge})}
            icon={type.icon}
            label={type.name}
            textTransform={TagTextTransform.UPPERCASE}
            onClick={onClick ? () => onClick(type.name) : undefined}
            customColor={active ? {backgroundColor: type.color, color: "var(--color-grey-light)", borderColor: "var(--color-grey-light)"} : undefined}
        />
    );
};

export default TagDataType;
