import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {StorageFilter} from "../../api/model/storage-filter/StorageFilter";
import {session} from "../../api/ApiSession";
import {useNavigate} from "react-router-dom";
import {StorageFilterStatus} from "../../api/model/storage-filter/StorageFilterStatus";
import {Action, ActionsMenu, Color, IconTooltip, Loadable, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {Formatter} from "../../common/utils/Formatter";
import {downloadCSV} from "../../common/utils/portal";

export type CountingTableRowActions = {
    onDuplicate: () => void;
    onProcessOrder: () => void;
    onDelete: () => void;
}

type CountingTableRowProps = {
    counting: StorageFilter;
    actions?: CountingTableRowActions;
}

const CountingTableRow: FunctionComponent<CountingTableRowProps> = ({counting, actions}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCountings} = useTranslation(TranslationPortalFile.COUNTINGS);
    const navigate = useNavigate();
    const [isLoadingDownload, setLoadingDownload] = useState(false);

    const downloadCounting = async () => {
        try {
            setLoadingDownload(true);
            const countingCsv = await session.restStorageFilter.downloadStorageFilter(counting);

            let universalBOM = "\uFEFF";
            let csvFile = universalBOM + countingCsv;
            downloadCSV(counting.name.split(".")[0], csvFile);
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingDownload(false);
        }
    };

    const getStatusColor = () => {
        switch (counting.status) {
            case StorageFilterStatus.OK:
                return Color.GREEN;
            case StorageFilterStatus.ERROR:
            case StorageFilterStatus.DELETED:
                return Color.ORANGE;
            default:
                return Color.GREY;
        }
    };

    return (
        <TableRow onClick={{link: counting.getRoute()}}>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                <StatusLabel label={t(`status.${counting.status}`)} icon={{name: "circle", colorIcon: getStatusColor()}}/>
            </TableColumn>
            <TableColumn>{counting.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{counting.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(counting.creation_ts)}</TableColumn>
            {!!actions ?
                (counting.isActionsAllowed() ?
                    <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                        <Loadable loading={isLoadingDownload} loaderOptions={{small: true}}>
                            <IconTooltip
                                icon={Action.DOWNLOAD.icon}
                                text={textCountings("table.actions.download_result")}
                                onClick={downloadCounting}
                            />
                        </Loadable>
                        <IconTooltip
                            icon={{name: "shopping_cart"}}
                            text={textCountings("table.actions.order_data")}
                            onClick={actions.onProcessOrder}
                        />
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                            items={[
                                {label: textCountings("table.actions.view_countings"), onClick: () => navigate(counting.getRoute())},
                                {label: textCountings("table.actions.duplicate"), onClick: actions.onDuplicate},
                                {label: textCommon(Action.DELETE.labelKey), onClick: actions.onDelete, critical: true, separator: true}
                            ]}
                        />
                    </TableActionColumn> :
                    <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                            items={[
                                {label: textCountings("table.actions.view_countings"), onClick: () => navigate(counting.getRoute())},
                                {label: textCommon(Action.DELETE.labelKey), onClick: actions.onDelete, critical: true, separator: true}
                            ]}
                        />
                    </TableActionColumn>
                ) : <></>
            }
        </TableRow>
    );
};

export default CountingTableRow;
