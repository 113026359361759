import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SegmentFilterSegment} from "./SegmentFilterSegment";

export class StorageSegmentFilter extends ApiModel {
    private _segment: SegmentFilterSegment[] | null = [];
    private _segment_modeling: SegmentFilterSegment[] | null = [];
    private _data_type: string[] | null = [];
    private _tiers1: string[] | null = [];
    private _tiers2: string[] | null = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get segment() : SegmentFilterSegment[] {
        return this._segment ? this._segment : [];
    }

    set segment(segment: SegmentFilterSegment[]) {
        this._segment = !!segment.length ? segment : null;
    }

    get segment_modeling() : SegmentFilterSegment[] {
        return this._segment_modeling ? this._segment_modeling : [];
    }

    set segment_modeling(segment_modeling: SegmentFilterSegment[]) {
        this._segment_modeling = !!segment_modeling.length ? segment_modeling : null;
    }

    get data_type() : string[] {
        return this._data_type ? this._data_type : [];
    }

    set data_type(data_type: string[]) {
        this._data_type = !!data_type.length ? data_type : null;
    }

    get tiers1() : string[] {
        return this._tiers1 ? this._tiers1 : [];
    }

    set tiers1(tiers1: string[]) {
        this._tiers1 = !!tiers1.length ? tiers1 : null;
    }

    get tiers2() : string[] {
        return this._tiers2 ? this._tiers2 : [];
    }

    set tiers2(tiers2: string[]) {
        this._tiers2 = !!tiers2.length ? tiers2 : null;
    }

    getJsonParameters(): {} {
        return {
            segment: this.segment,
            segment_modeling: this.segment_modeling,
            data_type: this.data_type,
            tiers1: this.tiers1,
            tiers2: this.tiers2
        };
    }
}
