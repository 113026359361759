import {FunctionComponent, useEffect, useState} from "react";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {ButtonBack, ButtonSize, ButtonValidate, ContentBlock, FieldBlock, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, ModalActions, ModalActionsAlignment, ModalContent, ModalDescription, Select, Table} from "@sirdata/ui-lib";

type ModalProcessCountingColumnsSelectionProps = {
    initCounting: StorageFilter;
    onSubmit: (counting: StorageFilter) => void;
    onGoBack: () => void;
}

const PREVIEW_ROW_COUNT = 5;
const SELECTED_COLUMNS_MAX_LENGTH = 2;

const ModalProcessCountingColumnsSelection: FunctionComponent<ModalProcessCountingColumnsSelectionProps> = ({initCounting, onSubmit, onGoBack}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);

    const [columnNames, setColumnNames] = useState<string[]>([]);
    const [filteredColumnNames, setFilteredColumnNames] = useState<string[]>([]);
    const [storageFirstRows, setStorageFirstRows] = useState<string[][]>([]);

    const [selectedColumnsIndexes, setSelectedColumnsIndexes] = useState<number[]>([]);

    useEffect(() => {
        const newSelectedColumnsIndexes = initCounting.dimension_indexes;
        setSelectedColumnsIndexes(newSelectedColumnsIndexes);

        const newStorageFirstRows = [...initCounting.storage.table.row_sample].slice(0, PREVIEW_ROW_COUNT);
        setStorageFirstRows(newStorageFirstRows);

        const mappingColumnIndex = initCounting.storage.table.mapping_column_index;
        const newColumnNames = [...initCounting.storage.table.column_names];
        setColumnNames(newColumnNames);

        let newFilteredColumnsNames = [...newColumnNames];
        newFilteredColumnsNames.splice(mappingColumnIndex, 1);
        setFilteredColumnNames(newFilteredColumnsNames);
    }, [initCounting]);

    const handleChangeSelectedColumnsIndexes = (index: number, value?: number) => {
        setSelectedColumnsIndexes((prev) => {
            let newSelectedColumnsIndexes = [...prev];
            if (value !== undefined) {
                newSelectedColumnsIndexes[index] = value;
                if (newSelectedColumnsIndexes.every((it) => it === value)) {
                    newSelectedColumnsIndexes.length = 1;
                }
            } else {
                newSelectedColumnsIndexes.length = index;
            }
            return newSelectedColumnsIndexes;
        });
    };

    const handleSubmit = () => {
        let newCounting = new StorageFilter();
        newCounting.load(initCounting);
        newCounting.dimension_indexes = selectedColumnsIndexes;
        onSubmit(newCounting);
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textProcessCounting("columns_selection.description")}}/>
                    </ModalDescription>
                    <FormLayoutSeparator/>
                    <FormLayoutColumns columns={2}>
                        {Array.from(new Array(selectedColumnsIndexes.length + 1)).slice(0, SELECTED_COLUMNS_MAX_LENGTH).map((_, index) =>
                            (filteredColumnNames.length > index &&
                                <FieldBlock label={textProcessCounting("columns_selection.column", {value: index + 1})} key={`filter-column-${index + 1}`}>
                                    <Select
                                        value={selectedColumnsIndexes[index]}
                                        options={filteredColumnNames.filter((name) => (index === 0) || (selectedColumnsIndexes.slice(0, index).every((nameIndex) => columnNames[nameIndex] !== name))).map((name) => {
                                            return {value: columnNames.findIndex((it) => it === name), label: name};
                                        })}
                                        onChange={(option) => handleChangeSelectedColumnsIndexes(index, option ? +option?.value : undefined)}
                                        clearable
                                    />
                                </FieldBlock>
                            )
                        )}
                    </FormLayoutColumns>
                    <ContentBlock header={{title: {label: textProcessCounting("columns_selection.result")}}}>
                        <Table
                            columns={[
                                ...selectedColumnsIndexes.map((colIndex) => {
                                    return {label: columnNames[colIndex]};
                                }),
                                {label: textProcessCounting("columns_selection.table.headers.segment")},
                                {label: textProcessCounting("columns_selection.table.headers.count")}
                            ]}
                        >
                            {storageFirstRows.map((row, rowIndex) => {
                                const trKey = `row-${rowIndex}`;
                                return (
                                    <tr key={trKey}>
                                        {selectedColumnsIndexes.map((colIndex) => (
                                            <td key={`${trKey}.${colIndex}`}>{row[colIndex]}</td>
                                        ))}
                                        <td>{((rowIndex + 73) * 43) + (rowIndex + rowIndex) * rowIndex * (rowIndex + rowIndex) * rowIndex}</td>
                                        <td>-</td>
                                    </tr>
                                );
                            })}
                        </Table>
                    </ContentBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <ButtonBack onClick={onGoBack}/>
                <ButtonValidate size={ButtonSize.MEDIUM} onClick={handleSubmit}/>
            </ModalActions>
        </>
    );
};

export default ModalProcessCountingColumnsSelection;
