import {routeParamId} from "../common/utils/constants";

/* ROUTES */
export const routeParamStorageId = "/:storageId";
export const routeParamCountingId = "/:countingId";
export const pathCountings = "/countings";

export const pathOrders = "/orders";
export const pathStorages = "/storages";
export const pathStoragesWithId = pathStorages + routeParamId;
export const pathCountingsWithIds = pathStorages + routeParamStorageId + pathCountings + routeParamCountingId;
export const pathOrdersWithId = pathOrders + routeParamId;

/* TRANSLATIONS */
export enum TranslationPortalFile {
    ACCESS = "access",
    TRANSLATION = "translation",
    COUNTINGS = "countings",
    COUNTINGS_DETAILS = "countings-details",
    HOME = "home",
    LEGAL_NOTICE = "legal-notice",
    LOGIN = "login",
    ORDERS = "orders",
    PRICING = "pricing",
    PROCESS_COUNTING = "process-counting",
    PROCESS_ORDER = "process-order",
    STORAGE_UPLOAD = "storage-upload",
    STORAGES = "storages",
    STORAGES_DETAILS = "storages-details"
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.ACCESS,
    TranslationPortalFile.COUNTINGS,
    TranslationPortalFile.COUNTINGS_DETAILS,
    TranslationPortalFile.HOME,
    TranslationPortalFile.LEGAL_NOTICE,
    TranslationPortalFile.LOGIN,
    TranslationPortalFile.ORDERS,
    TranslationPortalFile.PRICING,
    TranslationPortalFile.PROCESS_COUNTING,
    TranslationPortalFile.PROCESS_ORDER,
    TranslationPortalFile.STORAGE_UPLOAD,
    TranslationPortalFile.STORAGES,
    TranslationPortalFile.STORAGES_DETAILS,
    TranslationPortalFile.TRANSLATION
];
