import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {pathStorages, TranslationPortalFile} from "../../utils/constants";
import {Storage} from "../../api/model/storage/Storage";
import {useNavigate, useParams} from "react-router-dom";
import {MainContent, Wrapper} from "../../common/component/widget";
import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    BoxProps,
    Button,
    ButtonSize,
    ButtonStyle,
    Color,
    ContentBlock,
    EmptyContentMessage,
    FieldBlock,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    InputDate,
    LayoutRows,
    Loadable,
    MainContentStyle,
    ModalConfirmMessage,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    StatusLabel,
    Table,
    TableColumn,
    TableRow,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {session} from "../../api/ApiSession";
import {StorageStatus} from "../../api/model/storage/StorageStatus";
import {CountingsTable} from "../../component/snippet";
import {Formatter} from "../../common/utils/Formatter";
import {MainHeader} from "../../common/component/snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {StorageFilter} from "../../api/model/storage-filter/StorageFilter";
import {ModalProcessCounting} from "../../component/modal";
import {StorageFilterListQuery} from "../../api/model/storage-filter/search/StorageFilterListQuery";
import {SvgCountings} from "../../component/svg";
import {STORAGE_FILTER_STATUSES} from "../../api/model/storage-filter/StorageFilterStatus";

const MAX_STORAGE_OVERVIEW_ROWS = 5;

function StoragesDetails() {
    const navigate = useNavigate();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textStoragesDetails} = useTranslation(TranslationPortalFile.STORAGES_DETAILS);
    const {t: textCountings} = useTranslation(TranslationPortalFile.COUNTINGS);
    const {id: storageId} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);
    const [isLoadingCountings, setLoadingCountings] = useState(false);

    const [storage, setStorage] = useState<Storage>();

    const [storageFilterListQuery, setStorageFilterListQuery] = useState<StorageFilterListQuery>(new StorageFilterListQuery());
    const [currentCountings, setCurrentCountings] = useState<StorageFilter[]>([]);

    const [distinctMappingColumn, setDistinctMappingColumn] = useState<number>(0);
    const [distinctMappingColumnMatched, setDistinctMappingColumnMatched] = useState<number>(0);

    const [activeCopyStorage, setActiveCopyStorage] = useState<boolean>(false);
    const [activeModalProcessCounting, setActiveModalProcessCounting] = useState<StorageFilter>();

    const getStatus = (storage: Storage) => {
        switch (storage.status) {
            case StorageStatus.ERROR:
            case StorageStatus.DELETED:
                return <StatusLabel label={t(`status.${storage.status}`)} icon={{name: "circle", colorIcon: Color.ORANGE}}/>;
            case StorageStatus.OK:
                if (storage.isExpired()) {
                    return <StatusLabel label={t("status.EXPIRED")} icon={{name: "circle", colorIcon: Color.ORANGE}}/>;
                }
                return <StatusLabel label={t(`status.${storage.status}`)} icon={{name: "circle", colorIcon: Color.GREEN}}/>;
            default:
                return <StatusLabel label={t(`status.${storage.status}`)} icon={{name: "circle", colorIcon: Color.GREY}}/>;
        }
    };

    const loadCountings = useCallback(async () => {
        if (!storage) return;
        try {
            setLoadingCountings(true);
            let countings = await session.restStorageFilter.getStorageFilters(storageFilterListQuery, storage.id.toString());
            setCurrentCountings(countings);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                UIEventManager.alert(textCountings("error.get_countings"), AlertSeverity.DANGER);
            }
        } finally {
            setLoadingCountings(false);
        }
    }, [textCountings, storageFilterListQuery, storage]);

    const doCopyStorage = async () => {
        if (!storage?.id || !storage.isCopiable()) return;
        setLoading(true);
        try {
            const copy = await session.restStorage.copyStorage(storage.id);
            const newStorage = new Storage({...copy});
            setTimeout(() => navigate(newStorage.getRoute()), 5000);
            navigate(newStorage.getRoute());
        } catch (e) {
            UIEventManager.alert(textStoragesDetails("error.reimport_storage"), AlertSeverity.DANGER);
        } finally {
            setActiveCopyStorage(false);
            setLoading(false);
        }
    };

    const handleChangeDates = (name: string, value: string) => {
        setStorageFilterListQuery((prevState) => {
            const query = new StorageFilterListQuery();
            query.load({...prevState, [name]: value});
            return query;
        });
    };

    const handleActiveProcessCounting = (initCounting?: StorageFilter) => {
        if (!storage) return;
        let newCounting = new StorageFilter(initCounting);
        newCounting.storage = storage;
        newCounting.storage_id = storage.id;
        setActiveModalProcessCounting(newCounting);
    };

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const storage = await session.restStorage.getStorage(storageId);
                setStorage(storage);
                setDistinctMappingColumn(storage.table.distinct_mapping_column_values);
                setDistinctMappingColumnMatched(storage.table_intersect?.segment?.distinct_mapping_column_values);
            } catch (e) {
                navigate(pathStorages);
            } finally {
                setLoading(false);
            }
        })();
    }, [navigate, storageId, textStoragesDetails]);

    useEffect(() => {
        (async function () {
            await loadCountings();
        })();
    }, [loadCountings]);

    if (!storage) return <></>;

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock
                            header={{
                                title: {label: textStoragesDetails("general_information.title")},
                                actions: (storage.isCopiable() ?
                                    <Button
                                        style={ButtonStyle.PRIMARY_GREEN}
                                        size={ButtonSize.SMALL}
                                        icon={Action.COPY.icon}
                                        onClick={() => setActiveCopyStorage(true)}
                                    >{textStoragesDetails("reimport_storage")}
                                    </Button> : undefined
                                )
                            }}
                        >
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <FormLayoutRows>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textStoragesDetails("general_information.name")}>
                                            {storage.name}
                                        </FieldBlock>
                                        <FieldBlock label={textStoragesDetails("general_information.status")}>
                                            {getStatus(storage)}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    {storage.status === StorageStatus.ERROR &&
                                        <Alert text={textStoragesDetails(`error_code.${storage.error_code}`, textStoragesDetails("error_code.default"))} severity={AlertSeverity.WARNING} fullWidth/>
                                    }
                                    <FormLayoutColumns>
                                        <FieldBlock label={textStoragesDetails("general_information.creation_date")}>
                                            {Formatter.formatDate(storage.creation_ts)}
                                        </FieldBlock>
                                        <FieldBlock label={textStoragesDetails("general_information.expiration_date")}>
                                            {storage.expiration_ts ? Formatter.formatDate(storage.expiration_ts) : "-"}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    {storage.isExpired() &&
                                        <Alert text={textStoragesDetails("warning_expired", {limitDate: Formatter.formatDate(storage.copyable_limit_date)})} severity={AlertSeverity.WARNING} fullWidth/>
                                    }
                                    <FormLayoutSeparator/>
                                    <FormLayoutTitle>{textStoragesDetails("general_information.data_import.title")}</FormLayoutTitle>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textStoragesDetails("general_information.data_import.mapping_type")}>
                                            {textStoragesDetails(`mapping_type.${storage.mapping_type}`)}
                                        </FieldBlock>
                                        <FieldBlock label={textStoragesDetails("general_information.data_import.distinct_mapping_column_matched")}>
                                            {`${Formatter.formatNumber(!!distinctMappingColumnMatched ? distinctMappingColumnMatched / distinctMappingColumn * 100 : 0)}% (${Formatter.formatNumber(distinctMappingColumnMatched)} / ${Formatter.formatNumber(distinctMappingColumn)})`}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                </FormLayoutRows>
                            </Box>
                        </ContentBlock>
                        <ModalConfirmMessage
                            active={activeCopyStorage}
                            message={textStoragesDetails("reimport_storage_warning")}
                            confirm={{size: ButtonSize.BIG, style: ButtonStyle.PRIMARY_GREEN, onClick: doCopyStorage, children: textStoragesDetails("reimport_storage_warning")}}
                            cancel={{size: ButtonSize.BIG, style: ButtonStyle.DEFAULT_MIDNIGHT, onClick: () => setActiveCopyStorage(false), children: textCommon(Action.CANCEL.labelKey)}}
                        />
                        {storage.table.row_sample &&
                            <ContentBlock header={{title: {label: textStoragesDetails("storage_overview")}}}>
                                <Table columns={storage.table.column_names.map((columnName) => ({label: columnName}))}>
                                    {storage.table.row_sample.slice(0, MAX_STORAGE_OVERVIEW_ROWS).map((row, rowIndex) => {
                                        const tableRowKey = `storage-row-${rowIndex}`;
                                        return(
                                            <TableRow key={tableRowKey}>
                                                {row.map((value, index) => (
                                                    <TableColumn key={`${tableRowKey}.${index.toString()}`}>{value}</TableColumn>
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </ContentBlock>
                        }
                        <ContentBlock header={{title: {label: textCountings("storage_countings")}}}>
                            <SearchToolbar
                                searchBar={{placeholder: textCountings("search_for_countings"), value: storageFilterListQuery.search, onChange: (query) => setStorageFilterListQuery((prevState) => new StorageFilterListQuery({...prevState, search: query}))}}
                                actions={storage.isActionsAllowed() ?
                                    [
                                        <Button
                                            key={"btnNewCounting"}
                                            size={ButtonSize.MEDIUM}
                                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                                            onClick={() => handleActiveProcessCounting()}
                                            icon={{name: "add_circle"}}
                                        >
                                            {textCountings("btn_new_counting")}
                                        </Button>
                                    ] : []}
                            >
                                <SearchField label={textCountings("status")}>
                                    <Select
                                        value={storageFilterListQuery.status}
                                        options={STORAGE_FILTER_STATUSES.map((value) => {
                                            return {value: value, label: t(`status.${value}`)};
                                        })}
                                        onChange={(option) => setStorageFilterListQuery((prevState) => new StorageFilterListQuery({...prevState, status: option?.value || ""}))}
                                        clearable
                                    />
                                </SearchField>
                                <SearchField label={textCountings("start_date")}>
                                    <InputDate
                                        value={storageFilterListQuery.start_date}
                                        onChange={(value) => handleChangeDates("start_date", value)}
                                    />
                                </SearchField>
                                <SearchField label={textCountings("end_date")}>
                                    <InputDate
                                        value={storageFilterListQuery.end_date}
                                        onChange={(value) => handleChangeDates("end_date", value)}
                                    />
                                </SearchField>
                            </SearchToolbar>
                            <Loadable loading={isLoadingCountings}>
                                {currentCountings.length > 0 ?
                                    <CountingsTable
                                        countings={currentCountings}
                                        onRefresh={loadCountings}
                                        onProcessCounting={(initCounting) => handleActiveProcessCounting(initCounting)}
                                    /> :
                                    (storageFilterListQuery.isInitialized() ?
                                        <EmptyContentMessage svg={SvgCountings} message={textCountings(`empty_countings.${storage.isExpired() ? "expired_storage_message" : "storage_message"}`, {storage: storage.name})}/> :
                                        <SearchError/>
                                    )
                                }
                            </Loadable>
                        </ContentBlock>
                        <ModalProcessCounting
                            initCounting={activeModalProcessCounting}
                            onClose={(refresh) => {
                                setActiveModalProcessCounting(undefined);
                                refresh && loadCountings();
                            }}
                        />
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default StoragesDetails;

