import {ApiModel} from "../../../common/api/model/ApiModel";
import {Storage} from "../storage/Storage";
import {StorageColumnFilter} from "./column/StorageColumnFilter";
import {StorageSegmentFilter} from "./segment/StorageSegmentFilter";
import {StorageFilterStorage} from "./storage/StorageFilterStorage";
import {StorageFilterTable} from "./StorageFilterTable";
import {pathCountingsWithIds, routeParamCountingId, routeParamStorageId} from "../../../utils/constants";
import {StorageFilterStatus} from "./StorageFilterStatus";
import {StorageFilterType} from "./StorageFilterType";
import {Order} from "../order/Order";
import {StorageFilterErrorCode} from "./StorageFilterErrorCode";
import {StorageFilterOrder} from "./order/StorageFilterOrder";

export const STORAGE_FILTER_NAME_MIN_LENGTH = 1;
export const STORAGE_FILTER_NAME_MAX_LENGTH = 255;

export class StorageFilter extends ApiModel {
    id: number = 0;
    name: string = "";
    creation_ts: string = "";
    last_update_ts: string = "";
    status: string = StorageFilterStatus.PENDING;
    type: string = StorageFilterType.SEGMENT;
    error_code: string = StorageFilterErrorCode.NONE;
    error_message: string = "";
    storage_id: number = 0;
    private _storage: Storage = new Storage();
    private _dimension_indexes: number[] | null = [];
    private _storage_filter: StorageFilterStorage[] | null = [];
    private _order_filter: StorageFilterOrder[] | null = [];
    private _segment_filter: StorageSegmentFilter | null = new StorageSegmentFilter();
    private _column_filter: StorageColumnFilter[] | null = [];
    table: StorageFilterTable = new StorageFilterTable();
    private _orders: Order[] | null = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get storage(): Storage {
        return this._storage ? this._storage : new Storage();
    }

    set storage(storage: Storage) {
        this._storage = new Storage(storage);
    }

    get dimension_indexes(): number[] {
        return this._dimension_indexes ? this._dimension_indexes : [];
    }

    set dimension_indexes(dimension_indexes: number[]) {
        this._dimension_indexes = !!dimension_indexes.length ? dimension_indexes : null;
    }

    get storage_filter(): StorageFilterStorage[] {
        return this._storage_filter ? this._storage_filter : [];
    }

    set storage_filter(storage_filter: StorageFilterStorage[]) {
        this._storage_filter = !!storage_filter.length ? storage_filter : null;
    }

    get order_filter(): StorageFilterOrder[] {
        return this._order_filter ? this._order_filter : [];
    }

    set order_filter(order_filter: StorageFilterOrder[]) {
        this._order_filter = !!order_filter.length ? order_filter : null;
    }

    get segment_filter(): StorageSegmentFilter {
        return this._segment_filter ? this._segment_filter : new StorageSegmentFilter();
    }

    set segment_filter(segment_filter: StorageSegmentFilter) {
        this._segment_filter = segment_filter;
    }

    get column_filter(): StorageColumnFilter[] {
        return this._column_filter ? this._column_filter.map((it) => new StorageColumnFilter(it)) : [];
    }

    set column_filter(column_filter: StorageColumnFilter[]) {
        this._column_filter = !!column_filter.length ? column_filter : null;
    }

    get orders(): Order[] {
        return this._orders ? this._orders : [];
    }

    set orders(orders: Order[]) {
        this._orders = !!orders.length ? orders : null;
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            type: this.type,
            error_code: this.error_code,
            error_message: this.error_message,
            dimension_indexes: this.dimension_indexes,
            storage_filter: this.storage_filter.map((it) => it.getJsonParameters()),
            segment_filter: this.segment_filter.getJsonParameters(),
            column_filter: this.column_filter.map((it) => it.getJsonParameters()),
            order_filter: this.order_filter.map((it) => it.getJsonParameters())
        };
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getRoute(): string {
        let path = pathCountingsWithIds;
        path = path.replace(routeParamStorageId, `/${this.storage_id}`);
        path = path.replace(routeParamCountingId, `/${this.id}`);
        return path;
    }

    getOrder(): Order {
        let initOrder = new Order();
        initOrder.storage_id = this.storage_id;
        initOrder.filter_id = this.id;
        initOrder.filter = this;
        return initOrder;
    }

    isActionsAllowed(): boolean {
        return this.status === StorageFilterStatus.OK && this.storage.isActionsAllowed();
    }
}
