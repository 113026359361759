import {ModalNewSize} from "@sirdata/ui-lib";
import {ModalStep} from "./ModalStep";

export class ProcessOrderStep extends ModalStep {
    static COLUMN_LIMITS: ProcessOrderStep = new ProcessOrderStep("COLUMN_LIMITS", "column_limits").withModalSize(ModalNewSize.MAX_WIDTH);
    static VALIDATION: ProcessOrderStep = new ProcessOrderStep("VALIDATION", "validation");
    static CONFIRMATION: ProcessOrderStep = new ProcessOrderStep("CONFIRMATION", "confirmation");

    label: string;

    constructor(name: string, label: string) {
        super(name);
        this.label = label;
    }

    static values(): ProcessOrderStep[] {
        return [
            ProcessOrderStep.COLUMN_LIMITS,
            ProcessOrderStep.VALIDATION,
            ProcessOrderStep.CONFIRMATION
        ];
    }
}

