import {FunctionComponent, useEffect, useRef, useState} from "react";
import {
    Action,
    Alert,
    AlertSeverity,
    ButtonBack,
    ButtonSize,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    FormLayoutRows,
    FormLayoutSeparator,
    Icon,
    IconTooltip,
    Loadable,
    ModalActions,
    ModalActionsAlignment,
    ModalContent,
    ModalDescription,
    RadioButtons,
    SearchAutocomplete,
    SearchIds,
    SearchIdsResult
} from "@sirdata/ui-lib";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {StorageSegmentFilter} from "../../../api/model/storage-filter/segment/StorageSegmentFilter";
import {SegmentFilterType} from "../../../api/model/storage-filter/segment/SegmentFilterType";
import {DataType} from "../../../api/model/segment/DataType";
import {session} from "../../../api/ApiSession";
import {Segment} from "../../../api/model/segment/Segment";
import {SegmentFilterSegment} from "../../../api/model/storage-filter/segment/SegmentFilterSegment";
import {TagDataType, TagSegment} from "../../snippet";
import {audienceHome} from "../../../utils/helper";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ModalProcessCountingSegmentsFiltersProps = {
    initCounting: StorageFilter;
    onSubmit: (counting: StorageFilter) => void;
    onGoBack?: () => void;
};

type SegmentItem = {
    segment: Segment;
    limit: number | null;
};

const ModalProcessCountingSegmentsFilters: FunctionComponent<ModalProcessCountingSegmentsFiltersProps> = ({initCounting, onSubmit, onGoBack}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);
    const [isLoading, setLoading] = useState<boolean>(true);
    const scrollToRef = useRef<HTMLDivElement | null>(null);

    const [currentSegmentFilterTypeName, setCurrentSegmentFilterTypeName] = useState<string>(SegmentFilterType.NONE.name);

    const [selectedSegments, setSelectedSegments] = useState<SegmentItem[]>([]);
    const [selectedModelingSegments, setSelectedModelingSegments] = useState<SegmentItem[]>([]);
    const [selectedDataTypes, setSelectedDataTypes] = useState<string[]>([]);

    const [segments, setSegments] = useState<Segment[]>([]);
    const [currentSegments, setCurrentSegments] = useState<Segment[]>([]);

    const [highlightedSegments, setHighlightedSegments] = useState<SegmentItem[]>();
    const [segmentIdsSearchResult, setSegmentIdsSearchResult] = useState<SearchIdsResult>();

    useEffect(() => {
        (async function () {
            try {
                const result = await session.restSegment.getSegments();
                setSegments(result.segments);
                setCurrentSegments(result.segments);

                const segmentFilter = initCounting.segment_filter;
                let segmentFilterType = !!segmentFilter.data_type.length ? SegmentFilterType.SEGMENT_TYPE : (
                    (!!segmentFilter.segment.length || !!segmentFilter.segment_modeling.length) ? SegmentFilterType.SEGMENT : SegmentFilterType.NONE
                );
                setCurrentSegmentFilterTypeName(segmentFilterType.name);

                setSelectedDataTypes(segmentFilter.data_type);
                if (!!segmentFilter.segment.length) {
                    const newSelectedSegments: SegmentItem[] = [];
                    segmentFilter.segment.forEach(({id, limit}) => {
                        const segment = result.segments.find((it) => it.id === id);
                        if (!!segment) {
                            newSelectedSegments.push({segment: segment, limit: limit});
                        }
                    });
                    setSelectedSegments(newSelectedSegments);
                }
                if (!!segmentFilter.segment_modeling.length) {
                    const newSelectedModelingSegments: SegmentItem[] = [];
                    segmentFilter.segment_modeling.forEach(({id, limit}) => {
                        const segment = result.segments.find((it) => it.id === id);
                        if (!!segment) {
                            newSelectedModelingSegments.push({segment: segment, limit: limit});
                        }
                    });
                    setSelectedModelingSegments(newSelectedModelingSegments);
                }
            } catch (e) {
                console.error("Failed to load segments", e);
            } finally {
                setLoading(false);
            }
        })();
    }, [initCounting]);

    const addSegments = (segments: Segment[]) => {
        const segmentItems = segments.map((it) => ({segment: it, limit: null}));
        const deduplicatedSegmentList = segmentItems.filter((it) => [...selectedSegments, ...selectedModelingSegments].every(({segment}) => segment.id !== it.segment.id));
        setSelectedSegments([...selectedSegments, ...deduplicatedSegmentList.filter(({segment}) => segment.data_type !== DataType.MODELING.name)]);
        setSelectedModelingSegments([...selectedModelingSegments, ...deduplicatedSegmentList.filter(({segment}) => segment.data_type === DataType.MODELING.name)]);
        setTimeout(() => {
            setHighlightedSegments([]);
        }, 800);
        setHighlightedSegments(deduplicatedSegmentList);
    };

    const changeLimitSegmentRow = (rowIndex: number, newLimit: number | null, isModeling?: boolean) => {
        if (isModeling) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments[rowIndex].limit = newLimit;
                return newSegments;
            });
        } else {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments[rowIndex].limit = newLimit;
                return newSegments;
            });
        }
    };

    const moveFirstPositionSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (isModeling) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                const segment = newSegments[rowIndex];
                newSegments.splice(rowIndex, 1);
                newSegments = [segment, ...newSegments];
                return newSegments;
            });
        } else {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                const segment = newSegments[rowIndex];
                newSegments.splice(rowIndex, 1);
                newSegments = [segment, ...newSegments];
                return newSegments;
            });
        }
    };

    const moveDownSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (isModeling && selectedModelingSegments.length > rowIndex + 1) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                const segmentRow = newSegments[rowIndex];
                newSegments[rowIndex] = newSegments[rowIndex + 1];
                newSegments[rowIndex + 1] = segmentRow;
                return newSegments;
            });
        } else if (!isModeling && selectedSegments.length > rowIndex + 1) {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                const segmentRow = newSegments[rowIndex];
                newSegments[rowIndex] = newSegments[rowIndex + 1];
                newSegments[rowIndex + 1] = segmentRow;
                return newSegments;
            });
        }
    };

    const moveUpSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (rowIndex > 0) {
            if (isModeling) {
                setSelectedModelingSegments((prevState) => {
                    let newSegments = [...prevState];
                    const segmentRow = newSegments[rowIndex];
                    newSegments[rowIndex] = newSegments[rowIndex - 1];
                    newSegments[rowIndex - 1] = segmentRow;
                    return newSegments;
                });
            } else {
                setSelectedSegments((prevState) => {
                    let newSegments = [...prevState];
                    const segmentRow = newSegments[rowIndex];
                    newSegments[rowIndex] = newSegments[rowIndex - 1];
                    newSegments[rowIndex - 1] = segmentRow;
                    return newSegments;
                });
            }
        }
    };

    const removeSegmentRow = (rowIndex: number, isModeling?: boolean) => {
        if (isModeling) {
            setSelectedModelingSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments.splice(rowIndex, 1);
                return newSegments;
            });
        } else {
            setSelectedSegments((prevState) => {
                let newSegments = [...prevState];
                newSegments.splice(rowIndex, 1);
                return newSegments;
            });
        }
    };

    const handleToggleSegmentDataType = (dataType: string) => {
        if (selectedDataTypes.find((it) => it === dataType)) {
            setSelectedDataTypes(selectedDataTypes.filter((it) => it !== dataType));
        } else {
            setSelectedDataTypes([...selectedDataTypes, dataType]);
        }
    };
    const handleAddSegments = (result: SearchIdsResult) => {
        addSegments(result.matchedItems);
        setSegmentIdsSearchResult(result);
        setTimeout(() => setSegmentIdsSearchResult(undefined), 5000);
    };

    const handleChangeQuery = (query: string) => {
        const queryId = parseInt(query);
        if (!isNaN(queryId)) {
            const segment = segments.find(({id}) => id === queryId);
            setCurrentSegments(segment ? [segment] : []);
        } else {
            setCurrentSegments([...segments]);
        }
    };

    const handleSubmit = () => {
        let newCounting = new StorageFilter();
        newCounting.load(initCounting);

        let newSegmentFilter = new StorageSegmentFilter();
        if (currentSegmentFilterTypeName === SegmentFilterType.SEGMENT.name) {
            newSegmentFilter.segment = selectedSegments.map(({segment, limit}) => {
                const newSegment = new SegmentFilterSegment();
                newSegment.id = segment.id;
                newSegment.limit = limit;
                return newSegment;
            });
            newSegmentFilter.segment_modeling = selectedModelingSegments.map(({segment, limit}) => {
                const newSegment = new SegmentFilterSegment();
                newSegment.id = segment.id;
                newSegment.limit = limit;
                return newSegment;
            });
        }
        if (currentSegmentFilterTypeName === SegmentFilterType.SEGMENT_TYPE.name) {
            newSegmentFilter.data_type = selectedDataTypes;
        }
        newCounting.segment_filter = newSegmentFilter;

        onSubmit(newCounting);
    };

    const handleChangeSegmentFilterTypeName = (value: string) => {
        setCurrentSegmentFilterTypeName(value);
        setTimeout(() => scrollToRef?.current?.scrollIntoView({behavior: "smooth"}), 80);
    };

    const hasEmptyFields = () => {
        let hasEmptyFields = false;
        switch (currentSegmentFilterTypeName) {
            case SegmentFilterType.SEGMENT.name:
                hasEmptyFields = !selectedSegments.length && !selectedModelingSegments.length;
                break;
            case SegmentFilterType.SEGMENT_TYPE.name:
                hasEmptyFields = !selectedDataTypes.length;
                break;
            default:
                break;
        }
        return hasEmptyFields;
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textProcessCounting("segments_filters.description")}}/>
                    </ModalDescription>
                    <Alert text={textProcessCounting("segments_filters.important")} severity={AlertSeverity.WARNING}/>
                    <FormLayoutSeparator/>
                    <Loadable loading={isLoading}>
                        <div ref={scrollToRef} style={{display: "none"}}/>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            <FieldBlock label={textProcessCounting("segments_filters.counting_type")}>
                                <RadioButtons
                                    id="currentSegmentFilterTypeName"
                                    value={currentSegmentFilterTypeName}
                                    options={SegmentFilterType.values().map((it) => {
                                        return {value: it.name, label: textProcessCounting(`segments_filters_types.${it.label}`)};
                                    })}
                                    onChange={(value) => handleChangeSegmentFilterTypeName(value as string)}
                                />
                            </FieldBlock>
                            {currentSegmentFilterTypeName === SegmentFilterType.SEGMENT.name &&
                            <div className="form-counting-filter-group">
                                <div className="form-counting-filter-group-description">
                                    {textProcessCounting("segments_filters.filter_type_segment.search_description")}
                                </div>
                                <div className="form-counting-filter-group-config">
                                    <div className="form-counting-fields">
                                        <FormLayoutRows>
                                            <FieldBlock
                                                label={textProcessCounting("segments_filters.filter_type_segment.search.label")}
                                                actions={(
                                                    <IconTooltip
                                                        icon={Action.OPEN.icon}
                                                        text={textProcessCounting("segments_filters.filter_type_segment.search.audience_link")}
                                                        onClick={() => window.open(audienceHome, "_blank")}
                                                    />
                                                )}
                                            >
                                                <SearchAutocomplete
                                                    items={currentSegments.map((it) => {
                                                        return {
                                                            value: it,
                                                            searchField: `${it.query_name}`,
                                                            component: (
                                                                <>
                                                                    <TagDataType type={DataType.getByName(it.data_type)} active/>
                                                                    <span>{it.name} ({it.id})</span>
                                                                    <Icon {...Action.ADD.icon}/>
                                                                </>
                                                            )
                                                        };
                                                    })}
                                                    selectedItems={[...selectedSegments, ...selectedModelingSegments].map((it) => {
                                                        return {value: it, searchField: `${it.segment.query_name}`};
                                                    })}
                                                    onChange={handleChangeQuery}
                                                    onSelect={(item) => addSegments([item.value])}
                                                    placeholder={textProcessCounting("segments_filters.filter_type_segment.search.placeholder")}
                                                />
                                            </FieldBlock>
                                            <FieldBlock
                                                label={textProcessCounting("segments_filters.filter_type_segment.add_list.label")}
                                                tooltip={textProcessCounting("segments_filters.filter_type_segment.add_list.tooltip")}
                                                content={{direction: FlexContentDirection.COLUMN}}
                                            >
                                                <SearchIds
                                                    placeholder={textProcessCounting("segments_filters.filter_type_segment.add_list.placeholder")}
                                                    items={segments}
                                                    selectedItems={[...selectedSegments, ...selectedModelingSegments].map((it) => it.segment)}
                                                    onSubmit={handleAddSegments}
                                                />
                                                {!!segmentIdsSearchResult?.matchedItems.length &&
                                                    <FormLayoutMessage
                                                        message={textProcessCounting("segment_ids_search_message.matched", {count: segmentIdsSearchResult?.matchedItems.length})}
                                                        severity={AlertSeverity.SUCCESS}
                                                    />
                                                }
                                                {!!segmentIdsSearchResult?.alreadySelectedIds.length &&
                                                    <FormLayoutMessage
                                                        message={textProcessCounting("segment_ids_search_message.already_selected", {count: segmentIdsSearchResult?.alreadySelectedIds.length, ids: segmentIdsSearchResult?.alreadySelectedIds.toString()})}
                                                        severity={AlertSeverity.WARNING}
                                                    />
                                                }
                                                {!!segmentIdsSearchResult?.unmatchedIds.length &&
                                                    <FormLayoutMessage
                                                        message={textProcessCounting("segment_ids_search_message.unmatched", {count: segmentIdsSearchResult?.unmatchedIds.length, ids: segmentIdsSearchResult?.unmatchedIds.toString()})}
                                                        severity={AlertSeverity.DANGER}
                                                    />
                                                }
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </div>
                                    <div className="form-counting-fields">
                                        <div className="form-counting-list">
                                            {selectedSegments.length > 1 &&
                                            <div className="form-counting-list-text" dangerouslySetInnerHTML={{__html: textProcessCounting("segments_filters.filter_type_segment.result_description")}}/>
                                            }
                                            {selectedSegments.length > 0 ?
                                                <div className="form-counting-list-content">
                                                    {selectedSegments.map(({segment, limit}, index) => (
                                                        <TagSegment
                                                            key={segment.id}
                                                            segment={segment}
                                                            limit={limit}
                                                            isHighlighted={!!highlightedSegments?.find((it) => it.segment.id === segment.id)}
                                                            onChangeLimit={(newLimit) => changeLimitSegmentRow(index, newLimit)}
                                                            onMoveFirstPosition={() => moveFirstPositionSegmentRow(index)}
                                                            onMoveDown={() => moveDownSegmentRow(index)}
                                                            onMoveUp={() => moveUpSegmentRow(index)}
                                                            onRemove={() => removeSegmentRow(index)}
                                                        />
                                                    ))}
                                                </div> :
                                                <span className="form-counting-list-placeholder">{textProcessCounting("segments_filters.filter_type_segment.result_placeholder")}</span>
                                            }
                                        </div>
                                        {!!selectedModelingSegments.length &&
                                        <div className="form-counting-list">
                                            <div className="form-counting-list-text" dangerouslySetInnerHTML={{__html: textProcessCounting("segments_filters.filter_type_segment.segments_modeling_warning")}}/>
                                            <div className="form-counting-list-content">
                                                {selectedModelingSegments.map(({segment, limit}, index) => (
                                                    <TagSegment
                                                        key={segment.id}
                                                        segment={segment}
                                                        limit={limit}
                                                        isHighlighted={!!highlightedSegments?.find((it) => it.segment.id === segment.id)}
                                                        onChangeLimit={(newLimit) => changeLimitSegmentRow(index, newLimit, true)}
                                                        onMoveFirstPosition={() => moveFirstPositionSegmentRow(index, true)}
                                                        onMoveDown={() => moveDownSegmentRow(index, true)}
                                                        onMoveUp={() => moveUpSegmentRow(index, true)}
                                                        onRemove={() => removeSegmentRow(index, true)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            {currentSegmentFilterTypeName === SegmentFilterType.SEGMENT_TYPE.name &&
                            <div className="form-counting-filter-group">
                                <div className="form-counting-filter-group-description">
                                    {textProcessCounting("segments_filters.filter_type_segment_type.description")}
                                </div>
                                <div className="form-counting-filter-group-types">
                                    {DataType.values().map((item) =>
                                        <TagDataType
                                            key={item.name}
                                            type={item}
                                            active={selectedDataTypes.includes(item.name)}
                                            huge
                                            onClick={handleToggleSegmentDataType}
                                        />
                                    )}
                                </div>
                            </div>
                            }
                        </FormLayoutRows>
                    </Loadable>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={onGoBack ? ModalActionsAlignment.SPACE_BETWEEN : ModalActionsAlignment.RIGHT}>
                {onGoBack && <ButtonBack onClick={onGoBack}/>}
                <ButtonValidate size={ButtonSize.MEDIUM} onClick={handleSubmit} disabled={isLoading || hasEmptyFields()}/>
            </ModalActions>
        </>
    );
};

export default ModalProcessCountingSegmentsFilters;
