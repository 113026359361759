import {ApiModel} from "../../../common/api/model/ApiModel";

export class StorageTableIntersectSegment extends ApiModel {
    dataset: string = "";
    table_id: string = "";
    row_count: number = 0;
    distinct_mapping_column_values: number = 0;
    column_names: string[] = [];
    mapping_column_index: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class StorageTableIntersect extends ApiModel {
    segment: StorageTableIntersectSegment = new StorageTableIntersectSegment();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
