import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Storage} from "../../api/model/storage/Storage";
import {session} from "../../api/ApiSession";
import {StoragesQuery} from "../../api/model/storage/search/StoragesQuery";
import {MainContent, Wrapper} from "../../common/component/widget";
import {
    AlertSeverity,
    Button,
    ButtonAdd,
    ButtonSize,
    ButtonStyle,
    ContentBlock,
    EmptyContentMessage,
    LayoutRows,
    Loadable,
    MainContentStyle,
    MainContentPageHeader,
    ModalConfirmMessage,
    SearchError,
    SearchToolbar,
    Table,
    TableColumnStyle,
    TranslationLibFile,
    Action
} from "@sirdata/ui-lib";
import {SvgStorages} from "../../component/svg";
import {ModalDeleteWarning, ModalUploadStorage} from "../../component/modal/index";
import {StorageStatus} from "../../api/model/storage/StorageStatus";
import {StorageTableRow} from "../../component/snippet";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";

const MAX_ROWS_PER_PAGE = 20;

function Storages() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textStorages} = useTranslation(TranslationPortalFile.STORAGES);
    const [isLoading, setLoading] = useState(true);

    const [storages, setStorages] = useState<Storage[]>([]);
    const [currentStorages, setCurrentStorages] = useState<Storage[]>([]);

    const [activeDeleteStorage, setActiveDeleteStorage] = useState<number>();
    const [activeCopyStorage, setActiveCopyStorage] = useState<Storage>();
    const [isShowModalUploadStorage, setShowModalUploadStorage] = useState<boolean>(false);

    const [query, setQuery] = useState<string>("");

    useEffect(() => {
        let currentStorages = (storages || []);
        currentStorages = currentStorages.filter((storage) => storage.status !== StorageStatus.DELETED);
        if (query) {
            currentStorages = currentStorages.filter((storage) => storage.name.toLowerCase().includes(query.toLowerCase()));
        }
        setCurrentStorages(currentStorages);
    }, [storages, query]);

    const loadStorages = useCallback(async () => {
        try {
            setLoading(true);
            const newStorages = await session.restStorage.getStorages(new StoragesQuery());
            setStorages(newStorages);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                UIEventManager.alert(textStorages("error.get_storages"), AlertSeverity.DANGER);
            }
        } finally {
            setLoading(false);
        }
    }, [textStorages]);

    const doCopyStorage = async (storage?: Storage) => {
        if (!storage?.id || !storage.isCopiable()) return;
        try {
            await session.restStorage.copyStorage(storage.id);
            await loadStorages();
        } catch (e) {
            UIEventManager.alert(textStorages("error.reimport_storage"), AlertSeverity.DANGER);
        } finally {
            setActiveCopyStorage(undefined);
        }
    };

    const doDeleteStorage = async (storageId?: number) => {
        if (!storageId) return;
        try {
            await session.restStorage.deleteStorage(storageId);
            await loadStorages();
        } catch (e) {
            UIEventManager.alert(textStorages("error.delete_storage"), AlertSeverity.DANGER);
        } finally {
            setActiveDeleteStorage(undefined);
        }
    };

    useEffect(() => {
        (async function () {
            await loadStorages();
        })();
    }, [loadStorages]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textStorages("title")}/>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        {storages.filter((storage) => storage.status !== StorageStatus.DELETED).length > 0 ?
                            <ContentBlock>
                                <SearchToolbar
                                    searchBar={{placeholder: textStorages("search_for_storages"), value: query, onChange: setQuery}}
                                    actions={[<ButtonAdd key="add_storage" onClick={() => setShowModalUploadStorage(true)} size={ButtonSize.MEDIUM}/>]}
                                />
                                {currentStorages.length > 0 ?
                                    <Table
                                        columns={[
                                            {label: textStorages("table.headers.status"), width: 15},
                                            {label: textStorages("table.headers.id"), width: 10},
                                            {label: textStorages("table.headers.name")},
                                            {label: textStorages("table.headers.creation_date"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {label: textStorages("table.headers.size"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {label: textStorages("table.headers.actions"), width: 10, styles: TableColumnStyle.ALIGN_CENTER}
                                        ]}
                                        pagination={MAX_ROWS_PER_PAGE}
                                    >
                                        {currentStorages.map((storage, index) =>
                                            <StorageTableRow key={`storage-row-${index.toString()}`} storage={storage} actions={{onDelete: () => setActiveDeleteStorage(storage.id), onCopy: () => setActiveCopyStorage(storage)}}/>
                                        )}
                                    </Table> :
                                    <SearchError query={query}/>
                                }
                                <ModalConfirmMessage
                                    active={!!activeCopyStorage}
                                    message={textStorages("table.actions.reimport_storage_warning")}
                                    confirm={{size: ButtonSize.BIG, style: ButtonStyle.PRIMARY_GREEN, onClick: () => doCopyStorage(activeCopyStorage), children: textStorages("table.actions.reimport_storage")}}
                                    cancel={{size: ButtonSize.BIG, style: ButtonStyle.DEFAULT_MIDNIGHT, onClick: () => setActiveCopyStorage(undefined), children: textCommon(Action.CANCEL.labelKey)}}
                                />
                                <ModalDeleteWarning active={!!activeDeleteStorage} message={textStorages("table.actions.delete_warning")} onClose={() => setActiveDeleteStorage(undefined)} onDelete={() => doDeleteStorage(activeDeleteStorage)}/>
                            </ContentBlock> :
                            <EmptyContentMessage svg={SvgStorages} message={textStorages("empty_content.message")}>
                                <Button onClick={() => setShowModalUploadStorage(true)} size={ButtonSize.BIG}>
                                    {textStorages("empty_content.start")}
                                </Button>
                            </EmptyContentMessage>
                        }
                    </Loadable>
                </LayoutRows>
                <ModalUploadStorage
                    active={isShowModalUploadStorage}
                    onClose={(refresh) => {
                        setShowModalUploadStorage(false);
                        refresh && loadStorages();
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Storages;
