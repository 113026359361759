import React, {FunctionComponent} from "react";

const SvgStorages: FunctionComponent = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 314.44 270.35"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="357.94"
                    y1="177.19"
                    x2="281.36"
                    y2="242.43"
                    gradientTransform="matrix(-1, 0, 0, 1, 503.45, 0)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"/>
                    <stop offset="0.13" stopOpacity="0.69"/>
                    <stop offset="0.25" stopOpacity="0.32"/>
                    <stop offset="1" stopOpacity="0"/>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="119.79"
                    y1="188.34"
                    x2="247.16"
                    y2="172.18"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-3"
                    x1="298.47"
                    y1="204.83"
                    x2="158.22"
                    y2="191.9"
                    gradientTransform="matrix(1, 0, 0, -1, -65.66, 407.09)"
                    xlinkHref="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-4"
                    x1="326.52"
                    y1="162.3"
                    x2="102.75"
                    y2="160.41"
                    gradientTransform="matrix(1, 0, 0, -1, -65.66, 323.89)"
                    xlinkHref="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-5"
                    x1="330.11"
                    y1="122.29"
                    x2="105.08"
                    y2="98.65"
                    gradientTransform="matrix(1, 0, 0, -1, -65.66, 235.01)"
                    xlinkHref="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-6"
                    x1="236.68"
                    y1="175.79"
                    x2="160.09"
                    y2="241.03"
                    xlinkHref="#linear-gradient"
                />
            </defs>
            <path
                d="M126.46,26.87c-29.57,27-37.8,69.65-58.24,104-13.45,22.63-33.21,44.43-32,70.73.34,7.36,2.37,14.53,4.65,21.54,2.59,7.95,5.58,15.88,10.36,22.74,12.44,17.86,35.31,25.76,57.06,26.68s43.25-4,64.88-6.43c14.28-1.6,28.65-2.11,43-2.7,25.09-1,50.57-2.39,74.27-10.7s45.74-24.64,55.14-47.92c9.2-22.79,5-49.4-6.53-71.09s-29.74-39.11-49-54.35c-15.32-12.12-31.51-23.35-43.67-38.87-8.59-11-15-26.46-28.3-32.69C189.75-5.38,148.24,7,126.46,26.87Z"
                transform="translate(-36.16 -2.37)"
                fill="#68e1fd"
                opacity="0.18"
                style={{isolation: "isolate"}}
            />
            <ellipse
                cx="150.75"
                cy="217.74"
                rx="137.5"
                ry="11.32"
                fill="#68e1fd"
                opacity="0.18"
                style={{isolation: "isolate"}}
            />
            <path
                d="M198,179a11.9,11.9,0,0,1-2,5.34c-4.28,7.57-9.08,15-15.72,20.64-9.76,8.26-22.54,11.81-34.88,15.15,1.11-.54,2.43-6.23,2.92-7.48,1-2.45,1.85-4.92,2.77-7.38,1.45-3.89,4.5-7,7.46-9.88a59,59,0,0,1,23.78-14A55.91,55.91,0,0,1,198,179Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-green)"
            />
            <path
                d="M198,179a11.9,11.9,0,0,1-2,5.34c-4.28,7.57-9.08,15-15.72,20.64-9.76,8.26-22.54,11.81-34.88,15.15,1.11-.54,2.43-6.23,2.92-7.48,1-2.45,1.85-4.92,2.77-7.38,1.45-3.89,4.5-7,7.46-9.88a59,59,0,0,1,23.78-14A55.91,55.91,0,0,1,198,179Z"
                transform="translate(-36.16 -2.37)"
                fill="url(#linear-gradient)"
            />
            <path
                d="M149.73,183.21c1-7,2-14.18,4.87-20.7S162.35,150,169,147.56q.3,15.13.59,30.25c.11,5.83.21,11.77-1.46,17.35-2.93,9.78-13.11,21.26-22.91,24.49-1.45-4.7,1-11.82,1.68-16.7Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-green)"
            />
            <path
                d="M149.73,183.21c1-7,2-14.18,4.87-20.7S162.35,150,169,147.56q.3,15.13.59,30.25c.11,5.83.21,11.77-1.46,17.35-2.93,9.78-13.11,21.26-22.91,24.49-1.45-4.7,1-11.82,1.68-16.7Z"
                transform="translate(-36.16 -2.37)"
                fill="url(#linear-gradient-2)"
            />
            <rect
                x="168.55"
                y="186.05"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(401.63 404.72) rotate(-180)"
                fill="var(--color-coral)"
            />
            <rect
                x="168.55"
                y="144.45"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(401.63 321.52) rotate(-180)"
                fill="var(--color-coral)"
            />
            <rect
                x="168.55"
                y="186.05"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(401.63 404.72) rotate(-180)"
                fill="url(#linear-gradient-3)"
            />
            <rect
                x="168.55"
                y="144.45"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(401.63 321.52) rotate(-180)"
                fill="url(#linear-gradient-4)"
            />
            <rect
                x="158.62"
                y="186.05"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(381.78 404.72) rotate(-180)"
                fill="var(--color-coral)"
            />
            <path
                d="M158.62,153.77v16.35a9.31,9.31,0,0,0,9.32,9.32H250a9.31,9.31,0,0,0,9.31-9.32V153.77a9.31,9.31,0,0,0-9.31-9.32H167.94A9.32,9.32,0,0,0,158.62,153.77Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-coral)"
            />
            <rect
                x="168.55"
                y="100.01"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(401.63 232.64) rotate(-180)"
                fill="var(--color-coral)"
            />
            <rect
                x="168.55"
                y="100.01"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(401.63 232.64) rotate(-180)"
                fill="url(#linear-gradient-5)"
            />
            <rect
                x="158.62"
                y="100.01"
                width="100.7"
                height="34.98"
                rx="9.32"
                transform="translate(381.78 232.64) rotate(-180)"
                fill="var(--color-coral)"
            />
            <circle cx="207.38" cy="115.51" r="3.96" fill="#fff"/>
            <circle cx="195.48" cy="115.51" r="3.96" fill="#fff"/>
            <circle cx="183.58" cy="115.51" r="3.96" fill="#fff"/>
            <circle cx="207.38" cy="159.58" r="3.96" fill="#fff"/>
            <circle cx="195.48" cy="159.58" r="3.96" fill="#fff"/>
            <circle cx="183.58" cy="159.58" r="3.96" fill="#fff"/>
            <circle cx="207.38" cy="203.64" r="3.96" fill="#fff"/>
            <circle cx="195.48" cy="203.64" r="3.96" fill="#fff"/>
            <circle cx="183.58" cy="203.64" r="3.96" fill="#fff"/>
            <path
                d="M319.28,177.57a11.8,11.8,0,0,1-1.94,5.34c-4.28,7.57-9.09,15-15.72,20.65-9.76,8.25-22.54,11.81-34.88,15.14,1.1-.53,2.43-6.22,2.92-7.47,1-2.45,1.85-4.92,2.76-7.38,1.45-3.89,4.5-7,7.47-9.88A58.91,58.91,0,0,1,303.67,180,55.79,55.79,0,0,1,319.28,177.57Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-green)"
            />
            <path
                d="M319.28,177.57a11.8,11.8,0,0,1-1.94,5.34c-4.28,7.57-9.09,15-15.72,20.65-9.76,8.25-22.54,11.81-34.88,15.14,1.1-.53,2.43-6.22,2.92-7.47,1-2.45,1.85-4.92,2.76-7.38,1.45-3.89,4.5-7,7.47-9.88A58.91,58.91,0,0,1,303.67,180,55.79,55.79,0,0,1,319.28,177.57Z"
                transform="translate(-36.16 -2.37)"
                fill="url(#linear-gradient-6)"
            />
            <path
                d="M271,181.81c1-7,2-14.18,4.87-20.7s7.74-12.47,14.41-15q.3,15.12.59,30.25c.11,5.83.21,11.76-1.46,17.35-2.94,9.78-13.11,21.26-22.91,24.49-1.45-4.7,1-11.82,1.68-16.7Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-green)"
            />
            <g id="Q83r0t">
                <path
                    d="M197.35,177.69a3.14,3.14,0,0,1-2.27-.9c-.45-.43-.87-.89-1.33-1.31a2.8,2.8,0,0,0-3.25-.63,2.86,2.86,0,0,0-1.85,2.76c0,.67,0,1.33,0,2a3.14,3.14,0,0,1-3.21,3.09h-3.73a3.19,3.19,0,0,1-3.24-3.21c0-.66,0-1.33,0-2a2.84,2.84,0,0,0-1.88-2.67,2.77,2.77,0,0,0-3.15.6c-.45.42-.87.88-1.32,1.31a3.24,3.24,0,0,1-4.63,0c-.91-.9-1.83-1.8-2.73-2.72a3.14,3.14,0,0,1,0-4.59c.42-.43.87-.84,1.28-1.27a2.8,2.8,0,0,0,.65-3.15,2.84,2.84,0,0,0-2.66-1.9c-.66,0-1.33,0-2,0a3.18,3.18,0,0,1-3.26-3.39c0-1.17,0-2.35,0-3.53a3.12,3.12,0,0,1,2.95-3.21c.68-.06,1.37,0,2.06,0a2.9,2.9,0,0,0,2.88-1.86,2.86,2.86,0,0,0-.78-3.34c-.4-.41-.82-.81-1.22-1.23a3.11,3.11,0,0,1,0-4.41c1-1,2-2,3-2.92a3.22,3.22,0,0,1,4.43.06c.47.45.9.92,1.38,1.36a2.77,2.77,0,0,0,3.15.58,2.8,2.8,0,0,0,1.86-2.68c0-.62,0-1.24,0-1.86a3.21,3.21,0,0,1,3.39-3.32c1.16,0,2.32,0,3.48,0a3.2,3.2,0,0,1,3.34,3.24c0,.6,0,1.2,0,1.8a3,3,0,0,0,5.15,2.14c.41-.4.81-.82,1.23-1.22a3.23,3.23,0,0,1,4.57,0c1,.92,1.9,1.86,2.83,2.81a3.11,3.11,0,0,1,0,4.49c-.45.46-.93.9-1.38,1.36a3,3,0,0,0,1.79,5c.7.08,1.41,0,2.12.05a2.93,2.93,0,0,1,3.12,3q.06,2,0,4.05a3,3,0,0,1-3.22,3.09c-.62,0-1.24,0-1.86,0a2.79,2.79,0,0,0-2.59,1.9,2.73,2.73,0,0,0,.62,3.08c.45.46.93.9,1.37,1.36a3.13,3.13,0,0,1,0,4.5c-.92,1-1.87,1.89-2.82,2.82A3.13,3.13,0,0,1,197.35,177.69ZM173.28,158a10.29,10.29,0,1,0,10.32-10.27A10.28,10.28,0,0,0,173.28,158Z"
                    transform="translate(-36.16 -2.37)"
                    fill="#fff"
                />
            </g>
            <path
                d="M135.27,174.74a14.34,14.34,0,0,0-4.06,2.75,5.9,5.9,0,0,0-1.8,4.43c.15,2,1.58,3.62,3.08,4.93,1.73,1.51,3.81,3.09,3.95,5.37,1.23-.91,2.34-2,3.61-2.84,2.22-1.51,4.83-2.32,7.27-3.44s4.85-2.7,6-5.12A14.21,14.21,0,0,0,154.4,176a23.68,23.68,0,0,0,.14-5.32,7.78,7.78,0,0,0-2.21-4.73,10.8,10.8,0,0,0-3.14-1.82,10.05,10.05,0,0,0-3.45-1,7.06,7.06,0,0,0-6.24,4.37c-.5,1.34-.27,2.85-.76,4.18C138.16,173.29,136.66,174,135.27,174.74Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-midnight-light)"
            />
            <path
                d="M66.17,212.35a4.64,4.64,0,0,0-1.3-.6,1.29,1.29,0,0,0-1.3.42,1.41,1.41,0,0,0,0,1.35c.2.43.49.81.68,1.24a7.09,7.09,0,0,0,.48,1.21A3.25,3.25,0,0,0,66.48,217a26.86,26.86,0,0,1,8.17,4.66,3.28,3.28,0,0,0,.75.53,2.74,2.74,0,0,0,2.6-.55A13.3,13.3,0,0,0,81.71,217a1.39,1.39,0,0,0,.21-.62,1.14,1.14,0,0,0-.19-.56c-1.68-2.86-4.65-1.3-7.26-1.3A16.64,16.64,0,0,1,66.17,212.35Z"
                transform="translate(-36.16 -2.37)"
                fill="#ffbbab"
            />
            <path
                d="M150.25,215.23s-5.48,9.71-15.63,7.07-23.54-14.72-23.54-14.72-13.53,7.55-23.24,9l-6.48,1.3a4.67,4.67,0,0,1-3.09-4.71S105.05,197.73,107.7,197a16.69,16.69,0,0,1,9.71,0c4.12,1.18,20,5.67,20,5.67Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-blue)"
            />
            <polygon
                points="59.97 170.16 68.44 193.65 88.04 195.36 88.04 193.65 70.05 191.75 62.09 169.52 59.97 170.16"
                fill="var(--color-midnight-light)"
            />
            <path
                d="M137.66,160.53c-.37.62-1.13,1-1.37,1.66s.28,1.66.57,2.48c.41,1.14.28,2.42.68,3.57a1.37,1.37,0,0,0,.4.65,1.41,1.41,0,0,0,.61.23,8.66,8.66,0,0,0,2.59.16.71.71,0,0,1,.5.07.62.62,0,0,1,.22.43,13.86,13.86,0,0,1-.1,6.59c-.18.71-.39,1.57.14,2.08a1.39,1.39,0,0,0,.66.34c1.64.4,3.22-.82,4.33-2.09a19.34,19.34,0,0,0,2.54-3.73,3.94,3.94,0,0,0,.44-1.2,4.51,4.51,0,0,0-.23-1.62,27.43,27.43,0,0,1-.85-5.47c-.12-2.37.05-4.84-.86-7a3.7,3.7,0,0,0-1.43-1.84,4.64,4.64,0,0,0-2.56-.41c-1.72.06-3.61.3-4.78,1.56C138.82,157.33,138.11,159.76,137.66,160.53Z"
                transform="translate(-36.16 -2.37)"
                fill="#ffbbab"
            />
            <path
                d="M149.72,171.92s-7.08,3.67-9.22,6.54-5.64,5-4.29,10.44-.3,13.76-.3,13.76,3.07,9.68,10.63,12.13,5.49,1.67,5.49,1.67,4-18,5.59-23S156.63,171.12,149.72,171.92Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-yellow)"
            />
            <path
                d="M139.93,156.72a4.92,4.92,0,0,0,1.57,3c.84.79,1.88,1.32,2.74,2.08a3.4,3.4,0,0,1,1.38,3,1.54,1.54,0,0,1,1.88.6,1.26,1.26,0,0,1-.2,1.3,1.56,1.56,0,0,1-1.24.51,4.9,4.9,0,0,1,2.21,1.79c.61.89.9,2,1.45,2.9a9.49,9.49,0,0,0,2.54,2.39,3.48,3.48,0,0,1,1.1,1.16,18,18,0,0,0,.73,2.1c.54,1,1.4,1.67,2,2.56a9.31,9.31,0,0,1,1.61,4.81,7.8,7.8,0,0,1,0-1.53c.17-.31.35-.61.51-.93a8.31,8.31,0,0,0,.63-1.75,17.62,17.62,0,0,0,.12-4.17c0-3,2.81-8.61.55-11.16-.78-.88-2-1.28-2.86-2-2-1.65-2.28-4.56-3.53-6.83a8.64,8.64,0,0,0-5.23-4.09C144.93,151.65,139.56,152.57,139.93,156.72Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-midnight-light)"
            />
            <path
                d="M147,181.25s-4.48,12.1-5.36,13,8.62,6.23,8.62,6.23,6.19-11.76,5.9-16.86S150.48,176.45,147,181.25Z"
                transform="translate(-36.16 -2.37)"
                fill="var(--color-yellow)"
            />
            <path
                d="M150.25,198.83s-.33,3.9-5.11,3.83-21.26-.3-21.26-.3c-1.45-.74-3.43-.87-4.51-2.09-.51-.58-.78-1.34-1.28-1.94A4.29,4.29,0,0,0,115.5,197a12.91,12.91,0,0,0-3-.08,1.23,1.23,0,0,1,0-1.62,3,3,0,0,1,1.54-.84,9.53,9.53,0,0,1,7.21,1,8.58,8.58,0,0,0,2,1.07,5.87,5.87,0,0,0,1.62.09c6.13-.29,12.18-1.6,18.31-1.87a10.49,10.49,0,0,1,4.25.44A5,5,0,0,1,150.25,198.83Z"
                transform="translate(-36.16 -2.37)"
                fill="#ffbbab"
            />
            <path
                d="M217.82,79a12,12,0,0,1-12,12H123.53a12,12,0,1,1,0-24h2.79a23.65,23.65,0,0,1,22.77-30h7a23.63,23.63,0,0,1,21,12.75,14,14,0,0,1,6.45-1.55h1.81a14.22,14.22,0,0,1,14.22,14.22V63A14.06,14.06,0,0,1,199,67h6.81A12,12,0,0,1,217.82,79Z"
                transform="translate(-36.16 -2.37)"
                fill="#fff"
            />
        </svg>
    );
};

export default SvgStorages;
