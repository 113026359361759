import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {MainContent, Wrapper} from "../../common/component/widget";
import {AlertSeverity, Button, ButtonSize, ButtonStyle, ContentBlock, EmptyContentMessage, LayoutRows, Loadable, MainContentStyle, MainContentPageHeader, SearchError, SearchField, SearchToolbar, SelectAutocomplete, Select, InputDate} from "@sirdata/ui-lib";
import {SvgCountings} from "../../component/svg";
import {Storage} from "../../api/model/storage/Storage";
import {session} from "../../api/ApiSession";
import {StoragesQuery} from "../../api/model/storage/search/StoragesQuery";
import {CountingsTable} from "../../component/snippet";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {StorageFilter} from "../../api/model/storage-filter/StorageFilter";
import {StorageFilterStatus, STORAGE_FILTER_STATUSES} from "../../api/model/storage-filter/StorageFilterStatus";
import {StorageFilterListQuery} from "../../api/model/storage-filter/search/StorageFilterListQuery";
import {ModalProcessCounting} from "../../component/modal";

function Countings() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCountings} = useTranslation(TranslationPortalFile.COUNTINGS);
    const [isLoadingStorages, setLoadingStorages] = useState(true);
    const [isLoadingCountings, setLoadingCountings] = useState(false);

    const [storages, setStorages] = useState<Storage[]>([]);
    const [currentStorageId, setCurrentStorageId] = useState<string>();

    const [storageFilterListQuery, setStorageFilterListQuery] = useState<StorageFilterListQuery>(new StorageFilterListQuery());

    const [currentCountings, setCurrentCountings] = useState<StorageFilter[]>([]);

    const [activeModalProcessCounting, setActiveModalProcessCounting] = useState<StorageFilter>();

    const loadCountings = useCallback(async () => {
        try {
            setLoadingCountings(true);
            let countings = await session.restStorageFilter.getStorageFilters(storageFilterListQuery, currentStorageId || undefined);
            countings = countings.filter((counting) => counting.status !== StorageFilterStatus.DELETED);
            setCurrentCountings(countings);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                UIEventManager.alert(textCountings("error.get_countings"), AlertSeverity.DANGER);
            }
        } finally {
            setLoadingCountings(false);
        }
    }, [textCountings, storageFilterListQuery, currentStorageId]);

    const handleChangeDates = (name: string, value: string) => {
        setStorageFilterListQuery((prevState) => {
            const query = new StorageFilterListQuery();
            query.load({...prevState, [name]: value});
            return query;
        });
    };

    const handleActiveProcessCounting = (initCounting?: StorageFilter) => {
        let newCounting = new StorageFilter(initCounting);

        const newStorage = storages.find(({id}) => id === newCounting.storage_id);
        if (newStorage?.id) {
            newCounting.storage = newStorage;
            newCounting.storage_id = newStorage.id;
        }

        setActiveModalProcessCounting(newCounting);
    };

    useEffect(() => {
        (async function () {
            try {
                const newStorages = await session.restStorage.getStorages(new StoragesQuery());
                setStorages(newStorages);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    UIEventManager.alert(textCountings("error.get_storages"), AlertSeverity.DANGER);
                }
            } finally {
                setLoadingStorages(false);
            }
        })();
    }, [textCountings]);

    useEffect(() => {
        (async function () {
            await loadCountings();
        })();
    }, [loadCountings]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textCountings("title")}/>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCountings("search_for_countings"), value: storageFilterListQuery.search, onChange: (query) => setStorageFilterListQuery((prevState) => new StorageFilterListQuery({...prevState, search: query}))}}
                            actions={[
                                <Button
                                    key={"btnNewCounting"}
                                    size={ButtonSize.MEDIUM}
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    onClick={() => handleActiveProcessCounting()}
                                    icon={{name: "add_circle"}}
                                >
                                    {textCountings("btn_new_counting")}
                                </Button>
                            ]}
                        >
                            <SearchField label={textCountings("storage")}>
                                <SelectAutocomplete
                                    value={currentStorageId}
                                    options={storages.map((storage) => {
                                        return {value: storage.id.toString(), label: storage.name};
                                    })}
                                    onChange={(option) => setCurrentStorageId(option?.value.toString())}
                                    isLoading={isLoadingStorages}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textCountings("status")}>
                                <Select
                                    value={storageFilterListQuery.status}
                                    options={STORAGE_FILTER_STATUSES.map((value) => {
                                        return {value: value, label: t(`status.${value}`)};
                                    })}
                                    onChange={(option) => setStorageFilterListQuery((prevState) => new StorageFilterListQuery({...prevState, status: option?.value || ""}))}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textCountings("start_date")}>
                                <InputDate
                                    value={storageFilterListQuery.start_date}
                                    onChange={(value) => handleChangeDates("start_date", value)}
                                />
                            </SearchField>
                            <SearchField label={textCountings("end_date")}>
                                <InputDate
                                    value={storageFilterListQuery.end_date}
                                    onChange={(value) => handleChangeDates("end_date", value)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoadingCountings}>
                            {currentCountings.length > 0 ?
                                <CountingsTable
                                    countings={currentCountings}
                                    onRefresh={loadCountings}
                                    onProcessCounting={(initCounting) => handleActiveProcessCounting(initCounting)}
                                /> :
                                (storageFilterListQuery.isInitialized() && !currentStorageId ?
                                    <EmptyContentMessage svg={SvgCountings} message={textCountings("empty_countings.message")}/> :
                                    <SearchError/>
                                )
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalProcessCounting
                    initCounting={activeModalProcessCounting}
                    onClose={(refresh) => {
                        setActiveModalProcessCounting(undefined);
                        refresh && loadCountings();
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Countings;
