import {ApiModel} from "../../../../common/api/model/ApiModel";

export const SEGMENT_LIMIT_MIN_VALUE = 0;
export const SEGMENT_LIMIT_MAX_VALUE = 99999;

export class SegmentFilterSegment extends ApiModel {
    id: number = 0;
    limit: number | null = null;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            limit: this.limit
        };
    }
}
