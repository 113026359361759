import {ModalNewSize} from "@sirdata/ui-lib";
import {ModalStep} from "./ModalStep";

export class ProcessCountingStep extends ModalStep {
    static STORAGE_SELECTION: ProcessCountingStep = new ProcessCountingStep("STORAGE_SELECTION", "storage_selection");
    static COLUMN_FILTERS: ProcessCountingStep = new ProcessCountingStep("COLUMN_FILTERS", "columns_filters");
    static SEGMENTS_FILTERS: ProcessCountingStep = new ProcessCountingStep("SEGMENTS_FILTERS", "segments_filters").withModalSize(ModalNewSize.MAX_WIDTH);
    static COLUMNS_SELECTION: ProcessCountingStep = new ProcessCountingStep("COLUMNS_SELECTION", "columns_selection");
    static HASH_DEDUPLICATION: ProcessCountingStep = new ProcessCountingStep("HASH_DEDUPLICATION", "hash_deduplication");
    static VALIDATION: ProcessCountingStep = new ProcessCountingStep("VALIDATION", "validation");
    static CONFIRMATION: ProcessCountingStep = new ProcessCountingStep("CONFIRMATION", "confirmation");

    label: string;

    constructor(name: string, label: string) {
        super(name);
        this.label = label;
    }

    static values(): ProcessCountingStep[] {
        return [
            ProcessCountingStep.STORAGE_SELECTION,
            ProcessCountingStep.COLUMN_FILTERS,
            ProcessCountingStep.SEGMENTS_FILTERS,
            ProcessCountingStep.COLUMNS_SELECTION,
            ProcessCountingStep.HASH_DEDUPLICATION,
            ProcessCountingStep.VALIDATION,
            ProcessCountingStep.CONFIRMATION
        ];
    }
}

