import {ApiModel} from "../../../../common/api/model/ApiModel";

export class PartnerPricing extends ApiModel {
    discount: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
