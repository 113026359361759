import {Rest} from "../../common/api/rest/Rest";
import {PricingDetails} from "../model/partner/pricing/PricingDetails";

export class RestPricing extends Rest {
    rootPath = "/customer/pricing";

    getPricingDetails(): Promise<PricingDetails> {
        return this._client.get(this.rootPath, PricingDetails) as Promise<PricingDetails>;
    }
}
