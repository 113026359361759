import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, AlertSeverity, Button, ButtonAdd, ButtonBack, ButtonSize, ButtonStyle, ButtonValidate, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, EmptyContentMessage, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentSpacing, FormLayoutRows, FormLayoutSeparator, IconTooltip, ModalActions, ModalActionsAlignment, ModalContent, ModalDescription, Select, Table, TableColumn, TableColumnStyle, TableRow, Textarea, TranslationLibFile} from "@sirdata/ui-lib";
import {ColumnFilterType} from "../../../api/model/storage-filter/column/ColumnFilterType";
import {ColumnFilterOperator} from "../../../api/model/storage-filter/column/ColumnFilterOperator";
import {StorageColumnFilter} from "../../../api/model/storage-filter/column/StorageColumnFilter";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {SvgColumnsFiltersEmpty} from "../../../component/svg";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type ModalProcessCountingColumnsFiltersProps = {
    initCounting: StorageFilter;
    onSubmit: (counting: StorageFilter) => void;
    onGoBack?: () => void;
}

const VALUES_SEPARATOR = ",";

const ModalProcessCountingColumnsFilters: FunctionComponent<ModalProcessCountingColumnsFiltersProps> = ({initCounting, onSubmit, onGoBack}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);

    const [columnFilters, setColumnFilters] = useState<StorageColumnFilter[]>([]);
    const [mappingColumnIndex, setMappingColumnIndex] = useState<number>(0);
    const [columnNames, setColumnNames] = useState<string[]>([]);
    const [filteredColumnNames, setFilteredColumnNames] = useState<string[]>([]);

    const [isFirstRowHighlighted, setFirstRowHighlighted] = useState(false);

    useEffect(() => {
        const newColumnFilters = initCounting.column_filter;
        setColumnFilters(newColumnFilters);

        const newMappingColumnIndex = initCounting.storage.table.mapping_column_index;
        setMappingColumnIndex(newMappingColumnIndex);

        const newColumnNames = [...initCounting.storage.table.column_names];
        setColumnNames(newColumnNames);

        let newFilteredColumnsNames = [...newColumnNames];
        newFilteredColumnsNames.splice(newMappingColumnIndex, 1);
        setFilteredColumnNames(newFilteredColumnsNames);
    }, [initCounting]);

    const handleAddColumnFilter = () => {
        setColumnFilters((prev) => {
            let newColumnFilter = new StorageColumnFilter();
            if (mappingColumnIndex === 0) {
                newColumnFilter.column_index = 1;
            }
            return [newColumnFilter, ...prev];
        });
        setTimeout(() => {
            setFirstRowHighlighted(false);
        }, 800);
        setFirstRowHighlighted(true);
    };

    const handleDeleteColumnFilter = (index: number) => {
        setColumnFilters((prev) => {
            let newColumnFilters = [...prev];
            newColumnFilters.splice(index, 1);
            return newColumnFilters;
        });
    };

    const handleChange = (rowIndex: number, name: string, value?: number | string[]) => {
        if (!value) return;
        let newValue = value;

        if (typeof value === "object") newValue = value.map((it: string) => it.trim());

        setColumnFilters((prev) => {
            let newColumnFilters = [...prev];
            newColumnFilters[rowIndex][name] = newValue;
            return newColumnFilters;
        });
    };

    const handleChangeFilterType = (rowIndex: number, columnFilterType?: ColumnFilterType) => {
        if (!columnFilterType) return;
        setColumnFilters((prev) => {
            let newColumnFilters = [...prev];
            newColumnFilters[rowIndex].operator = columnFilterType.operator;
            newColumnFilters[rowIndex].not = columnFilterType.not;
            return newColumnFilters;
        });
    };

    const handleChangeFilterValues = (rowIndex: number, name: string, value: string) => {
        if (!value) {
            handleChange(rowIndex, name, []);
            return;
        }

        const regExp = new RegExp(["\n", "\t", ";", " "].join("|"), "g");
        const newValue = value.replaceAll(regExp, VALUES_SEPARATOR);

        handleChange(rowIndex, name, newValue.split(VALUES_SEPARATOR));
    };

    const handleSubmit = () => {
        let newCounting = new StorageFilter();
        let filterValues = 0;

        newCounting.load(initCounting);
        newCounting.column_filter = columnFilters.map((columnFilter) => {
            const filteredValues = columnFilter.values.filter((it) => it);
            filterValues += filteredValues.length;
            columnFilter.values = filteredValues;
            return columnFilter;
        });

        if (filterValues) {
            UIEventManager.alert(`${textProcessCounting("columns_filters.values_count", {count: filterValues})} ${textProcessCounting("columns_filters.filters_count", {count: newCounting.column_filter.length})}`, AlertSeverity.SUCCESS);
        }

        onSubmit(newCounting);
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textProcessCounting("columns_filters.description")}}/>
                    </ModalDescription>
                    <FormLayoutSeparator/>
                    {!!columnFilters.length ?
                        <ContentBlock>
                            <ContentBlockRow>
                                <ContentBlockRowElementRight>
                                    <ButtonAdd onClick={handleAddColumnFilter} size={ButtonSize.SMALL}/>
                                </ContentBlockRowElementRight>
                            </ContentBlockRow>
                            <Table
                                columns={[
                                    {label: textProcessCounting("columns_filters.table.headers.filters"), width: 98},
                                    {label: "", width: 2}
                                ]}
                            >
                                {columnFilters.map((columnFilter, rowIndex) =>
                                    <TableRow key={`column-filter-row-${rowIndex.toString()}`}>
                                        <TableColumn styles={(rowIndex === 0 && isFirstRowHighlighted) ? TableColumnStyle.HIGHLIGHTED : []}>
                                            <div style={{width: "100%"}}>
                                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.SMALL} layout={FlexContentLayout.COLUMNS}>
                                                        <Select
                                                            value={columnFilter.column_index}
                                                            options={filteredColumnNames.map((name) => {
                                                                return {value: columnNames.findIndex((it) => it === name), label: name};
                                                            })}
                                                            onChange={(option) => handleChange(rowIndex, "column_index", option?.value as number)}
                                                        />
                                                        <Select
                                                            value={ColumnFilterType.getByStorageColumnFilter(columnFilter).name}
                                                            options={ColumnFilterType.values().map((filter) => {
                                                                return {value: filter.name, label: textProcessCounting(`columns_filters_types.${filter.label}`), filter: filter};
                                                            })}
                                                            onChange={(option) => handleChangeFilterType(rowIndex, option?.filter)}
                                                        />
                                                    </FlexContent>
                                                    {(columnFilter.operator !== ColumnFilterOperator.EMPTY) &&
                                                        <Textarea
                                                            value={columnFilter.values.join(VALUES_SEPARATOR)}
                                                            placeholder={textProcessCounting("columns_filters.table.values")}
                                                            onChange={(value) => handleChangeFilterValues(rowIndex, "values", value)}
                                                            rows={2}
                                                        />
                                                    }
                                                </FlexContent>
                                            </div>
                                        </TableColumn>
                                        <TableColumn styles={(rowIndex === 0 && isFirstRowHighlighted) ? TableColumnStyle.HIGHLIGHTED : []}>
                                            <IconTooltip
                                                icon={{...Action.DELETE.icon, colorIcon: ""}}
                                                text={textCommon(Action.DELETE.labelKey)}
                                                onClick={() => handleDeleteColumnFilter(rowIndex)}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                )}
                            </Table>
                        </ContentBlock> :
                        <EmptyContentMessage svg={SvgColumnsFiltersEmpty} message={textProcessCounting("columns_filters.empty_content.message")}/>
                    }
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={onGoBack ? ModalActionsAlignment.SPACE_BETWEEN : ModalActionsAlignment.RIGHT}>
                {onGoBack && <ButtonBack onClick={onGoBack}/>}
                {!!columnFilters.length ?
                    <ButtonValidate size={ButtonSize.MEDIUM} onClick={handleSubmit}/> :
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.MEDIUM}>
                        <Button size={ButtonSize.MEDIUM} style={ButtonStyle.DEFAULT_GREEN} onClick={handleAddColumnFilter}>
                            {textProcessCounting("columns_filters.empty_content.add_column_filter")}
                        </Button>
                        <Button size={ButtonSize.MEDIUM} onClick={handleSubmit}>
                            {textProcessCounting("columns_filters.empty_content.continue")}
                        </Button>
                    </FlexContent>
                }
            </ModalActions>
        </>
    );
};

export default ModalProcessCountingColumnsFilters;
