import moment from "moment";
import {ApiModel} from "../../../common/api/model/ApiModel";
import {pathStoragesWithId} from "../../../utils/constants";
import {StorageCsvOptions} from "./StorageCsvOptions";
import {StorageTableIntersect} from "./StorageTableIntersect";
import {StorageTable} from "./StorageTable";
import {StorageUploadedFile} from "./StorageUploadFile";
import {StorageStatus} from "./StorageStatus";
import {StorageErrorCode} from "./StorageErrorCode";
import {PathHelper} from "../../../common/utils/PathHelper";

export const MAPPING_TYPE_EMAIL_HASH = "EMAIL_HASH";
export const STORAGE_NAME_MIN_LENGTH = 5;
export const STORAGE_NAME_MAX_LENGTH = 255;

export class Storage extends ApiModel {
    id: number = 0;
    name: string = "";
    creation_ts: string = "";
    last_update_ts: string = "";
    expiration_ts: string = "";
    copyable_limit_date: string = "";
    partner_id: number = 0;
    partner_account_id: number = 0;
    uploaded_file: StorageUploadedFile = new StorageUploadedFile();
    mapping_type: string = MAPPING_TYPE_EMAIL_HASH;
    table: StorageTable = new StorageTable();
    table_intersect: StorageTableIntersect = new StorageTableIntersect();
    csv_options: StorageCsvOptions = new StorageCsvOptions();
    status: string = StorageStatus.PENDING;
    error_code: string = StorageErrorCode.NONE;
    error_message: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    isExpired(): boolean {
        return this.status === StorageStatus.OK && (moment(this.expiration_ts).diff(moment(), "day") < 0);
    }

    isCopiable(): boolean {
        return this.isExpired() && (moment(this.copyable_limit_date).diff(moment(), "day") >= 0);
    }

    isActionsAllowed(): boolean {
        return this.status === StorageStatus.OK && !this.isExpired();
    }

    getRoute(): string {
        return PathHelper.buildPathWithId(pathStoragesWithId, this.id);
    }
}

