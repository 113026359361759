import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonBack, ButtonSize, ButtonValidate, FieldBlock, FormLayoutRows, InputText, ModalActions, ModalActionsAlignment, ModalContent, ModalHeader, ModalHeaderDirection, ModalHeaderTitle, ModalStepper} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {StorageUploadRequest} from "../../../api/model/storage/StorageUploadRequest";
import {STORAGE_NAME_MAX_LENGTH, STORAGE_NAME_MIN_LENGTH} from "../../../api/model/storage/Storage";

type ModalUploadStorageValidationProps = {
    initStorageUploadRequest: StorageUploadRequest;
    onSubmit: (storageUploadRequest: StorageUploadRequest) => void;
    onGoBack: () => void;
}

const ModalUploadStorageValidation: FunctionComponent<ModalUploadStorageValidationProps> = ({initStorageUploadRequest, onSubmit, onGoBack}) => {
    const {t: textStorageUpload} = useTranslation(TranslationPortalFile.STORAGE_UPLOAD);
    const [storageUploadRequestName, setStorageUploadRequestName] = useState<string>("");

    useEffect(() => {
        setStorageUploadRequestName(initStorageUploadRequest.name);
    }, [initStorageUploadRequest]);

    const handleSubmit = () => {
        let newStorageUploadRequest = new StorageUploadRequest();
        newStorageUploadRequest.load(initStorageUploadRequest);
        newStorageUploadRequest.name = storageUploadRequestName.trim();
        onSubmit(newStorageUploadRequest);
    };

    return (
        <>
            <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                <ModalStepper steps={3} activeStep={2}/>
                <ModalHeaderTitle title={textStorageUpload("modal_validation.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <FieldBlock label={textStorageUpload("modal_validation.database_name")}>
                        <InputText
                            value={storageUploadRequestName}
                            onChange={setStorageUploadRequestName}
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <ButtonBack onClick={onGoBack}/>
                <ButtonValidate
                    size={ButtonSize.MEDIUM}
                    onClick={handleSubmit}
                    disabled={storageUploadRequestName.trim().length < STORAGE_NAME_MIN_LENGTH || storageUploadRequestName.trim().length > STORAGE_NAME_MAX_LENGTH}
                />
            </ModalActions>
        </>
    );
};

export default ModalUploadStorageValidation;
