export enum StorageFilterStatus {
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    OK = "OK",
    ERROR = "ERROR",
    DELETED = "DELETED"
}

export const STORAGE_FILTER_STATUSES: StorageFilterStatus[] = [
    StorageFilterStatus.OK,
    StorageFilterStatus.PENDING,
    StorageFilterStatus.PROCESSING,
    StorageFilterStatus.ERROR
];