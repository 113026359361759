import {ButtonBack, ButtonSize, ButtonValidate, FieldBlock, FormLayoutRows, FormLayoutSeparator, ModalActions, ModalActionsAlignment, ModalContent, ModalDescription, RadioButtons, SelectAutocomplete} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Storage} from "../../../api/model/storage/Storage";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {StoragesQuery} from "../../../api/model/storage/search/StoragesQuery";
import {TranslationPortalFile} from "../../../utils/constants";
import {RequestedOrdersQuery} from "../../../api/model/order/search/RequestedOrdersQuery";
import {Order} from "../../../api/model/order/Order";
import {StorageFilterStorage} from "../../../api/model/storage-filter/storage/StorageFilterStorage";
import {StorageFilterType} from "../../../api/model/storage-filter/storage/StorageFilterType";
import {StorageFilterOrder} from "../../../api/model/storage-filter/order/StorageFilterOrder";

export enum DeduplicationType {
    NONE = "NONE",
    BASE = "BASE",
    ORDER = "ORDER"
}

export const DEDUPLICATION_TYPES: DeduplicationType[] = [
    DeduplicationType.NONE,
    DeduplicationType.BASE,
    DeduplicationType.ORDER
];

type ModalProcessCountingHashDeduplicationProps = {
    initCounting: StorageFilter;
    onSubmit: (counting: StorageFilter) => void;
    onGoBack: () => void;
}

const ModalProcessCountingHashDeduplication: FunctionComponent<ModalProcessCountingHashDeduplicationProps> = ({initCounting, onSubmit, onGoBack}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);
    const [isLoadingStorages, setLoadingStorages] = useState<boolean>(true);
    const [isLoadingOrders, setLoadingOrders] = useState<boolean>(true);

    const [orders, setOrders] = useState<Order[]>([]);
    const [storages, setStorages] = useState<Storage[]>([]);
    const [currentStorages, setCurrentStorages] = useState<Storage[]>([]);

    const [currentDeduplicationType, setCurrentDeduplicationType] = useState<DeduplicationType>(DeduplicationType.NONE);
    const [selectedStorageId, setSelectedStorageId] = useState<number>();
    const [selectedOrderId, setSelectedOrderId] = useState<number>();

    useEffect(() => {
        const currentStorages = storages.filter((it) => it.id !== initCounting.storage_id && it.isActionsAllowed());
        if (initCounting.storage_filter.length) {
            setCurrentDeduplicationType(DeduplicationType.BASE);
            const storageFilter = initCounting.storage_filter.find((it) => it.filter_type === StorageFilterType.EXCLUDE && !it.filter_id);
            if (storageFilter) setSelectedStorageId(storageFilter.storage_id);
        } else if (initCounting.order_filter.length) {
            setCurrentDeduplicationType(DeduplicationType.ORDER);
            setSelectedOrderId(initCounting.order_filter[0].order_id);
        } else {
            setCurrentDeduplicationType(DeduplicationType.NONE);
        }
        setCurrentStorages(currentStorages);
    }, [initCounting, storages]);

    useEffect(() => {
        (async function () {
            try {
                setLoadingStorages(true);
                const storages = await session.restStorage.getStorages(new StoragesQuery());
                setStorages(storages);
            } catch (e) {
                console.error("Failed to load storages", e);
            } finally {
                setLoadingStorages(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async function () {
            try {
                setLoadingOrders(true);
                const orders = await session.restOrder.getRequestedOrders(new RequestedOrdersQuery());
                setOrders(orders);
            } catch (e) {
                console.error("Failed to load orders", e);
            } finally {
                setLoadingOrders(false);
            }
        })();
    }, []);

    const handleSubmit = () => {
        let newCounting = new StorageFilter(initCounting);
        switch (currentDeduplicationType) {
            case DeduplicationType.BASE:
                newCounting.storage_filter = selectedStorageId ? [new StorageFilterStorage({storage_id: selectedStorageId})] : [];
                newCounting.order_filter = [];
                break;
            case DeduplicationType.ORDER:
                newCounting.order_filter = selectedOrderId ? [new StorageFilterOrder({order_id: selectedOrderId})] : [];
                newCounting.storage_filter = [];
                break;
            default:
                break;
        }
        onSubmit(newCounting);
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textProcessCounting("hash_deduplication.description")}}/>
                    </ModalDescription>
                    <FormLayoutSeparator/>
                    <FieldBlock label={textProcessCounting("hash_deduplication.deduplication_type")}>
                        <RadioButtons
                            id="currentDeduplicationType"
                            value={currentDeduplicationType}
                            options={DEDUPLICATION_TYPES.map((it) => {
                                return {value: it, label: textProcessCounting(`deduplication_types.${it}`)};
                            })}
                            onChange={(value) => setCurrentDeduplicationType(value as DeduplicationType)}
                        />
                    </FieldBlock>
                    {currentDeduplicationType === DeduplicationType.BASE &&
                        <FieldBlock label={textProcessCounting("hash_deduplication.base_select")}>
                            <SelectAutocomplete
                                value={selectedStorageId}
                                isLoading={isLoadingStorages}
                                options={currentStorages.map((it) => ({value: it.id, label: `${it.id} - ${it.name}`}))}
                                onChange={(option) => setSelectedStorageId(option ? +option.value : undefined)}
                                clearable
                            />
                        </FieldBlock>
                    }
                    {currentDeduplicationType === DeduplicationType.ORDER &&
                        <FieldBlock label={textProcessCounting("hash_deduplication.order_select")}>
                            <SelectAutocomplete
                                value={selectedOrderId}
                                isLoading={isLoadingOrders}
                                options={orders.map((it) => ({value: it.id, label: `${it.id} - ${it.comment}`, order: it}))}
                                onChange={(option) => setSelectedOrderId(option ? +option.value : undefined)}
                                clearable
                            />
                        </FieldBlock>
                    }
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <ButtonBack onClick={onGoBack}/>
                <ButtonValidate size={ButtonSize.MEDIUM} onClick={handleSubmit}/>
            </ModalActions>
        </>
    );
};

export default ModalProcessCountingHashDeduplication;
