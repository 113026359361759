import {FunctionComponent, useEffect, useState} from "react";
import {AlertSeverity, Button, ButtonSize, ButtonValidate, EmptyContentMessage, FieldBlock, FormLayoutRows, Loadable, ModalActions, ModalActionsAlignment, ModalContent, SelectAutocomplete} from "@sirdata/ui-lib";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {Storage} from "../../../api/model/storage/Storage";
import {useTranslation} from "react-i18next";
import {pathStorages, TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {useNavigate} from "react-router-dom";
import {StoragesQuery} from "../../../api/model/storage/search/StoragesQuery";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {SvgStoragesEmpty} from "../../svg";

type ModalProcessCountingStorageSelectionProps = {
    initCounting: StorageFilter;
    onSubmit: (counting: StorageFilter) => void;
};

const ModalProcessCountingStorageSelection: FunctionComponent<ModalProcessCountingStorageSelectionProps> = ({initCounting, onSubmit}) => {
    const navigate = useNavigate();
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);
    const {t: textCountings} = useTranslation(TranslationPortalFile.COUNTINGS);
    const [isLoading, setLoading] = useState<boolean>(true);

    const [storages, setStorages] = useState<Storage[]>([]);
    const [storage, setStorage] = useState<Storage>();

    const handleSubmit = () => {
        if (!storage) return;
        let newCounting = new StorageFilter();
        newCounting.load(initCounting);
        newCounting.storage = storage;
        newCounting.storage_id = storage.id;
        onSubmit(newCounting);
    };

    useEffect(() => {
        const newStorage = storages.find((it) => it.id === initCounting.storage_id);
        setStorage(newStorage);
    }, [initCounting, storages]);

    useEffect(() => {
        (async function () {
            try {
                let newStorages = await session.restStorage.getStorages(new StoragesQuery());
                newStorages = newStorages.filter((storage) => storage.isActionsAllowed());
                setStorages(newStorages);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    UIEventManager.alert(textCountings("error.get_storages"), AlertSeverity.DANGER);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [textCountings]);

    return (
        <Loadable loading={isLoading}>
            {storages.length ?
                <>
                    <ModalContent>
                        <FormLayoutRows>
                            <FieldBlock label={textProcessCounting("storage_selection.select_storage")}>
                                <SelectAutocomplete
                                    value={storage?.id}
                                    options={storages.map((storage) => ({value: storage.id, label: storage.name, storage: storage}))}
                                    onChange={(option) => setStorage(option ? option.storage : undefined)}
                                    clearable
                                />
                            </FieldBlock>
                        </FormLayoutRows>
                    </ModalContent>
                    <ModalActions alignment={ModalActionsAlignment.RIGHT}>
                        <ButtonValidate
                            size={ButtonSize.MEDIUM}
                            onClick={handleSubmit}
                            disabled={!storage}
                        />
                    </ModalActions>
                </> :
                <ModalContent>
                    <EmptyContentMessage svg={SvgStoragesEmpty} message={textCountings("empty_storages.message")}>
                        <Button onClick={() => navigate(pathStorages)}>{textCountings("empty_storages.add_storage")}</Button>
                    </EmptyContentMessage>
                </ModalContent>
            }
        </Loadable>
    );
};

export default ModalProcessCountingStorageSelection;
