import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, AlertItem, AlertSeverity, ButtonBack, ButtonSize, ButtonValidate, FieldBlock, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormLayoutTitle, InputRadio, InputText, ModalActions, ModalActionsAlignment, ModalContent, ModalDescription, ModalHeader, ModalHeaderDirection, ModalHeaderTitle, ModalStepper, RadioButtons, Table, TableColumn, TableColumnStyle, TableRow, ToggleSwitch} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {getMappingColumnPosition, isValidMappingValue, StorageUploadRequest, SUPPORTED_COLUMN_SEPARATORS} from "../../../api/model/storage/StorageUploadRequest";
import clsx from "clsx";

type ModalUploadStorageConfigurationProps = {
    initStorageUploadRequest: StorageUploadRequest;
    fileFirstRows: string[];
    onSubmit: (storageUploadRequest: StorageUploadRequest, fileFirstRows?: string[]) => void;
    onGoBack: () => void;
}

const ModalUploadStorageConfiguration: FunctionComponent<ModalUploadStorageConfigurationProps> = ({initStorageUploadRequest, fileFirstRows, onSubmit, onGoBack}) => {
    const {t: textStorageUpload} = useTranslation(TranslationPortalFile.STORAGE_UPLOAD);
    const [message, setMessage] = useState<AlertItem>();

    const [storageUploadRequest, setStorageUploadRequest] = useState<StorageUploadRequest>(initStorageUploadRequest);
    const [currentFileFirstRows, setCurrentFileFirstRows] = useState<string[]>(initStorageUploadRequest.csv_has_header ? [...fileFirstRows].slice(1) : [...fileFirstRows].slice(0));

    const isValidMappingTypePosition = (rows: string[], columnPosition: number) => {
        return rows.every((row) => {
            const value = row.split(storageUploadRequest.csv_separator)[columnPosition - 1];
            return isValidMappingValue(value);
        });
    };

    const updateUploadRequest = (storageUploadRequest: StorageUploadRequest) => {
        setMessage(undefined);
        let newCurrentFileFirstRows = [];
        let newStorageUploadRequest = storageUploadRequest;
        if (fileFirstRows.length > 0) {
            if (storageUploadRequest.csv_has_header) {
                newCurrentFileFirstRows = [...fileFirstRows].slice(1);
                newStorageUploadRequest.csv_column_names = fileFirstRows[0].split(storageUploadRequest.csv_separator);
            } else {
                newCurrentFileFirstRows = [...fileFirstRows].slice(0);
                const columnsCount = fileFirstRows[0].split(storageUploadRequest.csv_separator).length;
                newStorageUploadRequest.csv_column_names = Array(columnsCount).fill("");
            }
            let newMappingColumnPosition = getMappingColumnPosition(newCurrentFileFirstRows, storageUploadRequest.csv_separator);
            if (!newMappingColumnPosition) {
                newMappingColumnPosition = 1;
                setMessage({text: textStorageUpload("error.selected_hash_column_invalid"), severity: AlertSeverity.DANGER});
            }
            newStorageUploadRequest.csv_mapping_column_position = newMappingColumnPosition;

            setCurrentFileFirstRows(newCurrentFileFirstRows);
            setStorageUploadRequest(newStorageUploadRequest);
        }
    };

    const handleChange = (name: string, value: string | boolean) => {
        let newStorageUploadRequest = new StorageUploadRequest();
        newStorageUploadRequest.load(storageUploadRequest);
        if (name === "csv_has_header") {
            newStorageUploadRequest.csv_has_header = !storageUploadRequest.csv_has_header;
        } else {
            newStorageUploadRequest[name] = value;
        }
        updateUploadRequest(newStorageUploadRequest);
    };

    const handleChangeMappingColumnPosition = (newColumnPosition: number) => {
        setMessage(undefined);
        if (currentFileFirstRows.length > 0) {
            if (!isValidMappingTypePosition(currentFileFirstRows, newColumnPosition)) {
                setMessage({text: textStorageUpload("error.selected_hash_column_invalid"), severity: AlertSeverity.DANGER});
            }
        }
        setStorageUploadRequest((prevState) => {
            let newStorageUploadRequest = new StorageUploadRequest();
            newStorageUploadRequest.load(prevState);
            newStorageUploadRequest.csv_mapping_column_position = newColumnPosition;
            return newStorageUploadRequest;
        });
    };

    const handleChangeColumnName = (value: string, colIndex: number) => {
        setStorageUploadRequest((prevState) => {
            let newStorageUploadRequest = new StorageUploadRequest();
            newStorageUploadRequest.load(prevState);
            newStorageUploadRequest.csv_column_names[colIndex] = value;
            return newStorageUploadRequest;
        });
    };

    return (
        <>
            <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                <ModalStepper steps={3} activeStep={1}/>
                <ModalHeaderTitle title={textStorageUpload("modal_configuration.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textStorageUpload("modal_configuration.description")}}/>
                    </ModalDescription>
                    <FormLayoutSeparator/>
                    <FormLayoutTitle>{textStorageUpload("modal_configuration.file_setting")}</FormLayoutTitle>
                    <FormLayoutColumns>
                        <FieldBlock label={textStorageUpload("modal_configuration.column_separator")}>
                            <RadioButtons
                                id="csv_separator"
                                value={storageUploadRequest.csv_separator}
                                options={SUPPORTED_COLUMN_SEPARATORS.map((separator) => {
                                    return {value: separator.value, label: textStorageUpload(`separators.${separator.label}`)};
                                })}
                                onChange={(value) => handleChange("csv_separator", value as string)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textStorageUpload("modal_configuration.column_names")}>
                            <ToggleSwitch
                                name="csv_has_header"
                                label={textStorageUpload("modal_configuration.contain_column_names")}
                                checked={storageUploadRequest.csv_has_header}
                                onChange={(value) => handleChange("csv_has_header", value)}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    {message &&
                        <Alert text={message.text} severity={message.severity}/>
                    }
                    <Table
                        columns={storageUploadRequest.csv_column_names?.map((columnName, colIndex) => (
                            {
                                label: (
                                    <div className={clsx("storage-table__header-inputs", {"highlighted": (colIndex + 1) === storageUploadRequest.csv_mapping_column_position})}>
                                        <InputRadio
                                            value={colIndex + 1}
                                            checked={(colIndex + 1) === storageUploadRequest.csv_mapping_column_position}
                                            onChange={(value) => handleChangeMappingColumnPosition(value as number)}
                                        />
                                        <InputText
                                            placeholder={textStorageUpload("modal_configuration.column_name", {value: colIndex + 1})}
                                            value={columnName}
                                            onChange={(value) => handleChangeColumnName(value, colIndex)}
                                            small
                                        />
                                    </div>
                                ),
                                styles: TableColumnStyle.ALIGN_CENTER
                            }
                        ))}
                    >
                        {currentFileFirstRows.map((row, rowIndex) => {
                            const tableRowKey = `current-file-first-row-${rowIndex}`;
                            return (
                                <TableRow key={tableRowKey}>
                                    {row.split(storageUploadRequest.csv_separator).map((cell, cellIndex) => (
                                        <TableColumn key={`${tableRowKey}.${cellIndex.toString()}`}>{cell}</TableColumn>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </Table>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <ButtonBack
                    onClick={() => {
                        onGoBack();
                        setMessage(undefined);
                    }}
                />
                <ButtonValidate size={ButtonSize.MEDIUM} onClick={() => onSubmit(storageUploadRequest)} disabled={!!message}/>
            </ModalActions>
        </>
    );
};

export default ModalUploadStorageConfiguration;
