export enum OrderStatus {
    ACCEPTED = "ACCEPTED",
    CANCELED = "CANCELED",
    PENDING = "PENDING",
    PROCESSING = "PROCESSING",
    REJECTED = "REJECTED",
    WAITING_VALIDATION = "WAITING_VALIDATION"
}

export const ORDER_STATUS: OrderStatus[] = [
    OrderStatus.ACCEPTED,
    OrderStatus.CANCELED,
    OrderStatus.PENDING,
    OrderStatus.PROCESSING,
    OrderStatus.REJECTED,
    OrderStatus.WAITING_VALIDATION
];