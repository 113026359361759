export class StorageSortType {
    static RECENCY: StorageSortType = new StorageSortType("RECENCY", "creation_ts");
    static NAME: StorageSortType = new StorageSortType("NAME", "name");
    static ID: StorageSortType = new StorageSortType("ID", "id");

    name: string;
    field: string;

    constructor(name: string, field: string) {
        this.name = name;
        this.field = field;
    }

    static values(): StorageSortType[] {
        return [
            StorageSortType.RECENCY,
            StorageSortType.NAME,
            StorageSortType.ID
        ];
    }
}