import {ColumnFilterOperator} from "./ColumnFilterOperator";
import {StorageColumnFilter} from "./StorageColumnFilter";

export class ColumnFilterType {
    static EQUALS: ColumnFilterType = new ColumnFilterType("EQUALS", "equals", ColumnFilterOperator.EQUALS, false);
    static NOT_EQUALS: ColumnFilterType = new ColumnFilterType("NOT_EQUALS", "not_equals", ColumnFilterOperator.EQUALS, true);
    static EMPTY: ColumnFilterType = new ColumnFilterType("EMPTY", "empty", ColumnFilterOperator.EMPTY, false);
    static NOT_EMPTY: ColumnFilterType = new ColumnFilterType("NOT_EMPTY", "not_empty", ColumnFilterOperator.EMPTY, true);
    static CONTAINS: ColumnFilterType = new ColumnFilterType("CONTAINS", "contains", ColumnFilterOperator.CONTAINS, false);
    static STARTS_WITH: ColumnFilterType = new ColumnFilterType("STARTS_WITH", "starts_with", ColumnFilterOperator.PREFIX, false);
    static ENDS_WITH: ColumnFilterType = new ColumnFilterType("ENDS_WITH", "ends_with", ColumnFilterOperator.SUFFIX, false);

    name: string;
    label: string;
    operator: ColumnFilterOperator;
    not: boolean;

    constructor(name: string, label: string, operator: ColumnFilterOperator, not: boolean) {
        this.name = name;
        this.label = label;
        this.operator = operator;
        this.not = not;
    }

    static values(): ColumnFilterType[] {
        return [
            ColumnFilterType.EQUALS,
            ColumnFilterType.NOT_EQUALS,
            ColumnFilterType.EMPTY,
            ColumnFilterType.NOT_EMPTY,
            ColumnFilterType.CONTAINS,
            ColumnFilterType.STARTS_WITH,
            ColumnFilterType.ENDS_WITH
        ];
    }

    static getByStorageColumnFilter(storageColumnFilter: StorageColumnFilter): ColumnFilterType {
        const columnFilterType = this.values().find(({operator, not}) => (operator === storageColumnFilter.operator && not === storageColumnFilter.not));
        return columnFilterType || this.values()[0];
    }
}
