import {ApiModel} from "../../../../common/api/model/ApiModel";
import {StorageFilterType} from "./StorageFilterType";

export class StorageFilterStorage extends ApiModel {
    storage_id: number = 0;
    filter_id: number | null = null;
    filter_type: StorageFilterType = StorageFilterType.EXCLUDE;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            storage_id: this.storage_id,
            filter_id: this.filter_id,
            filter_type: this.filter_type
        };
    }
}
