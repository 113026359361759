import {ApiModel} from "../../../common/api/model/ApiModel";

export class StorageCsvOptions extends ApiModel {
    separator: string = ",";
    mapping_column_index: number = 0;
    column_names: string[] = [];
    has_header: boolean = true;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
