import {Rest} from "../../common/api/rest/Rest";
import {StorageFilter} from "../model/storage-filter/StorageFilter";
import {Order} from "../model/order/Order";
import {RequestedOrdersQuery} from "../model/order/search/RequestedOrdersQuery";

export class RestOrder extends Rest {

    getRequestedOrders(query: RequestedOrdersQuery): Promise<Order[]> {
        return this._client.get("customer/order", Order, {queryParams: query.getJsonParameters()}) as Promise<Order[]>;
    }

    getOrder(orderId: string): Promise<Order> {
        return this._client.get(`customer/order/${orderId}`, Order) as Promise<Order>;
    }

    cancelOrder(orderId: string) {
        return this._client.delete(`customer/order/${orderId}`);
    }

    createRequestOrder(order: Order): Promise<StorageFilter> {
        return this._client.post(`customer/storage/${order.storage_id.toString()}/filter/${order.filter_id.toString()}/order`, order, StorageFilter) as Promise<StorageFilter>;
    }

    downloadAcceptedOrder(orderId: string): Promise<string> {
        return this._client.get(`customer/order/${orderId}/data`) as Promise<string>;
    }
}
