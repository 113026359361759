import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Order} from "../../api/model/order/Order";
import {OrderTableRow} from "./index";
import {Alert, AlertItem, AlertSeverity, Button, ButtonSize, ButtonStyle, EmptyContentMessage, InputDate, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {ModalDeleteWarning} from "../modal";
import {session} from "../../api/ApiSession";
import {ORDER_STATUS} from "../../api/model/order/OrderStatus";
import {RequestedOrdersQuery} from "../../api/model/order/search/RequestedOrdersQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SvgOrdersEmpty} from "../svg";

type OrdersTableProps = {
    emptyContent: { message: string; children?: React.ReactNode };
    initOrdersQuery?: RequestedOrdersQuery;
    processOrder?: () => void;
}

const MAX_ROWS_PER_PAGE = 20;

const OrdersTable: FunctionComponent<OrdersTableProps> = ({emptyContent, initOrdersQuery, processOrder}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOrders} = useTranslation(TranslationPortalFile.ORDERS);
    const [message, setMessage] = useState<AlertItem>();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingRequiredOrders, setLoadingRequiredOrders] = useState(true);

    const [orders, setOrders] = useState<Order[]>([]);
    const [currentOrders, setCurrentOrders] = useState<Order[]>([]);

    const [requestedOrdersQuery, setRequestedOrdersQuery] = useState<RequestedOrdersQuery>(new RequestedOrdersQuery());

    const [activeCancelOrderWarning, setActiveCancelOrderWarning] = useState<number>();

    const loadRequiredOrders = useCallback(async () => {
        try {
            setLoadingRequiredOrders(true);
            const newOrders = await session.restOrder.getRequestedOrders(requestedOrdersQuery);
            setCurrentOrders(newOrders);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                setMessage({text: textOrders("error.get_orders"), severity: AlertSeverity.DANGER});
            }
        } finally {
            setLoadingRequiredOrders(false);
        }
    }, [requestedOrdersQuery, textOrders]);

    const handleChangeDates = (name: string, value: string) => {
        setRequestedOrdersQuery((prevState) => {
            const query = new RequestedOrdersQuery();
            query.load({...prevState, [name]: value});
            return query;
        });
    };

    const handleChangeQuery = (query: string) => {
        setRequestedOrdersQuery((prevState) => {
            const ordersQuery = new RequestedOrdersQuery();
            ordersQuery.load({...prevState, search: query});
            return ordersQuery;
        });
    };

    const clearFilters = () => {
        setRequestedOrdersQuery((prevState) => {
            const ordersQuery = new RequestedOrdersQuery();
            ordersQuery.load({...prevState});
            ordersQuery.status = "";
            ordersQuery.start_date = "";
            ordersQuery.end_date = "";
            return ordersQuery;
        });
    };

    const doCancelOrder = async (orderId?: number) => {
        if (!orderId) return;
        try {
            await session.restOrder.cancelOrder(orderId.toString());
            setActiveCancelOrderWarning(undefined);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                setMessage({text: textOrders("error.cancel_order"), severity: AlertSeverity.DANGER});
            }
        } finally {
            await loadRequiredOrders();
        }
    };

    useEffect(() => {
        (async function () {
            await loadRequiredOrders();
        })();
    }, [loadRequiredOrders]);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                !!initOrdersQuery && setRequestedOrdersQuery(initOrdersQuery);
                const newOrders = await session.restOrder.getRequestedOrders(initOrdersQuery || new RequestedOrdersQuery());
                setOrders(newOrders);
                setCurrentOrders(newOrders);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    setMessage({text: textOrders("error.get_orders"), severity: AlertSeverity.DANGER});
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [initOrdersQuery, textOrders]);

    return (
        <Loadable loading={isLoading}>
            {message && <Alert {...message} fullWidth/>}
            {orders.length ?
                <>
                    <SearchToolbar
                        searchBar={{placeholder: textOrders("search_for_orders"), value: requestedOrdersQuery.search, onChange: handleChangeQuery}}
                        actions={!!processOrder
                            ? [
                                <Button
                                    key={"processOrder"}
                                    onClick={processOrder}
                                    icon={{name: "add_circle"}}
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    size={ButtonSize.MEDIUM}
                                >
                                    {textOrders("new_order")}
                                </Button>
                            ]
                            : undefined
                        }
                        onClearFilters={(!!requestedOrdersQuery.status.length || !!requestedOrdersQuery.start_date.length || !!requestedOrdersQuery.end_date.length) ? clearFilters : undefined}
                    >
                        <SearchField label={textOrders("status")}>
                            <Select
                                value={requestedOrdersQuery.status}
                                options={ORDER_STATUS.map((value) => {
                                    return {value: value, label: t(`status.${value}`)};
                                })}
                                onChange={(option) => setRequestedOrdersQuery((prevState) => new RequestedOrdersQuery({...prevState, status: option?.value || ""}))}
                                clearable
                            />
                        </SearchField>
                        <SearchField label={textOrders("start_date")}>
                            <InputDate
                                value={requestedOrdersQuery.start_date}
                                onChange={(value) => handleChangeDates("start_date", value)}
                            />
                        </SearchField>
                        <SearchField label={textOrders("end_date")}>
                            <InputDate
                                value={requestedOrdersQuery.end_date}
                                onChange={(value) => handleChangeDates("end_date", value)}
                            />
                        </SearchField>
                    </SearchToolbar>
                    <Loadable loading={isLoadingRequiredOrders}>
                        {currentOrders.length > 0 ?
                            <Table
                                columns={[
                                    {width: 10, label: textOrders("field.status")},
                                    {width: 10, label: textOrders("field.id")},
                                    {width: 40, label: textOrders("field.name"), styles: TableColumnStyle.FIXED_WIDTH},
                                    {width: 10, label: textOrders("field.date"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                    {width: 5, label: textOrders("field.nb_contacts"), styles: TableColumnStyle.FIXED_WIDTH},
                                    {width: 10, label: textOrders("field.ip_address"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                    {width: 5, label: textOrders("field.cost"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                    {width: 10, label: textOrders("field.actions"), styles: TableColumnStyle.ALIGN_CENTER}
                                ]}
                                pagination={MAX_ROWS_PER_PAGE}
                            >
                                {currentOrders.map((order) =>
                                    <OrderTableRow key={order.id} order={order} actions={{onCancel: () => setActiveCancelOrderWarning(order.id)}}/>
                                )}
                            </Table> :
                            <SearchError query={requestedOrdersQuery.search}/>
                        }
                        <ModalDeleteWarning active={!!activeCancelOrderWarning} message={textOrders("actions.cancel_warning")} onClose={() => setActiveCancelOrderWarning(undefined)} onDelete={() => doCancelOrder(activeCancelOrderWarning)}/>
                    </Loadable>
                </> :
                <EmptyContentMessage svg={SvgOrdersEmpty} message={emptyContent.message}>
                    {emptyContent.children}
                </EmptyContentMessage>
            }
        </Loadable>
    );
};

export default OrdersTable;
