import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalTitle, TranslationLibFile} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalProcessCountingConfirmationProps = {
    isProcessError: boolean;
    onClose: () => void;
};

const ModalProcessCountingConfirmation: FunctionComponent<ModalProcessCountingConfirmationProps> = ({isProcessError, onClose}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <ModalContent>
            <ModalTitle>
                {textProcessCounting(`confirmation.${isProcessError ? "failed" : "success"}.title`)}
            </ModalTitle>
            <FormLayoutRows>
                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                    <span dangerouslySetInnerHTML={{__html: textProcessCounting(`confirmation.${isProcessError ? "failed" : "success"}.description`)}}/>
                </ModalDescription>
                <FormLayoutButtons>
                    <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={onClose}>
                        {textCommon(Action.CLOSE.labelKey)}
                    </Button>
                </FormLayoutButtons>
            </FormLayoutRows>
        </ModalContent>
    );
};

export default ModalProcessCountingConfirmation;
