import {ApiModel} from "../../../../common/api/model/ApiModel";
import {PricingStep} from "./PricingStep";

export class Pricing extends ApiModel {
    volume_cost_per_unit: number = 0;
    step_price_is_per_unit: boolean = false;
    steps_price: PricingStep[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "steps_price") {
                    this.steps_price = this.steps_price || [];
                    (o[key] as PricingStep[])?.forEach((obj) => {
                        const it = new PricingStep(obj);
                        this.steps_price.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }
}
