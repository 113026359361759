import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Order} from "../../../api/model/order/Order";
import {ORDER_LIMIT_TYPES, OrderLimitType} from "../../../api/model/order/OrderLimitType";
import {session} from "../../../api/ApiSession";
import {DimensionValue} from "../../../api/model/storage-filter/DimensionValue";
import {OrderLimitPerColumn} from "../../../api/model/order/OrderLimitPerColumn";
import {TranslationPortalFile} from "../../../utils/constants";
import {
    Action,
    Alert,
    AlertItem,
    AlertSeverity,
    ButtonNext,
    ButtonStyle,
    ButtonUpload,
    Color,
    ContentBlock,
    ContentBlockRow,
    ContentBlockRowElementRight,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    FormLayoutRows,
    FormLayoutSeparator,
    IconTooltip,
    InputNumber,
    Loadable,
    ModalActions,
    ModalActionsAlignment,
    ModalConfirmMessage,
    ModalContent,
    ModalDescription,
    RadioButtons,
    SearchToolbar,
    Select,
    Table,
    TableColumn,
    TableColumnStyle,
    TableRow
} from "@sirdata/ui-lib";
import * as XLSX from "xlsx";
import {Formatter} from "../../../common/utils/Formatter";
import {buildAndDownloadCSV} from "../../../common/utils/portal";
import {usePricingDetails} from "../../../utils/hooks";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ModalProcessOrderColumnLimitsProps = {
    initOrder: Order;
    onSubmit: (order: Order) => void;
}

const MAX_COLUMN_LIMIT_ROWS_PER_PAGE = 10;
const MAX_RESULT_OVERVIEW_ROWS = 5;

const ModalProcessOrderColumnLimits: FunctionComponent<ModalProcessOrderColumnLimitsProps> = ({initOrder, onSubmit}) => {
    const {t: textProcessOrder} = useTranslation(TranslationPortalFile.PROCESS_ORDER);
    const [pricingDetails] = usePricingDetails();

    const [isShowError, setShowError] = useState(false);
    const [importLimitsFileMessage, setImportLimitsFileMessage] = useState<AlertItem>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isLoadingDimensionValues, setLoadingDimensionValues] = useState<boolean>(true);
    const [isLoadingImport, setLoadingImport] = useState(false);

    const [orderLimitTypes, setOrderLimitTypes] = useState<OrderLimitType[]>(ORDER_LIMIT_TYPES);

    const [columnNames, setColumnNames] = useState<string[]>([]);
    const [countingRowsCount, setCountingRowsCount] = useState<number>(0);
    const [currentOrderLimitType, setCurrentOrderLimitType] = useState<OrderLimitType>(OrderLimitType.NONE);

    const [globalLimit, setGlobalLimit] = useState<number>(0);

    const [dimensionIndexes, setDimensionIndexes] = useState<number[]>([]);
    const [valuesPerDimensionIndex, setValuesPerDimensionIndex] = useState<Map<number, DimensionValue[]>>(new Map<number, DimensionValue[]>());
    const [currentDimensionIndex, setCurrentDimensionIndex] = useState<number>(-1);

    const [currentRowValues, setCurrentRowValues] = useState<DimensionValue[]>([]);
    const [rowValuesSearchQuery, setRowValuesSearchQuery] = useState<string>("");

    const [highlightedRowValuesIndexes, setHighlightedRowValuesIndexes] = useState<number[]>([]);

    const [globalCount, setGlobalCount] = useState(initOrder.filter.table.distinct_mapping_column_values);
    const [costProcessing, setCostProcessing] = useState(false);

    const [isShowModalConfirmResetLimits, setShowModalConfirmResetLimits] = useState(false);

    const updateCost = (count: number) => {
        setCostProcessing(true);
        setTimeout(() => {
            setCostProcessing(false);
            setGlobalCount(count);
        }, 250);
    };

    useEffect(() => {
        switch (currentOrderLimitType) {
            case OrderLimitType.NONE:
                updateCost(countingRowsCount);
                break;
            case OrderLimitType.GLOBAL:
                updateCost(globalLimit);
                break;
            case OrderLimitType.PER_COLUMN:
                const values = valuesPerDimensionIndex.get(currentDimensionIndex)?.map(({count, limit}) => limit || count) || [];
                updateCost(values.reduce((a, b) => a + b, 0));
                break;
            default:
                break;
        }
    }, [currentOrderLimitType, countingRowsCount, globalLimit, valuesPerDimensionIndex, currentDimensionIndex]);

    useEffect(() => {
        let newCurrentRowValues = valuesPerDimensionIndex.get(currentDimensionIndex) || [];
        if (rowValuesSearchQuery) {
            newCurrentRowValues = newCurrentRowValues.filter(({value}) => value.toLowerCase().includes(rowValuesSearchQuery.toLowerCase()));
        }
        setCurrentRowValues(newCurrentRowValues);
    }, [valuesPerDimensionIndex, currentDimensionIndex, rowValuesSearchQuery]);

    const handleChangeLimit = (dimensionRow: DimensionValue, newLimit: number) => {
        let values = valuesPerDimensionIndex.get(currentDimensionIndex) || [];
        const index = values.findIndex(({value}) => value === dimensionRow.value);
        if (index !== undefined) {
            values[index].limit = newLimit;
            setValuesPerDimensionIndex(new Map(valuesPerDimensionIndex.set(currentDimensionIndex, values)));
        }
    };

    const handleChangeColumn = async (columnIndex: number, limits?: Map<string, number>) => {
        try {
            setLoadingDimensionValues(true);
            if (columnIndex !== -1) {
                let values = valuesPerDimensionIndex.get(columnIndex);
                if (!values) {
                    values = await session.restStorageFilter.getDimensionValues(initOrder.filter, columnIndex);
                }
                if (!!limits) {
                    limits.forEach((limit, rowValue) => {
                        const index = values?.findIndex(({value}) => value === rowValue);
                        if (index !== undefined && values) {
                            values[index].limit = limit;
                        }
                    });
                }
                setValuesPerDimensionIndex(new Map(valuesPerDimensionIndex.set(columnIndex, values)));
            }
            setCurrentDimensionIndex(columnIndex);
            setRowValuesSearchQuery("");
        } catch (e) {
            setShowError(true);
            setTimeout(() => setShowError(false), 5000);
        } finally {
            setLoading(false);
            setLoadingDimensionValues(false);
        }
    };

    useEffect(() => {
        (async function () {
            let orderLimitType = !!initOrder.limit_per_column ? OrderLimitType.PER_COLUMN : (
                !!initOrder.limit ? OrderLimitType.GLOBAL : OrderLimitType.NONE
            );
            setCurrentOrderLimitType(orderLimitType);

            setColumnNames(initOrder.filter.storage.table.column_names);
            const countingRowsCount = initOrder.filter.table.distinct_mapping_column_values;
            setCountingRowsCount(countingRowsCount);

            setGlobalLimit(!!initOrder.limit ? initOrder.limit : countingRowsCount);

            const dimensionIndexes = initOrder.filter.dimension_indexes;
            setDimensionIndexes(dimensionIndexes);

            let newOrderLimitTypes = dimensionIndexes.length ? ORDER_LIMIT_TYPES : ORDER_LIMIT_TYPES.filter((type) => type !== OrderLimitType.PER_COLUMN);
            setOrderLimitTypes(newOrderLimitTypes);
            await handleChangeColumn(initOrder.limit_per_column?.column_index || -1, initOrder.limit_per_column?.limits);
        })();
    }, [initOrder]); // eslint-disable-line

    const downloadTemplate = () => {
        const headers = [
            textProcessOrder("column_limits.limit_type_per_column.table.headers.column_name"),
            textProcessOrder("column_limits.limit_type_per_column.table.headers.value"),
            textProcessOrder("column_limits.limit_type_per_column.table.headers.limit")
        ];
        let rows: string[][] = [];
        for (let i = 0; i < currentRowValues.length; i++) {
            rows.push([columnNames[currentDimensionIndex], "", ""]);
        }
        buildAndDownloadCSV("Sirdata_Order_Column_Limits", headers, rows);
    };

    const handleBrowseFile = (file?: File) => {
        setLoadingImport(true);
        try {
            if (file) {
                const reader = new FileReader();

                reader.onload = (evt) => {
                    const fileResult = evt.target?.result;
                    const workBook = XLSX.read(fileResult, {type: "binary"});
                    const workSheetName = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[workSheetName];
                    const dataString = XLSX.utils.sheet_to_csv(workSheet);

                    const fileRows = dataString.split(/\r\n|\r|\n/).slice(1);
                    let values: DimensionValue[] = [];
                    valuesPerDimensionIndex.get(currentDimensionIndex)?.forEach((dimensionValue) => {
                        values.push(new DimensionValue({value: dimensionValue.value, count: dimensionValue.count}));
                    });

                    let updatedRowIndexes: number[] = [];
                    fileRows.forEach((row, rowIndex) => {
                        const fileRow = row.split(",");
                        if (fileRow[0] === columnNames[currentDimensionIndex] && !!fileRow[1].length) {
                            const index = values.findIndex(({value}) => value.toLowerCase() === fileRow[1].toLowerCase());
                            const limit = Number(fileRow[2]);

                            if (!!limit) {
                                if (index === -1) {
                                    setImportLimitsFileMessage({text: textProcessOrder("import_limits_file_message.parsing_failed", {row: rowIndex + 2, value: fileRow}), severity: AlertSeverity.DANGER});
                                    setTimeout(() => {
                                        setImportLimitsFileMessage(undefined);
                                    }, 5000);
                                }
                                values[index].limit = (values[index].count > limit) ? limit : values[index].count;
                                updatedRowIndexes.push(index);
                            }
                        }
                    });

                    if (!!updatedRowIndexes.length) {
                        setValuesPerDimensionIndex(new Map(valuesPerDimensionIndex.set(currentDimensionIndex, values)));
                        setHighlightedRowValuesIndexes(updatedRowIndexes);
                        setImportLimitsFileMessage({text: textProcessOrder("import_limits_file_message.limits_imported", {count: updatedRowIndexes.length}), severity: AlertSeverity.SUCCESS});
                    } else {
                        setImportLimitsFileMessage({text: textProcessOrder("import_limits_file_message.no_matched_limits"), severity: AlertSeverity.DANGER});
                    }
                    setTimeout(() => {
                        setHighlightedRowValuesIndexes([]);
                        setImportLimitsFileMessage(undefined);
                    }, 5000);
                };

                reader.readAsBinaryString(file);
            }
        } catch (e) {
        } finally {
            setLoadingImport(false);
        }
    };

    const handleResetLimits = () => {
        currentRowValues.forEach((it) => handleChangeLimit(it, 0));
        setShowModalConfirmResetLimits(false);
    };

    const handleSubmit = () => {
        let columnLimits = new Map<string, number>();
        const valuesLimited = valuesPerDimensionIndex.get(currentDimensionIndex)?.filter(({limit}) => !!limit);
        valuesLimited?.forEach(({value, limit}) => {
            columnLimits.set(value, limit || 0);
        });

        let newOrder = new Order();
        newOrder.load(initOrder);

        if ([OrderLimitType.GLOBAL, OrderLimitType.NONE].includes(currentOrderLimitType)) {
            newOrder.limit = (currentOrderLimitType === OrderLimitType.NONE) ? 0 : globalLimit;
            newOrder.limit_per_column = null;
        }
        if (currentOrderLimitType === OrderLimitType.PER_COLUMN && !!columnLimits.size) {
            let newLimitPerColumn = new OrderLimitPerColumn();
            newLimitPerColumn.column_index = currentDimensionIndex;
            newLimitPerColumn.limits = columnLimits;

            newOrder.limit = 0;
            newOrder.limit_per_column = newLimitPerColumn;
        }

        newOrder.pendingCost = pricingDetails.getCostForVolume(globalCount);
        onSubmit(newOrder);
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textProcessOrder("column_limits.description")}}/>
                    </ModalDescription>
                    <FormLayoutSeparator/>
                    <Loadable loading={isLoading}>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            <FieldBlock label={textProcessOrder("column_limits.set_limit")}>
                                <RadioButtons
                                    id="currentOrderLimitType"
                                    value={currentOrderLimitType}
                                    options={orderLimitTypes.map((it) => {
                                        return {value: it, label: textProcessOrder(`column_limits_types.${it}`)};
                                    })}
                                    onChange={(value) => setCurrentOrderLimitType(value as OrderLimitType)}
                                />
                            </FieldBlock>
                            {currentOrderLimitType === OrderLimitType.GLOBAL &&
                                <ContentBlock
                                    header={{title: {label: textProcessOrder("column_limits.limit_type_global.result_overview")}}}
                                    cssClass={"form-order-limit-group"}
                                >
                                    <Table
                                        columns={[
                                            ...initOrder.filter.table.column_names.map((columnName) => {
                                                return {label: columnName};
                                            }),
                                            {label: textProcessOrder("column_limits.limit_type_global.row_count")}
                                        ]}
                                    >
                                        {[...initOrder.filter.table.row_sample].slice(0, MAX_RESULT_OVERVIEW_ROWS).map((row, rowIndex) => {
                                            const tableRowKey = `limit-type-global-row-${rowIndex}`;
                                            return (
                                                <TableRow key={tableRowKey}>
                                                    {row.map((value, index) => (
                                                        <TableColumn key={`${tableRowKey}.${index.toString()}`}>{(index === row.length - 1) ? Formatter.formatNumber(Number(value)) : value}</TableColumn>
                                                    ))}
                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                    <ContentBlockRow>
                                        <ContentBlockRowElementRight>
                                            <FormLayoutRows>
                                                <FieldBlock label={textProcessOrder("column_limits.limit_type_global.define_limit")}>
                                                    <InputNumber
                                                        value={globalLimit}
                                                        minValue={0}
                                                        maxValue={countingRowsCount}
                                                        onChange={(value) => setGlobalLimit(value)}
                                                        autoFocus
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </ContentBlockRowElementRight>
                                    </ContentBlockRow>
                                    {globalLimit < 10000 &&
                                        <ContentBlockRow>
                                            <ContentBlockRowElementRight>
                                                <Alert text={textProcessOrder("column_limits.limit_type_global.limit_warning")} severity={AlertSeverity.WARNING}/>
                                            </ContentBlockRowElementRight>
                                        </ContentBlockRow>
                                    }
                                </ContentBlock>
                            }
                            {currentOrderLimitType === OrderLimitType.PER_COLUMN &&
                                <div className="form-order-limit-group">
                                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                        <FieldBlock
                                            label={textProcessOrder("column_limits.limit_type_per_column.column_select")}
                                            tooltip={textProcessOrder("column_limits.limit_type_per_column.tooltip")}
                                            content={{noFullWidth: true}}
                                        >
                                            <div>
                                                <Select
                                                    value={currentDimensionIndex}
                                                    options={dimensionIndexes.map((index) => {
                                                        return {value: index, label: columnNames[index]};
                                                    })}
                                                    onChange={(option) => handleChangeColumn(option ? +option.value : -1)}
                                                    clearable
                                                />
                                            </div>
                                        </FieldBlock>
                                        {isShowError &&
                                            <FormLayoutMessage
                                                message={textProcessOrder("column_limits.limit_type_per_column.dimension_values_error")}
                                                severity={AlertSeverity.DANGER}
                                            />
                                        }
                                    </FormLayoutRows>
                                    <Loadable loading={isLoadingDimensionValues}>
                                        {(!isShowError && currentDimensionIndex !== -1) &&
                                            <ContentBlock>
                                                <SearchToolbar
                                                    searchBar={{placeholder: textProcessOrder("column_limits.limit_type_per_column.search"), value: rowValuesSearchQuery, onChange: setRowValuesSearchQuery}}
                                                    actions={[
                                                        !!valuesPerDimensionIndex.get(currentDimensionIndex)?.find((it) => !!it.limit) &&
                                                        <IconTooltip
                                                            key={"action-reset"}
                                                            icon={{name: "refresh", colorIcon: Color.MIDNIGHT}}
                                                            text={textProcessOrder("column_limits.limit_type_per_column.reset_limits")}
                                                            onClick={() => setShowModalConfirmResetLimits(true)}
                                                        />,
                                                        <IconTooltip
                                                            key={"action-download"}
                                                            icon={{...Action.DOWNLOAD.icon, colorIcon: Color.MIDNIGHT}}
                                                            text={textProcessOrder("column_limits.limit_type_per_column.download_template")}
                                                            onClick={downloadTemplate}
                                                        />,
                                                        <ButtonUpload
                                                            key={"action-upload"}
                                                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                            label={textProcessOrder("column_limits.limit_type_per_column.import_config_file")}
                                                            onChange={handleBrowseFile}
                                                            loading={isLoadingImport}
                                                        />
                                                    ]}
                                                />
                                                {importLimitsFileMessage &&
                                                    <Alert text={importLimitsFileMessage.text} severity={importLimitsFileMessage.severity} fullWidth/>
                                                }
                                                <Table
                                                    columns={[
                                                        {label: textProcessOrder("column_limits.limit_type_per_column.table.headers.column_name")},
                                                        {label: textProcessOrder("column_limits.limit_type_per_column.table.headers.value")},
                                                        {label: textProcessOrder("column_limits.limit_type_per_column.table.headers.total_count")},
                                                        {label: textProcessOrder("column_limits.limit_type_per_column.table.headers.limit")}
                                                    ]}
                                                    pagination={MAX_COLUMN_LIMIT_ROWS_PER_PAGE}
                                                >
                                                    {[...currentRowValues].map((dimensionValue, rowIndex) =>
                                                        <TableRow key={`limit-type-per-column-row-${rowIndex.toString()}`}>
                                                            <TableColumn styles={highlightedRowValuesIndexes.includes(rowIndex) ? TableColumnStyle.HIGHLIGHTED : []}>
                                                                {columnNames[currentDimensionIndex]}
                                                            </TableColumn>
                                                            <TableColumn styles={highlightedRowValuesIndexes.includes(rowIndex) ? TableColumnStyle.HIGHLIGHTED : []}>
                                                                {dimensionValue.value}
                                                            </TableColumn>
                                                            <TableColumn styles={highlightedRowValuesIndexes.includes(rowIndex) ? TableColumnStyle.HIGHLIGHTED : []}>
                                                                {Formatter.formatNumber(dimensionValue.count)}
                                                            </TableColumn>
                                                            <TableColumn styles={highlightedRowValuesIndexes.includes(rowIndex) ? TableColumnStyle.HIGHLIGHTED : []}>
                                                                <InputNumber
                                                                    value={dimensionValue.limit || 0}
                                                                    minValue={0}
                                                                    maxValue={dimensionValue.count}
                                                                    onChange={(value) => handleChangeLimit(dimensionValue, value)}
                                                                    small
                                                                />
                                                            </TableColumn>
                                                        </TableRow>
                                                    )}
                                                </Table>
                                            </ContentBlock>
                                        }
                                    </Loadable>
                                </div>
                            }
                        </FormLayoutRows>
                    </Loadable>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.RIGHT}>
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE} alignment={FlexContentAlignment.START}>
                    <div className="form-order-cost">
                        <span>{textProcessOrder("label.cost")}</span>
                        <Loadable loading={costProcessing} loaderOptions={{small: true}}>
                            <span className="h2">{`${pricingDetails.getCostForVolume(globalCount)}`}</span>
                        </Loadable>
                    </div>
                    <ButtonNext disabled={!globalCount} onClick={handleSubmit}/>
                </FlexContent>
            </ModalActions>
            <ModalConfirmMessage
                active={isShowModalConfirmResetLimits}
                cancel={{onClick: () => setShowModalConfirmResetLimits(false)}}
                confirm={{onClick: handleResetLimits}}
                message={textProcessOrder("message.confirm_reset_limits")}
            />
        </>
    );
};

export default ModalProcessOrderColumnLimits;
