import {MENU} from "./Menu";
import {ModalPartnerPricingUiEvent} from "../component/modal/ModalPartnerPricing";
import {Origin} from "../common/api/model/Origin";
import {pathHome} from "../common/utils/constants";
import {PortalConfig} from "../common/api/PortalConfig";
import {UIEventManager} from "../common/utils/UIEventManager";

export const PORTAL = new PortalConfig(Origin.CUSTOMER, pathHome)
    .withMenuItems(MENU)
    .withShortcuts([{target: () => UIEventManager.emit(ModalPartnerPricingUiEvent), icon: {name: "euro_symbol"}, label: "menu.rate_card"}])
;
