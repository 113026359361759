import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {pathOrders, TranslationPortalFile} from "../../utils/constants";
import {useNavigate, useParams} from "react-router-dom";
import {Order} from "../../api/model/order/Order";
import {session} from "../../api/ApiSession";
import {DimensionValue} from "../../api/model/storage-filter/DimensionValue";
import {MainContent, Wrapper} from "../../common/component/widget";
import {Action, AlertSeverity, Box, BoxProps, Button, ButtonSize, Color, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, ContentBlockRowTitle, FieldBlock, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormLayoutTitle, LayoutRows, Loadable, MainContentStyle, StatusLabel, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {Formatter} from "../../common/utils/Formatter";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {downloadCSV} from "../../common/utils/portal";
import {OrderStatus} from "../../api/model/order/OrderStatus";
import {UIEventManager} from "../../common/utils/UIEventManager";

const MAX_COLUMN_LIMIT_ROWS_PER_PAGE = 10;

function OrdersDetails() {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOrders} = useTranslation(TranslationPortalFile.ORDERS);
    const {id: orderId} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);
    const [isLoadingDownload, setLoadingDownload] = useState(false);

    const [order, setOrder] = useState<Order>();
    const [columnValuesLimited, setColumnValuesLimited] = useState<DimensionValue[]>([]);
    const [limitedColumnName, setLimitedColumnName] = useState<string>("");

    const getStatus = (order: Order) => {
        switch (order.status) {
            case OrderStatus.ACCEPTED:
                return <StatusLabel label={t(`status.${order.status}`)} icon={{name: "circle", colorIcon: Color.GREEN}}/>;
            case OrderStatus.REJECTED:
            case OrderStatus.CANCELED:
                return <StatusLabel label={t(`status.${order.status}`)} icon={{name: "circle", colorIcon: Color.ORANGE}}/>;
            default:
                return <StatusLabel label={t(`status.${order.status}`)} icon={{name: "circle", colorIcon: Color.GREY}}/>;
        }
    };

    const downloadOrder = async () => {
        if (!order) return;
        try {
            setLoadingDownload(true);
            const orderCsv = await session.restOrder.downloadAcceptedOrder(order.id.toString());

            let universalBOM = "\uFEFF";
            let csvFile = universalBOM + orderCsv;
            downloadCSV(order.comment.toString().split(".")[0], csvFile);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(textOrders("error.download_data", {message: e.message}), AlertSeverity.DANGER);
            }
        } finally {
            setLoadingDownload(false);
        }
    };

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const newOrder = await session.restOrder.getOrder(orderId);
                const columnIndex = newOrder.limit_per_column?.column_index;
                const limits: Map<string, number> | null = newOrder.limit_per_column && new Map(Object.entries(newOrder.limit_per_column.limits));
                if (!!limits && !!columnIndex) {
                    const columnName = newOrder.filter.storage.table.column_names[columnIndex];
                    let valuesLimited = await session.restStorageFilter.getDimensionValues(newOrder.filter, columnIndex);
                    limits.forEach((limit, columnValue) => {
                        const index = valuesLimited.findIndex(({value}) => value.toLowerCase() === columnValue.toLowerCase());
                        valuesLimited[index].limit = limit;
                    });
                    valuesLimited = [...valuesLimited].filter(({limit}) => !!limit);
                    setLimitedColumnName(columnName);
                    setColumnValuesLimited(valuesLimited);
                }
                setOrder(newOrder);
            } catch (e) {
                navigate(pathOrders);
            } finally {
                setLoading(false);
            }
        })();
    }, [navigate, orderId]);

    if (!order) return <></>;

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock>
                            <ContentBlockRow>
                                <ContentBlockRowTitle label={textOrders("section.general_information")}/>
                                {order.isDownloadable() &&
                                    <ContentBlockRowElementRight>
                                        <Button onClick={downloadOrder} icon={Action.DOWNLOAD.icon} size={ButtonSize.MEDIUM} loading={isLoadingDownload}>
                                            {textOrders("actions.download_data")}
                                        </Button>
                                    </ContentBlockRowElementRight>
                                }
                            </ContentBlockRow>
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <FormLayoutRows>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textOrders("field.id")}>
                                            {order.id.toString()}
                                        </FieldBlock>
                                        <FieldBlock label={textOrders("field.status")}>
                                            {getStatus(order)}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textOrders("field.name")}>
                                            {order.comment}
                                        </FieldBlock>
                                        <FieldBlock label={textOrders("field.ip_address")}>
                                            {order.ip_address || "-"}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textOrders("field.counting_name")}>
                                            {order.filter.name}
                                        </FieldBlock>
                                        <FieldBlock label={textOrders("field.storage_name")}>
                                            {order.filter.storage.name}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textOrders("field.order_date")}>
                                            {Formatter.formatDate(order.creation_ts)}
                                        </FieldBlock>
                                        <FieldBlock label={textOrders("field.last_update")}>
                                            {Formatter.formatDate(order.last_update_ts)}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    <FormLayoutSeparator/>
                                    <FormLayoutTitle>{textOrders("section.order_data")}</FormLayoutTitle>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textOrders("field.nb_contacts")}>
                                            {!!order.table?.row_count ? Formatter.formatNumber(order.table?.row_count) : "-"}
                                        </FieldBlock>
                                        <FieldBlock label={textOrders("field.cost")}>
                                            {order.cost ? `${Formatter.formatNumber(order.cost)} ${order.getOrderCurrencySymbol()}` : "-"}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    {!!order.discount &&
                                        <FieldBlock label={textOrders("field.discount")}>
                                            {`${Formatter.formatNumber(order.discount)} ${order.getOrderCurrencySymbol()}`}
                                        </FieldBlock>
                                    }
                                </FormLayoutRows>
                            </Box>
                        </ContentBlock>
                        {!!columnValuesLimited.length &&
                            <ContentBlock header={{title: {label: textOrders("section.column_limits")}}}>
                                <Table
                                    columns={[
                                        {label: textOrders("field.column_name")},
                                        {label: textOrders("field.value")},
                                        {label: textOrders("field.count")},
                                        {label: textOrders("field.limit")}
                                    ]}
                                    pagination={MAX_COLUMN_LIMIT_ROWS_PER_PAGE}
                                >
                                    {columnValuesLimited.map((value, index) => (
                                        <TableRow key={`column-limits-row-${index.toString()}`}>
                                            <TableColumn>{limitedColumnName}</TableColumn>
                                            <TableColumn>{value.value}</TableColumn>
                                            <TableColumn>{Formatter.formatNumber(value.count)}</TableColumn>
                                            <TableColumn>{Formatter.formatNumber(value.limit)}</TableColumn>
                                        </TableRow>
                                    ))}
                                </Table>
                            </ContentBlock>
                        }
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default OrdersDetails;
