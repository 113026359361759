import {useEffect, useState} from "react";
import {Alert, Box, BoxProps, Button, ButtonSize, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, ContentBlockRowTitle, ElementList, FieldBlock, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, LayoutRows, Loadable, MainContentStyle, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Formatter} from "../../common/utils/Formatter";
import {ColumnFilterType} from "../../api/model/storage-filter/column/ColumnFilterType";
import {SegmentFilterType} from "../../api/model/storage-filter/segment/SegmentFilterType";
import {MainContent} from "../../common/component/widget/index";
import {MainHeader} from "../../common/component/snippet";
import {ModalProcessOrder} from "../../component/modal";
import {OrdersTable, TagDataType, TagSegment} from "../../component/snippet/index";
import {Order} from "../../api/model/order/Order";
import {pathCountings, TranslationPortalFile} from "../../utils/constants";
import {RequestedOrdersQuery} from "../../api/model/order/search/RequestedOrdersQuery";
import {Segment} from "../../api/model/segment/Segment";
import {session} from "../../api/ApiSession";
import {StorageFilter} from "../../api/model/storage-filter/StorageFilter";
import {usePricingDetails} from "../../utils/hooks";
import {Wrapper} from "../../common/component/widget";
import {DataType} from "../../api/model/segment/DataType";

function CountingsDetails() {
    const navigate = useNavigate();
    const {t: textCountingsDetails} = useTranslation(TranslationPortalFile.COUNTINGS_DETAILS);
    const {storageId} = useParams() as {storageId: string};
    const {countingId} = useParams() as {countingId: string};
    const [pricingDetails] = usePricingDetails();

    const [activeModalProcessOrder, setActiveModalProcessOrder] = useState<Order>();
    const [counting, setCounting] = useState<StorageFilter>(new StorageFilter());
    const [columnNames, setColumnNames] = useState<string[]>([]);
    const [segmentFilterType, setSegmentFilterType] = useState<SegmentFilterType>(SegmentFilterType.NONE);
    const [segmentFilterSegments, setSegmentFilterSegments] = useState<Segment[]>([]);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const newCounting = await session.restStorageFilter.getStorageFilter(storageId, countingId);
                setCounting(newCounting);

                setColumnNames(newCounting.storage.table.column_names);

                // SEGMENT FILTER
                const segmentFilter = newCounting.segment_filter;
                let segmentFilterType = !!segmentFilter.data_type.length ? SegmentFilterType.SEGMENT_TYPE : (
                    (!!segmentFilter.segment.length || !!segmentFilter.segment_modeling.length) ? SegmentFilterType.SEGMENT : SegmentFilterType.NONE
                );
                setSegmentFilterType(segmentFilterType);

                let segmentIdList: number[] = [];
                if (!!segmentFilter.segment?.length) {
                    segmentIdList = segmentFilter.segment.map(({id}) => id);
                }
                if (!!segmentFilter.segment_modeling?.length) {
                    const segmentModelingList = segmentFilter.segment_modeling.map(({id}) => id);
                    segmentIdList = [...segmentIdList, ...segmentModelingList];
                }
                if (!!segmentIdList.length) {
                    const result = await session.restSegment.getSegments(segmentIdList.join(","));
                    if (!!result.segments.length) {
                        let segments: Segment[] = [];
                        segmentIdList.forEach((id) => {
                            const segment = result.segments.find((segment) => segment.id === id);
                            !!segment && segments.push(segment);
                        });
                        setSegmentFilterSegments(segments);
                    }
                }
            } catch (e) {
                navigate(pathCountings);
            } finally {
                setLoading(false);
            }
        })();
    }, [navigate, storageId, countingId]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock header={{title: {label: textCountingsDetails("general_information.title")}}}>
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <FormLayoutRows>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textCountingsDetails("general_information.id")}>
                                            {counting.id.toString()}
                                        </FieldBlock>
                                        <FieldBlock label={textCountingsDetails("general_information.name")}>
                                            {counting.name}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textCountingsDetails("general_information.storage")}>
                                            {counting.storage.name}
                                        </FieldBlock>
                                        <FieldBlock label={textCountingsDetails("general_information.creation_date")}>
                                            {Formatter.formatDate(counting.creation_ts)}
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                    <FieldBlock label={textCountingsDetails("general_information.distinct_mapping_column_matched")}>
                                        {Formatter.formatNumber(counting.table.distinct_mapping_column_values)}
                                    </FieldBlock>
                                    {!!counting.dimension_indexes.length &&
                                        <FormLayoutSeparator/>
                                    }
                                    {counting.dimension_indexes.map((nameIndex, index) =>
                                        <FieldBlock key={`column-name-${nameIndex}`} label={textCountingsDetails("general_information.column", {value: index + 1})}>
                                            {columnNames[nameIndex]}
                                        </FieldBlock>
                                    )}
                                </FormLayoutRows>
                            </Box>
                        </ContentBlock>
                        {!!counting.column_filter.length &&
                            <ContentBlock header={{title: {label: textCountingsDetails("column_filters.title")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <ElementList>
                                        {counting.column_filter.map((it) =>
                                            <div className="form-counting-filter" key={`column-filter-${it.column_index}`}>
                                                <span>{columnNames[it.column_index]}</span>
                                                {textCountingsDetails(`column_filters.types.${ColumnFilterType.getByStorageColumnFilter(it).label}`)}
                                                {!!it.values.length && <span>{it.values.join(",")}</span>}
                                            </div>
                                        )}
                                    </ElementList>
                                </Box>
                            </ContentBlock>
                        }
                        <ContentBlock header={{title: {label: textCountingsDetails("segment_filters.title")}}}>
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <FormLayoutRows>
                                    <FieldBlock label={textCountingsDetails("segment_filters.type")}>
                                        {textCountingsDetails(`segment_filters.types.${segmentFilterType.label}`)}
                                    </FieldBlock>
                                    {segmentFilterType.name === SegmentFilterType.SEGMENT.name &&
                                        <FieldBlock label={textCountingsDetails("segment_filters.segments")}>
                                            <div className="form-counting__field-content-column">
                                                <Alert text={textCountingsDetails("segment_filters.order_list_tooltip")}/>
                                                <ElementList>
                                                    {segmentFilterSegments.map((segment) => (
                                                        <TagSegment key={segment.id} segment={segment}/>
                                                    ))}
                                                </ElementList>
                                            </div>
                                        </FieldBlock>
                                    }
                                    {segmentFilterType.name === SegmentFilterType.SEGMENT_TYPE.name &&
                                        <FieldBlock label={textCountingsDetails("segment_filters.segment_types")}>
                                            <ElementList inline>
                                                {DataType.values().map((item) =>
                                                    <TagDataType key={item.name} type={item} active={counting.segment_filter.data_type.includes(item.name)}/>
                                                )}
                                            </ElementList>
                                        </FieldBlock>
                                    }
                                </FormLayoutRows>
                            </Box>
                        </ContentBlock>
                        <ContentBlock header={{title: {label: textCountingsDetails("result_overview.title")}}}>
                            <Alert text={textCountingsDetails("result_overview.order_list_tooltip")} fullWidth/>
                            <Table
                                columns={[
                                    ...counting.table.column_names.map((columnName) => {
                                        return {label: columnName};
                                    }),
                                    {label: textCountingsDetails("result_overview.volume")}
                                ]}
                            >
                                {counting.table.row_sample.map((row, rowIndex) => {
                                    const tableRowKey = `result-row-${rowIndex}`;
                                    return (
                                        <TableRow key={tableRowKey}>
                                            {row.map((value, index) => (
                                                <TableColumn key={`${tableRowKey}.${index.toString()}`}>{(index === row.length - 1) ? Formatter.formatNumber(Number(value)) : value}</TableColumn>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </Table>
                            <ContentBlockRow>
                                <ContentBlockRowElementRight>
                                    <span
                                        className="potential-budget-row"
                                        dangerouslySetInnerHTML={{__html: textCountingsDetails("result_overview.potential_budget", {price: pricingDetails.getCostForVolume(counting.table.distinct_mapping_column_values)})}}
                                    />
                                </ContentBlockRowElementRight>
                            </ContentBlockRow>
                        </ContentBlock>
                        <ContentBlock>
                            <ContentBlockRow>
                                <ContentBlockRowTitle label={textCountingsDetails("orders.title")}/>
                                {counting.isActionsAllowed() && !counting.orders.length &&
                                    <ContentBlockRowElementRight>
                                        <Button onClick={() => setActiveModalProcessOrder(counting.getOrder())} icon={{name: "add_circle"}} size={ButtonSize.MEDIUM}>
                                            {textCountingsDetails("orders.order")}
                                        </Button>
                                    </ContentBlockRowElementRight>
                                }
                            </ContentBlockRow>
                            <OrdersTable
                                initOrdersQuery={new RequestedOrdersQuery({storage_id: storageId, filter_id: countingId})}
                                processOrder={counting.isActionsAllowed() ? () => setActiveModalProcessOrder(counting.getOrder()) : undefined}
                                emptyContent={{message: textCountingsDetails("orders.no_orders_for_counting")}}
                            />
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
                <ModalProcessOrder initOrder={activeModalProcessOrder} onClose={() => setActiveModalProcessOrder(undefined)}/>
            </MainContent>
        </Wrapper>
    );
}

export default CountingsDetails;
