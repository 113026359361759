import {Rest} from "../../common/api/rest/Rest";
import {Storage} from "../model/storage/Storage";
import {StoragesQuery} from "../model/storage/search/StoragesQuery";

export class RestStorage extends Rest {
    getStorages(query: StoragesQuery): Promise<Storage[]> {
        return this._client.get("customer/storage", Storage, {queryParams: query.getJsonParameters()}) as Promise<Storage[]>;
    }

    getStorage(storageId: string): Promise<Storage> {
        return this._client.get("customer/storage/" + storageId, Storage) as Promise<Storage>;
    }

    uploadStorage(storageUploadRequest: FormData): Promise<Storage> {
        return this._client.post("customer/storage", storageUploadRequest, Storage) as Promise<Storage>;
    }

    copyStorage(storageId: number): Promise<Storage> {
        return this._client.post(`customer/storage/${storageId}/copy`) as Promise<Storage>;
    }

    deleteStorage(storageId: number) {
        return this._client.delete("customer/storage/" + storageId);
    }
}
