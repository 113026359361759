import React, {FunctionComponent, useEffect, useState} from "react";
import {StorageFilter} from "../../../api/model/storage-filter/StorageFilter";
import {ModalProcessCountingColumnsFilters, ModalProcessCountingColumnsSelection, ModalProcessCountingConfirmation, ModalProcessCountingHashDeduplication, ModalProcessCountingSegmentsFilters, ModalProcessCountingStorageSelection, ModalProcessCountingValidation} from "../index";
import {ProcessCountingStep} from "../../../utils/ProcessCountingStep";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {ModalHeader, ModalHeaderDirection, ModalHeaderTitle, ModalNew, ModalStepper} from "@sirdata/ui-lib";

type ModalProcessCountingProps = {
    initCounting?: StorageFilter;
    onClose: (refresh: boolean) => void;
}

const ModalProcessCounting: FunctionComponent<ModalProcessCountingProps> = ({initCounting, onClose}) => {
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);
    const [isProcessError, setProcessError] = useState(false);

    const [counting, setCounting] = useState<StorageFilter>(new StorageFilter());
    const [processCountingSteps, setProcessCountingSteps] = useState<ProcessCountingStep[]>(ProcessCountingStep.values());
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<ProcessCountingStep>(ProcessCountingStep.STORAGE_SELECTION);

    useEffect(() => {
        setCurrentStep(processCountingSteps[currentStepIndex]);
    }, [processCountingSteps, currentStepIndex]);

    const handleGoToNextStep = (isError?: boolean) => {
        isError && setProcessError(true);
        setCurrentStepIndex((prev) => prev + 1);
    };

    const handleSubmitStep = (newCounting: StorageFilter) => {
        setCounting(newCounting);
        handleGoToNextStep();
    };

    const handleSubmitStepStorageSelection = (newCounting: StorageFilter) => {
        if (newCounting.storage.table.column_names.length === 1) {
            const newProcessCountingSteps = ProcessCountingStep.values().filter(({name}) => ![ProcessCountingStep.COLUMN_FILTERS.name, ProcessCountingStep.COLUMNS_SELECTION.name].includes(name));
            setProcessCountingSteps(newProcessCountingSteps);
        }
        handleSubmitStep(newCounting);
    };

    const handleGoBack = () => {
        (currentStepIndex > 0) && setCurrentStepIndex((prev) => prev - 1);
    };

    const handleClose = () => {
        setCounting(new StorageFilter());
        onClose(currentStep.name === ProcessCountingStep.CONFIRMATION.name);
        setCurrentStepIndex(0);
        setProcessError(false);
    };

    useEffect(() => {
        if (!!initCounting) {
            setCounting(initCounting);

            let newProcessCountingSteps = ProcessCountingStep.values();
            if (initCounting.storage.isActionsAllowed()) {
                newProcessCountingSteps = newProcessCountingSteps.filter(({name}) => name !== ProcessCountingStep.STORAGE_SELECTION.name);
            }
            if (initCounting.storage.table.column_names.length === 1) {
                newProcessCountingSteps = newProcessCountingSteps.filter(({name}) => ![ProcessCountingStep.COLUMN_FILTERS.name, ProcessCountingStep.COLUMNS_SELECTION.name].includes(name));
            }
            setProcessCountingSteps(newProcessCountingSteps);
        }
    }, [initCounting]);

    return (
        <ModalNew onClose={handleClose} active={!!initCounting} size={currentStep.modalSize}>
            {currentStep.name !== ProcessCountingStep.CONFIRMATION.name &&
                <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                    <ModalStepper steps={processCountingSteps.length - 1} activeStep={currentStepIndex}/>
                    <ModalHeaderTitle title={textProcessCounting(`${currentStep.label}.title`)}/>
                </ModalHeader>
            }
            {currentStep.name === ProcessCountingStep.STORAGE_SELECTION.name &&
                <ModalProcessCountingStorageSelection
                    initCounting={counting}
                    onSubmit={handleSubmitStepStorageSelection}
                />}

            {currentStep.name === ProcessCountingStep.COLUMN_FILTERS.name &&
                <ModalProcessCountingColumnsFilters
                    initCounting={counting}
                    onSubmit={handleSubmitStep}
                    onGoBack={currentStepIndex > 0 ? handleGoBack : undefined}
                />}

            {currentStep.name === ProcessCountingStep.SEGMENTS_FILTERS.name &&
                <ModalProcessCountingSegmentsFilters
                    initCounting={counting}
                    onSubmit={handleSubmitStep}
                    onGoBack={currentStepIndex > 0 ? handleGoBack : undefined}
                />}

            {currentStep.name === ProcessCountingStep.COLUMNS_SELECTION.name &&
                <ModalProcessCountingColumnsSelection
                    initCounting={counting}
                    onSubmit={handleSubmitStep}
                    onGoBack={handleGoBack}
                />}

            {currentStep.name === ProcessCountingStep.HASH_DEDUPLICATION.name &&
                <ModalProcessCountingHashDeduplication
                    initCounting={counting}
                    onSubmit={handleSubmitStep}
                    onGoBack={handleGoBack}
                />}

            {currentStep.name === ProcessCountingStep.VALIDATION.name &&
                <ModalProcessCountingValidation
                    initCounting={counting}
                    onSubmit={handleGoToNextStep}
                    onGoBack={handleGoBack}
                />}

            {currentStep.name === ProcessCountingStep.CONFIRMATION.name &&
                <ModalProcessCountingConfirmation isProcessError={isProcessError} onClose={handleClose}/>}
        </ModalNew>
    );
};

export default ModalProcessCounting;
