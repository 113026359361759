import React, {FunctionComponent} from "react";

const SvgColumnsFiltersEmpty: FunctionComponent = () => {

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="406"
            height="306"
            viewBox="0 0 558.65 585.42"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="columns-filters-empty-linear-gradient"
                    x1="342.44"
                    y1="446.53"
                    x2="326.67"
                    y2="980.37"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopOpacity="0"></stop>
                    <stop offset="0.98"></stop>
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-2"
                    x1="478.78"
                    y1="155.87"
                    x2="34.48"
                    y2="513.17"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-3"
                    x1="165.7"
                    y1="224.15"
                    x2="268.19"
                    y2="262.82"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-4"
                    x1="434.14"
                    y1="131.57"
                    x2="358.68"
                    y2="273.48"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-5"
                    x1="229.82"
                    y1="122.61"
                    x2="280.72"
                    y2="122.61"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#ecc4d7"></stop>
                    <stop offset="0.42" stopColor="#efd4d1"></stop>
                    <stop offset="1" stopColor="#f2eac9"></stop>
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-6"
                    x1="259.32"
                    y1="245.1"
                    x2="-185.83"
                    y2="180.06"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-7"
                    x1="310.46"
                    y1="212.26"
                    x2="290.19"
                    y2="361.68"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-8"
                    x1="379.98"
                    y1="179.97"
                    x2="427.54"
                    y2="179.97"
                    xlinkHref="#columns-filters-empty-linear-gradient-5"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-9"
                    x1="243.29"
                    y1="544.63"
                    x2="282.19"
                    y2="544.63"
                    xlinkHref="#columns-filters-empty-linear-gradient-5"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-10"
                    x1="102.31"
                    y1="511.23"
                    x2="124.93"
                    y2="511.23"
                    xlinkHref="#columns-filters-empty-linear-gradient-5"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-11"
                    x1="560.85"
                    y1="-82.12"
                    x2="494.97"
                    y2="1162.94"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-12"
                    x1="565.03"
                    y1="-81.9"
                    x2="499.15"
                    y2="1163.16"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-13"
                    x1="572.99"
                    y1="-81.47"
                    x2="507.11"
                    y2="1163.58"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-14"
                    x1="576.85"
                    y1="-81.27"
                    x2="510.97"
                    y2="1163.79"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="columns-filters-empty-linear-gradient-15"
                    x1="580.62"
                    y1="-81.07"
                    x2="514.73"
                    y2="1163.99"
                    xlinkHref="#columns-filters-empty-linear-gradient"
                >
                </linearGradient>
            </defs>
            <path
                d="M266.19,108.7c-43.13,26.16-97.53,32.28-136,64.91-13.58,11.53-24.54,25.93-33.37,41.39C68.12,265.3,62.23,328.08,81.1,382.85s62.17,100.59,115.78,122.53c25.64,10.49,53.13,15.66,80.6,19.27a762.82,762.82,0,0,0,152.78,4.6c32.21-2.27,66.22-7.39,91-28.09,16.52-13.8,27.28-33.27,35.6-53.12a313.52,313.52,0,0,0,20.08-170.88c-5.73-34.91-17.58-69.08-37.1-98.58-32.84-49.65-84.52-82.22-137.48-107C381.57,61.9,355,45,331.82,54.78,307.45,65.08,289.41,94.62,266.19,108.7Z"
                transform="translate(-67.17 -4.31)"
                fill="#68e1fd"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <g>
                <path
                    d="M84.34,527.25c-4.55.87-9.33,1.88-12.78,5-6.58,5.9-5.23,17.22.55,23.9s14.58,9.79,23,12.34c70.32,21.15,145,21.3,218.42,21.27,22.43,0,45.45-.12,66.4-8.14,14.52-5.55,27.41-14.69,41.73-20.76,53.75-22.8,116.64.85,173.47-12.56,6.67-1.57,14.08-4.43,16.4-10.88,4.69-13-14.27-20.95-28.1-21.63a1683.85,1683.85,0,0,0-182.11.86c-48.45,2.85-92.59-11.53-141.22-9.56A1194.73,1194.73,0,0,0,84.34,527.25Z"
                    transform="translate(-67.17 -4.31)"
                    opacity="0.52"
                    fill="url(#columns-filters-empty-linear-gradient)"
                >
                </path>
                <path d="M361,515.72H344.9A20.78,20.78,0,0,1,324.12,495V25.09A20.79,20.79,0,0,1,344.9,4.31H361a20.78,20.78,0,0,0-20.78,20.78V495A20.77,20.77,0,0,0,361,515.72Z" transform="translate(-67.17 -4.31)" fill="var(--color-grey-dark)"></path>
                <path d="M361,515.72H344.9A20.78,20.78,0,0,1,324.12,495V25.09A20.79,20.79,0,0,1,344.9,4.31H361a20.78,20.78,0,0,0-20.78,20.78V495A20.77,20.77,0,0,0,361,515.72Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-2)"></path>
                <path d="M209.71,166.09s-45.73,59.36-41.07,86,15.89,53.78,15.89,53.78l30.68-131Z" transform="translate(-67.17 -4.31)" fill="#68e1fd"></path>
                <path d="M209.71,166.09s-45.73,59.36-41.07,86,15.89,53.78,15.89,53.78l30.68-131Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-3)"></path>
                <rect
                    x="273.02"
                    width="285.63"
                    height="511.41"
                    rx="20.78"
                    fill="var(--color-grey-dark)"
                >
                </rect>
                <path
                    d="M485.94,61.94H362.88a7.28,7.28,0,0,1-7.28-7.29h0a7.28,7.28,0,0,1,7.28-7.28H485.94a7.29,7.29,0,0,1,7.29,7.28h0A7.29,7.29,0,0,1,485.94,61.94Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </path>
                <path
                    d="M485.94,136.23H362.88A7.28,7.28,0,0,1,355.6,129h0a7.28,7.28,0,0,1,7.28-7.29H485.94a7.29,7.29,0,0,1,7.29,7.29h0A7.29,7.29,0,0,1,485.94,136.23Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </path>
                <path
                    d="M485.94,210.52H362.88a7.28,7.28,0,0,1-7.28-7.28h0a7.28,7.28,0,0,1,7.28-7.29H485.94a7.29,7.29,0,0,1,7.29,7.29h0A7.29,7.29,0,0,1,485.94,210.52Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </path>
                <path
                    d="M485.94,284.82H362.88a7.29,7.29,0,0,1-7.28-7.29h0a7.28,7.28,0,0,1,7.28-7.29H485.94a7.29,7.29,0,0,1,7.29,7.29h0A7.3,7.3,0,0,1,485.94,284.82Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </path>
                <path
                    d="M485.94,359.11H362.88a7.29,7.29,0,0,1-7.28-7.29h0a7.28,7.28,0,0,1,7.28-7.29H485.94a7.29,7.29,0,0,1,7.29,7.29h0A7.3,7.3,0,0,1,485.94,359.11Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </path>
                <path
                    d="M485.94,433.4H362.88a7.28,7.28,0,0,1-7.28-7.29h0a7.29,7.29,0,0,1,7.28-7.29H485.94a7.3,7.3,0,0,1,7.29,7.29h0A7.29,7.29,0,0,1,485.94,433.4Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </path>
                <path d="M188.4,322.54c8.65-16.63,50.77,19.2,56.09,37s-29.28,80.32-47.24,99.62S146,495.73,124.73,501.72c0,0-7.5-10-6-14.11s45.58-36.24,49.57-55.65S179.75,339.18,188.4,322.54Z" transform="translate(-67.17 -4.31)" fill="#302f47"></path>
                <path d="M391.67,167.73s-5.32-15.45-4.43-17.15,6.06-5.62,8.5-3.33,7.39,16.49,7.39,16.49L455.08,146s5.8-.9,5.19,5.44S403.76,180,403.76,180,399.21,184.66,391.67,167.73Z" transform="translate(-67.17 -4.31)" fill="var(--color-green)"></path>
                <path d="M267.3,80.07s16.21,28.3,13,45.45-12.38,13.3-15.63,22.17a74.69,74.69,0,0,0-3.9,18s-9.41,7.64-26.26-13.15a45.87,45.87,0,0,0-2.62-25.15C226.52,114.58,229.42,68.34,267.3,80.07Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-5)"></path>
                <path
                    d="M217.34,65.47c-24.82,23.68-35,9.32-47.4,15.87s-17.17,8.89-12.67,33.56-17.68,19.34-23,34.27c-15.21,42.67,10,50.12,38.14,48.94s25.49-52.54,46.23-54.53,25,1.11,33.39-25.53c4.94-15.67,1.42-22.51,8.13-27.83,8.57-6.79,11.56-21.5,2.61-27.77C252.92,55.55,233.13,50.41,217.34,65.47Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#3f3d56"
                >
                </path>
                <path
                    d="M215.58,162.18s-2.07-9.46,4.73-12.71,26.29-4.82,29,0,5.68,15.74,8.58,16,14.81,19.2,14.81,19.2,2.49,52.62,2.63,73.05c.23,32.46-19.22,83.13-46.07,79s-46.44-11.59-46-25.51,23.12-118.79,21.5-130.6C203,167.87,215.12,161.74,215.58,162.18Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#68e1fd"
                >
                </path>
                <path
                    d="M215.58,162.18s-2.07-9.46,4.73-12.71,26.29-4.82,29,0,5.68,15.74,8.58,16,14.81,19.2,14.81,19.2,2.49,52.62,2.63,73.05c.23,32.46-19.22,83.13-46.07,79s-46.44-11.59-46-25.51,23.12-118.79,21.5-130.6C203,167.87,215.12,161.74,215.58,162.18Z"
                    transform="translate(-67.17 -4.31)"
                    fill="url(#columns-filters-empty-linear-gradient-6)"
                >
                </path>
                <path d="M239.69,178.59s10.34-20.4,25-8,32.65,58.25,45.17,58.51,64-41.22,68.49-37.47,12.29,13.11,8.09,21.1-61.76,58.39-86.55,53.67S222.26,227.83,239.69,178.59Z" transform="translate(-67.17 -4.31)" fill="#68e1fd"></path>
                <path d="M239.69,178.59s10.34-20.4,25-8,32.65,58.25,45.17,58.51,64-41.22,68.49-37.47,12.29,13.11,8.09,21.1-61.76,58.39-86.55,53.67S222.26,227.83,239.69,178.59Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-7)"></path>
                <path d="M380,193.13s10.46-19.48,11.32-23,3.47,1.77,3.47,1.77,9-7.1,10.5-10.2,14.53-7.9,20.41-7.13-4,4.38-4,4.38-4.66,2.13-7.1,13.83-4.91,21.71-15.71,26.62-11.49,6-11.49,6S384.85,195.1,380,193.13Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-8)"></path>
                <path d="M188.4,322.54s-12.88,29.48,11.07,57,41.81,49,36.76,91.59,7.06,64.54,7.06,64.54h14.12s21.91-78.9,18.13-112.31-20.27-100.72-20.27-100.72S223,307.24,188.4,322.54Z" transform="translate(-67.17 -4.31)" fill="#3f3d56"></path>
                <path d="M243.29,535.65v18h38.9s-23.29-4.21-23.95-9-.83-9-.83-9Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-9)"></path>
                <path
                    d="M246.17,548.12c-1.76-.36-3.95-.8-5.06.61a4,4,0,0,0-.68,1.77,21.47,21.47,0,0,0-.38,5.31c.07,1.33.38,2.83,1.53,3.49a4.4,4.4,0,0,0,2.31.37l40.34-.74c1.54,0,3.32-.17,4.19-1.44a3.34,3.34,0,0,0,.5-2.1,7,7,0,0,0-3-5,17.51,17.51,0,0,0-5.49-2.49c-5-1.52-13-4.36-17.94-2.29C257.1,547.84,252.12,549.37,246.17,548.12Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#3f3d56"
                >
                </path>
                <path d="M118.34,490.31l-16,8.17,19.48,33.67s-8-22.27-4.22-25.23,7.36-5.21,7.36-5.21Z" transform="translate(-67.17 -4.31)" fill="url(#columns-filters-empty-linear-gradient-10)"></path>
                <path
                    d="M108.5,498.23c-.56-1.71-1.28-3.82-3.06-4.08a3.86,3.86,0,0,0-1.86.29,22,22,0,0,0-4.8,2.34c-1.11.72-2.25,1.74-2.25,3.07a4.43,4.43,0,0,0,.83,2.18q10.42,17.28,20.84,34.56c.8,1.31,1.82,2.78,3.35,2.9a3.38,3.38,0,0,0,2.07-.62,7,7,0,0,0,2.87-5.12,17.62,17.62,0,0,0-.59-6c-1.17-5.07-2.75-13.46-7-16.68C114.22,507.54,110.4,504,108.5,498.23Z"
                    transform="translate(-67.17 -4.31)"
                    fill="#3f3d56"
                >
                </path>
                <rect
                    x="456.71"
                    y="24.09"
                    width="53.05"
                    height="46.4"
                    rx="5.69"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </rect>
                <rect
                    x="456.71"
                    y="104.73"
                    width="53.05"
                    height="46.4"
                    rx="5.69"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </rect>
                <rect
                    x="456.71"
                    y="177.9"
                    width="53.05"
                    height="46.4"
                    rx="5.69"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </rect>
                <rect
                    x="456.71"
                    y="322.67"
                    width="53.05"
                    height="46.4"
                    rx="5.69"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </rect>
                <rect
                    x="456.71"
                    y="250.01"
                    width="53.05"
                    height="46.4"
                    rx="5.69"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </rect>
                <rect
                    x="456.71"
                    y="398.6"
                    width="53.05"
                    height="46.4"
                    rx="5.69"
                    fill="#fff"
                    opacity="0.34"
                    style={{isolation: "isolate"}}
                >
                </rect>
                <path d="M527.6,44.83l9.54,16c3,5,8.16,6,11.9,2.42l32.29-31.08s3-4.29-.19-7.61-5.83.5-5.83.5l-28.6,27.48c-1.81,1.65-4.28,1.11-5.68-1.26l-7.6-12.87C532,36,529.35,35.78,527.78,38h0A6.66,6.66,0,0,0,527.6,44.83Z" transform="translate(-67.17 -4.31)" fill="var(--color-green)"></path>
                <path d="M527.6,274.85l9.54,16c3,4.95,8.16,6,11.9,2.42l32.29-31.09s3-4.29-.19-7.61-5.83.5-5.83.5l-28.6,27.49c-1.81,1.65-4.28,1.1-5.68-1.26l-7.6-12.87c-1.44-2.43-4.08-2.65-5.65-.47h0A6.66,6.66,0,0,0,527.6,274.85Z" transform="translate(-67.17 -4.31)" fill="var(--color-green)"></path>
                <path d="M527.6,348.05l9.54,16c3,4.95,8.16,6,11.9,2.42l32.29-31.08s3-4.3-.19-7.62-5.83.5-5.83.5l-28.6,27.49c-1.81,1.65-4.28,1.1-5.68-1.26l-7.6-12.87c-1.44-2.43-4.08-2.65-5.65-.47h0A6.66,6.66,0,0,0,527.6,348.05Z" transform="translate(-67.17 -4.31)" fill="var(--color-green)"></path>
            </g>
        </svg>
    );
};

export default SvgColumnsFiltersEmpty;
