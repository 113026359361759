import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, BoxRadius, BoxSpacing, Button, ButtonSize, ButtonStyle, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentMobileDirection, FlexContentProps, FlexContentSpacing, HeroHeader, MainContentStyle, SvgRocket} from "@sirdata/ui-lib";
import {MainContent} from "../common/component/widget";
import {pathLogin} from "../common/utils/constants";
import {PORTAL} from "../utils/Portal";
import {session} from "../api/ApiSession";
import {TranslationPortalFile} from "../utils/constants";
import {UIEventManager} from "../common/utils/UIEventManager";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {MainHeader} from "../common/component/snippet";

function Access() {
    const {t: textAccess} = useTranslation(TranslationPortalFile.ACCESS);
    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getAccount();
                if (account.hasAccess(PORTAL.origin.name)) {
                    navigate(PORTAL.defaultPath);
                }
            } catch {
                navigate(pathLogin);
            }
        })();
    }, [navigate]);

    return (
        <div className="wrapper">
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH}>
                <HeroHeader title={textAccess("title")}/>
                <FlexContent
                    alignment={FlexContentAlignment.START}
                    mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE}
                    cssClass="activation-section"
                    {...FlexContentProps.LAYOUT_COLUMNS}
                >
                    <SvgRocket/>
                    <Box radius={BoxRadius.MD} spacing={BoxSpacing.LARGE} cssClass="activation-card">
                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.LARGE}>
                            <span className="h1 activation-card__title" dangerouslySetInnerHTML={{__html: textAccess("text")}}/>
                            <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.END}>
                                <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={() => UIEventManager.emit(ModalContactUiEvent, {})}>
                                    {textAccess("request_access")}
                                </Button>
                            </FlexContent>
                        </FlexContent>
                    </Box>
                </FlexContent>
            </MainContent>
        </div>
    );
}

export default Access;
