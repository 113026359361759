import React, {FunctionComponent, useEffect, useState} from "react";
import {session} from "../../../api/ApiSession";
import {useTranslation} from "react-i18next";
import {Order} from "../../../api/model/order/Order";
import {ProcessOrderStep} from "../../../utils/ProcessOrderStep";
import {ModalProcessOrderColumnLimits, ModalProcessOrderConfirmation, ModalProcessOrderValidation} from "../index";
import {TranslationPortalFile} from "../../../utils/constants";
import {ModalHeader, ModalHeaderDirection, ModalHeaderTitle, ModalNew, ModalStepper} from "@sirdata/ui-lib";

type ModalProcessOrderProps = {
    initOrder?: Order;
    onClose: (refresh: boolean) => void;
}

const ModalProcessOrder: FunctionComponent<ModalProcessOrderProps> = ({initOrder, onClose}) => {
    const {t: textProcessOrder} = useTranslation(TranslationPortalFile.PROCESS_ORDER);

    const [order, setOrder] = useState<Order>();

    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<ProcessOrderStep>(ProcessOrderStep.COLUMN_LIMITS);

    useEffect(() => {
        setCurrentStep(ProcessOrderStep.values()[currentStepIndex]);
    }, [currentStepIndex]);

    useEffect(() => {
        if (!!initOrder) {
            setOrder(initOrder);
        }
    }, [initOrder]);

    const handleGoToNextStep = () => {
        setCurrentStepIndex((prev) => prev + 1);
    };

    const handleSubmitStep = (newOrder: Order) => {
        setOrder(newOrder);
        handleGoToNextStep();
    };

    const handleGoBack = () => {
        (currentStepIndex > 0) && setCurrentStepIndex((prev) => prev - 1);
    };

    const handleClose = () => {
        setOrder(new Order());
        onClose(currentStep.name === ProcessOrderStep.CONFIRMATION.name);
        setCurrentStepIndex(0);
    };

    const handleProcessOrder = async (order: Order) => {
        try {
            await session.restOrder.createRequestOrder(order);
            handleGoToNextStep();
        } catch (e) {
            console.error(e);
        }
    };

    if (!order) return <></>;

    return (
        <ModalNew onClose={handleClose} active={!!initOrder} size={currentStep.modalSize}>
            {currentStep.name !== ProcessOrderStep.CONFIRMATION.name &&
                <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                    <ModalStepper steps={ProcessOrderStep.values().length - 1} activeStep={currentStepIndex}/>
                    <ModalHeaderTitle title={textProcessOrder(`${currentStep.label}.title`)}/>
                </ModalHeader>
            }
            {currentStep.name === ProcessOrderStep.COLUMN_LIMITS.name &&
                <ModalProcessOrderColumnLimits
                    initOrder={order}
                    onSubmit={handleSubmitStep}
                />}

            {currentStep.name === ProcessOrderStep.VALIDATION.name &&
                <ModalProcessOrderValidation
                    initOrder={order}
                    onSubmitOrder={handleProcessOrder}
                    onGoBack={handleGoBack}
                />}

            {currentStep.name === ProcessOrderStep.CONFIRMATION.name &&
                <ModalProcessOrderConfirmation onClose={handleClose}/>}
        </ModalNew>
    );
};

export default ModalProcessOrder;
