import React, {FunctionComponent} from "react";

const SvgOrdersEmpty: FunctionComponent = () => {

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 485.52 457.58"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="orders-empty-linear-gradient"
                    x1="377.5"
                    y1="141.2"
                    x2="338.19"
                    y2="315.45"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#010101" stopOpacity="0"></stop>
                    <stop offset="0.95" stopColor="#010101"></stop>
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-2"
                    x1="393.73"
                    y1="242.38"
                    x2="296.34"
                    y2="316.3"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-3"
                    x1="248.21"
                    y1="219.24"
                    x2="691.74"
                    y2="317.8"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-4"
                    x1="262.14"
                    y1="319.68"
                    x2="241.02"
                    y2="1076.5"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-5"
                    x1="121.97"
                    y1="371.64"
                    x2="201.18"
                    y2="393.64"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-6"
                    x1="125.35"
                    y1="369.72"
                    x2="-22.49"
                    y2="440.71"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-7"
                    x1="154.85"
                    y1="309.72"
                    x2="165.85"
                    y2="347.98"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-8"
                    x1="151.59"
                    y1="260.09"
                    x2="173.15"
                    y2="270.44"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-9"
                    x1="-970.5"
                    y1="247.29"
                    x2="-744.05"
                    y2="341.75"
                    gradientTransform="matrix(-1, 0, 0, 1, -695.74, 0)"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-10"
                    x1="-941.71"
                    y1="264.53"
                    x2="-930.45"
                    y2="264.53"
                    gradientTransform="matrix(-1, 0, 0, 1, -695.74, 0)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e4c5d5"></stop>
                    <stop offset="0.42" stopColor="#e9d3cf"></stop>
                    <stop offset="1" stopColor="#efe8ca"></stop>
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-11"
                    x1="168.01"
                    y1="350.92"
                    x2="209.24"
                    y2="350.92"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#orders-empty-linear-gradient-10"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-12"
                    x1="-896.91"
                    y1="131.7"
                    x2="-874.36"
                    y2="131.7"
                    gradientTransform="matrix(-1, -0.09, -0.09, 1, -671.06, 13.85)"
                    xlinkHref="#orders-empty-linear-gradient-10"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-13"
                    x1="-881.13"
                    y1="304.25"
                    x2="-921.95"
                    y2="258.36"
                    gradientTransform="matrix(-1, 0, 0, 1, -695.74, 0)"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-14"
                    x1="135.26"
                    y1="322.73"
                    x2="148.5"
                    y2="322.73"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-15"
                    x1="223.47"
                    y1="371.64"
                    x2="302.67"
                    y2="393.64"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-16"
                    x1="226.85"
                    y1="369.72"
                    x2="79"
                    y2="440.71"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-17"
                    x1="247.23"
                    y1="371.64"
                    x2="326.43"
                    y2="393.64"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="orders-empty-linear-gradient-18"
                    x1="250.61"
                    y1="369.72"
                    x2="102.76"
                    y2="440.71"
                    xlinkHref="#orders-empty-linear-gradient"
                >
                </linearGradient>
            </defs>
            <path
                d="M250.62,462.58c60.71,3.87,123.15,4.63,180.61-15.36,15.73-5.47,31.14-12.59,43.85-23.34,29.72-25.14,40.51-70.25,25.39-106.11-8.09-19.16-22.83-36.28-24.07-57-1.08-18.06,8.35-34.8,15.18-51.56,18.49-45.34,17.41-101-12.51-139.71C447.06,28,390.89,14.3,338.74,9.73a737.79,737.79,0,0,0-118.83-.31c-15.45,1.15-31.18,2.79-45.33,9.09-21.48,9.57-37.07,28.91-48.79,49.29a240,240,0,0,0-24.27,59.43c-4.12,15.89-6.65,32.31-13.07,47.42C78.13,199,58.57,218,44.66,240.49,17.05,285.06,8.93,368,51.41,407.41,100.37,452.83,187.92,458.57,250.62,462.58Z"
                transform="translate(-21.75 -7.18)"
                fill="#68e1fd"
                opacity="0.24"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M399.28,130.48c4.61-8.24,7.81-18.48,3.66-27a4.42,4.42,0,0,0-2.27-2.45,4.29,4.29,0,0,0-1.82-.12c-10.06,1-16.77,10.85-20.33,20.31s-5.72,20-12.84,27.18c-3.11,3.13-7,5.43-10,8.71-6.47,7.17-7.38,17.65-7.68,27.31-.32,10.25.13,28.87,14.64,26.8,7.65-1.1,17.66-10.7,20.8-17.61,3.64-8,2.12-18.07,2.36-26.59C386.26,152.23,392.25,143,399.28,130.48Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-lime-dark)"
            >
            </path>
            <path
                d="M399.28,130.48c4.61-8.24,7.81-18.48,3.66-27a4.42,4.42,0,0,0-2.27-2.45,4.29,4.29,0,0,0-1.82-.12c-10.06,1-16.77,10.85-20.33,20.31s-5.72,20-12.84,27.18c-3.11,3.13-7,5.43-10,8.71-6.47,7.17-7.38,17.65-7.68,27.31-.32,10.25.13,28.87,14.64,26.8,7.65-1.1,17.66-10.7,20.8-17.61,3.64-8,2.12-18.07,2.36-26.59C386.26,152.23,392.25,143,399.28,130.48Z"
                transform="translate(-21.75 -7.18)"
                fill="url(#orders-empty-linear-gradient)"
            >
            </path>
            <path
                d="M408.86,169.09c6.23-.6,14,.91,15.85,6.9,1.09,3.57-.48,7.38-2.29,10.65-7.24,13.12-18.56,23.64-25.74,36.8a21.78,21.78,0,0,0-3,8.53c-.45,5.58,2.7,10.72,5.21,15.73,6.22,12.41,8.46,28.78-.73,39.18-4.49,5.07-11.12,8-15.06,13.53-4.42,6.18-4.61,14.33-6.82,21.61s-8.6,14.77-16,13.11a458.74,458.74,0,0,0-1-58.15c-2-24.15-7.15-49.66,2.82-72.54C370.27,185.57,388,171.11,408.86,169.09Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-lime-dark)"
            >
            </path>
            <path
                d="M408.86,169.09c6.23-.6,14,.91,15.85,6.9,1.09,3.57-.48,7.38-2.29,10.65-7.24,13.12-18.56,23.64-25.74,36.8a21.78,21.78,0,0,0-3,8.53c-.45,5.58,2.7,10.72,5.21,15.73,6.22,12.41,8.46,28.78-.73,39.18-4.49,5.07-11.12,8-15.06,13.53-4.42,6.18-4.61,14.33-6.82,21.61s-8.6,14.77-16,13.11a458.74,458.74,0,0,0-1-58.15c-2-24.15-7.15-49.66,2.82-72.54C370.27,185.57,388,171.11,408.86,169.09Z"
                transform="translate(-21.75 -7.18)"
                fill="url(#orders-empty-linear-gradient-2)"
            >
            </path>
            <rect
                x="173.99"
                y="63.76"
                width="167.57"
                height="315.2"
                rx="28.72"
                fill="var(--color-midnight-light)"
            >
            </rect>
            <rect
                x="173.99"
                y="63.76"
                width="167.57"
                height="315.2"
                rx="28.72"
                fill="url(#orders-empty-linear-gradient-3)"
            >
            </rect>
            <path
                d="M146.11,397.89c-6.47.54-14.71,2.77-15.24,9.24s7.16,10,13.46,11.17c15,2.87,30.35,3,45.61,3.11q78.3.45,156.6-.9c13.93-.24,29.37-1.14,39.15-11.05,1.63-1.65,3.12-3.76,2.91-6.07-.41-4.37-6-5.77-10.42-6.05C300,392.41,224.2,391.33,146.11,397.89Z"
                transform="translate(-21.75 -7.18)"
                fill="url(#orders-empty-linear-gradient-4)"
            >
            </path>
            <polygon points="149.36 401.55 101.83 401.55 107.41 342.59 142.02 341.71 149.36 401.55" fill="var(--color-sand-dark)"></polygon>
            <polygon points="149.36 401.55 101.83 401.55 107.41 342.59 142.02 341.71 149.36 401.55" fill="url(#orders-empty-linear-gradient-5)"></polygon>
            <polygon points="141.44 401.55 93.92 401.55 99.49 342.59 134.1 341.71 141.44 401.55" fill="var(--color-sand-dark)"></polygon>
            <polygon points="141.44 401.55 93.92 401.55 99.49 342.59 134.1 341.71 141.44 401.55" fill="url(#orders-empty-linear-gradient-6)"></polygon>
            <path
                d="M151.81,350.19l-.56-.06c.1-2.59,0-9.84-3.4-14a8.19,8.19,0,0,0-6.13-3.13,7.87,7.87,0,0,0-6.16,2.31c-4.19,4.1-5.09,12.35-5.28,15l-.69,0c0-.47.41-11.1,5.63-15.88a8.43,8.43,0,0,1,6.56-2.31,8.78,8.78,0,0,1,6.47,3.12C152.63,340.29,151.88,349.79,151.81,350.19Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-sand-dark)"
            >
            </path>
            <path
                d="M157,350.19l-.56-.06c.09-2.59,0-9.84-3.41-14a8.15,8.15,0,0,0-6.12-3.13,7.86,7.86,0,0,0-6.16,2.31c-4.19,4.1-5.1,12.35-5.28,15l-.69,0c0-.47.41-11.1,5.63-15.88A8.43,8.43,0,0,1,147,332.1a8.8,8.8,0,0,1,6.47,3.12C157.83,340.29,157.08,349.79,157,350.19Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-sand-dark)"
            >
            </path>
            <rect
                x="166.07"
                y="63.76"
                width="167.57"
                height="315.2"
                rx="28.72"
                fill="var(--color-midnight-light)"
            >
            </rect>
            <rect
                x="177.81"
                y="83.75"
                width="146.96"
                height="276.44"
                rx="25.19"
                fill="#fff"
            >
            </rect>
            <rect
                x="204.5"
                y="244.61"
                width="96.22"
                height="30.51"
                rx="15.25"
                fill="var(--color-green)"
            >
            </rect>
            <path
                d="M248,269.79a3.16,3.16,0,0,1,0,.35,1.56,1.56,0,0,1,0,.25.75.75,0,0,1-.07.18,1.29,1.29,0,0,1-.13.17,2,2,0,0,1-.35.24,4.32,4.32,0,0,1-.64.29,6.07,6.07,0,0,1-3.89,0,3.93,3.93,0,0,1-1.5-1,4.44,4.44,0,0,1-1-1.68,8.42,8.42,0,0,1,0-4.77,4.88,4.88,0,0,1,1-1.77,4.22,4.22,0,0,1,1.56-1.1,5.46,5.46,0,0,1,2-.37,4.91,4.91,0,0,1,.86.08,5.27,5.27,0,0,1,.76.19,3.63,3.63,0,0,1,.63.28,1.41,1.41,0,0,1,.39.27.79.79,0,0,1,.16.18.85.85,0,0,1,.06.19,1.88,1.88,0,0,1,0,.28c0,.1,0,.23,0,.38s0,.31,0,.43a1.06,1.06,0,0,1-.06.28.36.36,0,0,1-.1.15.17.17,0,0,1-.13.05.61.61,0,0,1-.32-.14,3.21,3.21,0,0,0-.49-.32,4.05,4.05,0,0,0-.72-.33,3.24,3.24,0,0,0-1-.14,2.46,2.46,0,0,0-1.14.26,2.4,2.4,0,0,0-.85.74,3.49,3.49,0,0,0-.53,1.17,5.72,5.72,0,0,0-.19,1.55,6,6,0,0,0,.2,1.63,3.19,3.19,0,0,0,.55,1.14,2.24,2.24,0,0,0,.87.66,2.79,2.79,0,0,0,1.14.22,3.28,3.28,0,0,0,1-.13,4.71,4.71,0,0,0,.73-.31l.49-.29a.62.62,0,0,1,.31-.14.2.2,0,0,1,.13,0,.22.22,0,0,1,.08.13,1.43,1.43,0,0,1,0,.28C248,269.43,248,269.59,248,269.79Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M259.08,265.94a7.91,7.91,0,0,1-.33,2.38,4.73,4.73,0,0,1-1,1.78,4.17,4.17,0,0,1-1.62,1.12,6.07,6.07,0,0,1-2.22.38,6.53,6.53,0,0,1-2.18-.33,3.63,3.63,0,0,1-1.55-1,4.43,4.43,0,0,1-.93-1.7,8.49,8.49,0,0,1-.31-2.45,7.74,7.74,0,0,1,.33-2.34,4.87,4.87,0,0,1,1-1.76,4.44,4.44,0,0,1,1.62-1.12,6.15,6.15,0,0,1,2.24-.38,6.38,6.38,0,0,1,2.14.32,3.63,3.63,0,0,1,1.55,1,4.41,4.41,0,0,1,.95,1.69A8.2,8.2,0,0,1,259.08,265.94Zm-2.28.12a8,8,0,0,0-.13-1.54,3.38,3.38,0,0,0-.45-1.18,2.09,2.09,0,0,0-.85-.76,2.87,2.87,0,0,0-1.32-.27,2.74,2.74,0,0,0-1.34.3,2.42,2.42,0,0,0-.87.81,3.27,3.27,0,0,0-.46,1.18,7.2,7.2,0,0,0-.14,1.42,9.34,9.34,0,0,0,.13,1.58,3.43,3.43,0,0,0,.45,1.19,2.12,2.12,0,0,0,.84.76,3.09,3.09,0,0,0,1.33.26,2.71,2.71,0,0,0,1.34-.3,2.42,2.42,0,0,0,.87-.81,3.41,3.41,0,0,0,.47-1.19A7.52,7.52,0,0,0,256.8,266.06Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M269.73,270.65a.83.83,0,0,1-.24.59.87.87,0,0,1-.27.14,1.06,1.06,0,0,1-.31.05H268a1.8,1.8,0,0,1-.5-.06,1.06,1.06,0,0,1-.39-.21,1.83,1.83,0,0,1-.35-.41c-.11-.18-.23-.4-.37-.68l-2.66-5c-.15-.3-.31-.62-.47-1s-.3-.68-.43-1h0c0,.4,0,.8.05,1.19s0,.8,0,1.22v5.6a.24.24,0,0,1-.05.14.31.31,0,0,1-.15.12,1.67,1.67,0,0,1-.3.07l-.49,0-.48,0a1.33,1.33,0,0,1-.3-.07.31.31,0,0,1-.15-.12.23.23,0,0,1,0-.14v-9.62a.72.72,0,0,1,.23-.58.83.83,0,0,1,.56-.19h1.16a2,2,0,0,1,.53,0,1.21,1.21,0,0,1,.38.18,1.17,1.17,0,0,1,.32.34,6.07,6.07,0,0,1,.31.54l2.08,3.91.36.7c.12.23.23.46.34.69l.33.67.3.66h0c0-.39,0-.79,0-1.21s0-.81,0-1.19v-5a.25.25,0,0,1,.05-.15.5.5,0,0,1,.17-.12,1.33,1.33,0,0,1,.3-.07l.49,0,.47,0a1.23,1.23,0,0,1,.29.07.49.49,0,0,1,.15.12.24.24,0,0,1,0,.15Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M278.06,261.61c0,.16,0,.3,0,.42a.74.74,0,0,1-.07.26.36.36,0,0,1-.1.15.17.17,0,0,1-.13.05h-3.5v2.88h3.28a.23.23,0,0,1,.14,0,.56.56,0,0,1,.1.14,1.31,1.31,0,0,1,.07.27c0,.11,0,.24,0,.41s0,.3,0,.41a1.31,1.31,0,0,1-.07.27.44.44,0,0,1-.1.16.23.23,0,0,1-.14,0h-3.28v4a.2.2,0,0,1-.05.15.32.32,0,0,1-.18.12,1.68,1.68,0,0,1-.33.07l-.53,0-.52,0a1.81,1.81,0,0,1-.34-.07.41.41,0,0,1-.18-.12.25.25,0,0,1-.05-.15v-9.7a.62.62,0,0,1,.19-.52.64.64,0,0,1,.45-.16h5a.2.2,0,0,1,.13,0,.36.36,0,0,1,.1.15.88.88,0,0,1,.07.28C278.05,261.3,278.06,261.44,278.06,261.61Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M282,271.11a.25.25,0,0,1-.05.15.4.4,0,0,1-.18.11,1.68,1.68,0,0,1-.33.07l-.53,0-.52,0a1.65,1.65,0,0,1-.34-.07.4.4,0,0,1-.18-.11.25.25,0,0,1-.05-.15V261a.24.24,0,0,1,.05-.14.4.4,0,0,1,.18-.11,2,2,0,0,1,.34-.07,4,4,0,0,1,.52,0,4,4,0,0,1,.53,0,1.68,1.68,0,0,1,.33.07.4.4,0,0,1,.18.11.24.24,0,0,1,.05.14Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M292.28,271.13a.24.24,0,0,1,0,.15.27.27,0,0,1-.15.11,2.37,2.37,0,0,1-.37.05,5.84,5.84,0,0,1-.65,0,4.2,4.2,0,0,1-.55,0,1.22,1.22,0,0,1-.33-.06.38.38,0,0,1-.17-.11.89.89,0,0,1-.08-.17l-1-2.39c-.12-.27-.23-.51-.34-.72a2.37,2.37,0,0,0-.37-.53,1.25,1.25,0,0,0-.47-.31,1.45,1.45,0,0,0-.61-.11h-.67v4.07a.2.2,0,0,1-.06.15.31.31,0,0,1-.18.11,1.68,1.68,0,0,1-.33.07l-.53,0-.52,0a1.68,1.68,0,0,1-.33-.07.31.31,0,0,1-.18-.11.25.25,0,0,1-.05-.15V261.4a.62.62,0,0,1,.19-.52.65.65,0,0,1,.46-.16h3.46l.5,0a4.48,4.48,0,0,1,1.16.32,2.82,2.82,0,0,1,.87.58,2.31,2.31,0,0,1,.55.85,3.08,3.08,0,0,1,.19,1.13,3.37,3.37,0,0,1-.13,1,2.67,2.67,0,0,1-.4.79,2.72,2.72,0,0,1-.66.6,3.77,3.77,0,0,1-.88.42,2.11,2.11,0,0,1,.45.28,2.52,2.52,0,0,1,.4.4,3.75,3.75,0,0,1,.35.53c.11.21.21.43.32.69l.9,2.1a4.4,4.4,0,0,1,.16.46A.87.87,0,0,1,292.28,271.13Zm-2.81-7.26a1.58,1.58,0,0,0-.24-.89,1.31,1.31,0,0,0-.78-.51l-.38-.07-.58,0h-1v3h1.1a2.78,2.78,0,0,0,.81-.11A1.77,1.77,0,0,0,289,265a1.3,1.3,0,0,0,.35-.49A1.61,1.61,0,0,0,289.47,263.87Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M306.33,271.11a.24.24,0,0,1,0,.15.32.32,0,0,1-.17.11,1.61,1.61,0,0,1-.31.07l-.51,0-.49,0a1.56,1.56,0,0,1-.32-.07.29.29,0,0,1-.16-.11.2.2,0,0,1-.05-.15v-8.7h0l-3.1,8.7a.34.34,0,0,1-.11.17.39.39,0,0,1-.2.11,1.22,1.22,0,0,1-.33.06h-.47l-.48,0a1.17,1.17,0,0,1-.33-.07.48.48,0,0,1-.2-.11.25.25,0,0,1-.1-.15l-3-8.7h0v8.7a.25.25,0,0,1-.05.15.28.28,0,0,1-.17.11,1.61,1.61,0,0,1-.31.07l-.5,0-.5,0a1.73,1.73,0,0,1-.32-.07.29.29,0,0,1-.16-.11.25.25,0,0,1-.05-.15v-9.53a.9.9,0,0,1,.22-.64.81.81,0,0,1,.6-.22h1.42a3.06,3.06,0,0,1,.65.06,1.39,1.39,0,0,1,.48.2,1.27,1.27,0,0,1,.33.39,3.33,3.33,0,0,1,.23.58l2.31,6.37h0l2.4-6.36a2.9,2.9,0,0,1,.24-.58,1.32,1.32,0,0,1,.29-.39,1,1,0,0,1,.41-.21,2.09,2.09,0,0,1,.53-.06h1.47a1.46,1.46,0,0,1,.38,0,.62.62,0,0,1,.26.17.71.71,0,0,1,.16.28,1,1,0,0,1,.05.36Z"
                transform="translate(-21.75 -7.18)"
                fill="#fff"
            >
            </path>
            <path
                d="M153.35,302.4a15.34,15.34,0,0,0-6.71.8c-3.57,1.54-5.57,5.33-6.87,9-2.1,5.93-3.05,12.2-4,18.42-.42,2.75-.83,5.58-.16,8.27a4.81,4.81,0,0,0,1.33,2.49,6,6,0,0,0,2.43,1.09,94.77,94.77,0,0,0,40,2.19,8.14,8.14,0,0,0,4.61-1.81c1.3-1.29,1.61-3.25,1.79-5.07a66,66,0,0,0-2.22-24.3c-.68-2.38-1.59-4.87-3.54-6.4-3.13-2.43-8.19-2-11.88-2.92A75.37,75.37,0,0,0,153.35,302.4Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-red)"
            >
            </path>
            <path
                d="M185.77,337.79c-.19,1.81-.5,3.75-1.78,5.06s-3,1.53-4.63,1.81a94.86,94.86,0,0,1-40-2.19,6.49,6.49,0,0,1-2.4-1.09,4,4,0,0,1-1-1.59,8.88,8.88,0,0,1-.32-.91c-.65-2.69-.25-5.53.16-8.28,1-6.22,1.91-12.47,4-18.41,1.19-3.32,2.94-6.72,5.91-8.44a5.07,5.07,0,0,1,1-.53,15.21,15.21,0,0,1,6.72-.82,76.23,76.23,0,0,1,14.78,1.75c3.69.91,8.75.47,11.88,2.91,1.93,1.53,2.84,4,3.53,6.41A65.9,65.9,0,0,1,185.77,337.79Z"
                transform="translate(-21.75 -7.18)"
                opacity="0.26"
                fill="url(#orders-empty-linear-gradient-7)"
            >
            </path>
            <path d="M159.39,258.61c-3.92,6.76-7.31,14.3-6.64,22.08a23,23,0,0,0,14.4,19c3.74-3,4.15-8.45,4.26-13.24.24-11.11,1.72-23.11.57-34.16-.29-2.82,3-16.2-1.06-14.68C168.47,238.56,160.76,256.25,159.39,258.61Z" transform="translate(-21.75 -7.18)" fill="#68e1fd"></path>
            <path
                d="M159.39,258.61c-3.92,6.76-7.31,14.3-6.64,22.08a23,23,0,0,0,14.4,19c3.74-3,4.15-8.45,4.26-13.24.24-11.11,1.72-23.11.57-34.16-.29-2.82,3-16.2-1.06-14.68C168.47,238.56,160.76,256.25,159.39,258.61Z"
                transform="translate(-21.75 -7.18)"
                opacity="0.26"
                fill="url(#orders-empty-linear-gradient-8)"
            >
            </path>
            <path
                d="M216.47,310a21.12,21.12,0,0,1,1.84-8c3.78-7.18,13.66-8,20.29-12.65,5.33-3.77,8.4-10.1,9.59-16.52s.71-13,.22-19.53c-.13-1.85-.33-3.84-1.57-5.22-2.71-3-7.62-.86-11,1.42L227,255.62c-1.88,1.29-4.45,2.57-6.24,1.16-1.18-.92-1.38-2.6-1.49-4.1l-1.68-23.44c-.47-6.47-1.58-14-7.26-17.13s-13,.22-16.59,5.54-4.24,12-4.7,18.39a586.32,586.32,0,0,0,1.5,100.73c.13,1.32.37,2.81,1.46,3.56a4.69,4.69,0,0,0,2.8.5c6.23-.2,17.41,2.14,20.44-4.37S215.83,317.25,216.47,310Z"
                transform="translate(-21.75 -7.18)"
                opacity="0.26"
                fill="url(#orders-empty-linear-gradient-9)"
            >
            </path>
            <path
                d="M245.73,265.13a25,25,0,0,0-.18-7.95,2.93,2.93,0,0,0-.38-1.17c-.75-1.09-2.54-.66-3.42.33s-1.24,2.35-2.08,3.37c-.69.84-1.65,1.41-2.42,2.18a10.23,10.23,0,0,0-2.37,4.94,4,4,0,0,0-.15,1.41,4.38,4.38,0,0,0,.84,1.85c1.36,2.07,3.67,4.92,6.23,3C244.09,271.28,245.3,267.86,245.73,265.13Z"
                transform="translate(-21.75 -7.18)"
                fill="url(#orders-empty-linear-gradient-10)"
            >
            </path>
            <path d="M209.24,302l-6,100-10.9,1.6-5-75.91-4.52,75.73-10.79.35s-5-76.49-3.91-82.09,0-23.47,0-23.47Z" transform="translate(-21.75 -7.18)" fill="url(#orders-empty-linear-gradient-11)"></path>
            <path
                d="M211,292.24c5.28-3.19,10.39-16.77,8.12-20.06-2.74-4-6.82-31.39-10.43-34.54s-8.17-5.42-12.91-5.24c-5.61.21-11.8-2.41-17-.29-3,1.2-5.4,3.38-7.79,5.53-3.75,3.35-6.67,59.92-2.47,66.07,5.38,7.86,16.45,8.12,25.82,7.58,3.23-.19,6.6-.41,9.43-2C210.82,305.13,211.71,300.58,211,292.24Z"
                transform="translate(-21.75 -7.18)"
                fill="#68e1fd"
            >
            </path>
            <path
                d="M210,213.35s0,.09,0,.13a12.25,12.25,0,0,0,1.25,3.76c.25.51.53,1.12.25,1.62s-1,.61-1.47,1c-1.57,1.53.11,4.94-1.41,6.82-1,1.17-2.58,1.49-4,2-1.07.4-2.44,1.22-2.7,2.44s.27,2.78.19,4.09c-.11,1.66-1.08,3.53-2.74,3.68a3.5,3.5,0,0,1-1.73-.38A10.75,10.75,0,0,1,193.4,234c-3.14-5.42-5.1-11.77-4.13-18s5.34-12.06,11.42-13.57c3.38-.84,6.31.23,7.87,3.42a17.82,17.82,0,0,1,1.4,4.86A26.29,26.29,0,0,1,210,213.35Z"
                transform="translate(-21.75 -7.18)"
                fill="url(#orders-empty-linear-gradient-12)"
            >
            </path>
            <path
                d="M209.67,202.29c-.85,3.06-3.42,5.34-6.14,7-1.85,1.11-3.47,1.91-4.53,3.84a18.94,18.94,0,0,0-2.11,6.5c0,.33-.14.72-.45.82s-.7-.31-.83-.68c-.26-.78-.36-1.72-1.05-2.16a1.55,1.55,0,0,0-1.7.12,2.43,2.43,0,0,0-.89,1.53,3.57,3.57,0,0,0,1.81,3.74,4.4,4.4,0,0,1,1,.58c1.32,1.23.05,2.62-.47,3.77a10.25,10.25,0,0,0-.61,5.09c.23,2.41.85,4.82.5,7.22a7.55,7.55,0,0,1-2.81,4.94,5.69,5.69,0,0,1-5.52.76c-1.31-.57-2.35-1.69-3.72-2.09-3.16-.94-6.39,2.32-9.61,1.57-1.82-.43-3.1-2-4.07-3.64a9.73,9.73,0,0,1-1.62-4.37c-.15-2.6,1.41-5,2.89-7.1,2.1-3.07,4.21-6.28,4.91-9.94.54-2.88.18-5.89.88-8.73.77-3.13,3.07-5.38,4.18-8.27,1-2.69,2.31-5.32,5.11-6.59,2.61-1.2,5.66-.69,8.52-1,4-.44,7.15-3.24,11.26-1.68C208,194.78,210.69,198.63,209.67,202.29Z"
                transform="translate(-21.75 -7.18)"
                fill="#606161"
            >
            </path>
            <path
                d="M232.82,267.84a3.77,3.77,0,0,1,2.51-.35,3.47,3.47,0,0,1,1.23.89,18.46,18.46,0,0,1,4,6.23,5.05,5.05,0,0,1,.33,3.49,5.9,5.9,0,0,1-2.89,2.72c-8.35,4.8-18.35,11.65-27.94,10.72-.67-.07-.44-2.07-.94-2.52a3.39,3.39,0,0,1-.83-2.29,41.6,41.6,0,0,1-.27-9.19,13.92,13.92,0,0,1,3.65-8.27,5.05,5.05,0,0,1,3.17-1.67c1.86-.11,1.72,1.31,2.83,2,1.37.81,3.4.81,5,.77A31.4,31.4,0,0,0,232.82,267.84Z"
                transform="translate(-21.75 -7.18)"
                fill="#68e1fd"
            >
            </path>
            <path d="M199.49,261.18s3.12,24.73,11.46,31.06c0,0,2.87,15.32-10.33,18,0,0-3.74,1.54-10,1,0,0,12.84,3.89,10.42-21.85S199.49,261.18,199.49,261.18Z" transform="translate(-21.75 -7.18)" opacity="0.26" fill="url(#orders-empty-linear-gradient-13)"></path>
            <path
                d="M211.09,408.84a1.78,1.78,0,0,1,.62,1.27,1.51,1.51,0,0,1-.5.9,6,6,0,0,1-3.4,1.34,29.73,29.73,0,0,1-12-.67,5,5,0,0,1-2.61-1.34,5.49,5.49,0,0,1-.94-3.57l-.12-4.35a2.41,2.41,0,0,1,.11-1c.33-.79,1.35-1,2.21-1.05a55.6,55.6,0,0,1,6.19-.11c.73,0,1.67-.11,2.25.35s.78,1.37,1.17,1.85C206,405,208.94,406.5,211.09,408.84Z"
                transform="translate(-21.75 -7.18)"
                fill="#606161"
            >
            </path>
            <path
                d="M190.43,408.86a1.75,1.75,0,0,1,.6,1.22,1.4,1.4,0,0,1-.49.87,5.78,5.78,0,0,1-3.28,1.3,28.77,28.77,0,0,1-11.61-.64,4.89,4.89,0,0,1-2.53-1.3,5.4,5.4,0,0,1-.91-3.45l-.11-4.2a2.26,2.26,0,0,1,.11-1c.32-.76,1.3-.95,2.13-1a56.83,56.83,0,0,1,6-.11c.71,0,1.62-.1,2.18.35s.75,1.32,1.13,1.79C185.55,405.11,188.35,406.6,190.43,408.86Z"
                transform="translate(-21.75 -7.18)"
                fill="#606161"
            >
            </path>
            <path
                d="M148.42,326.78c-.06,3.69-.13,7.44-1.25,10.94a5.73,5.73,0,0,1-2.6,3.6c-2.06,1-6.4,0-8.65-1.53a8.88,8.88,0,0,1-.32-.91c-.65-2.69-.25-5.53.16-8.28,1-6.22,1.91-12.47,4-18.41,1.19-3.32,2.94-6.72,5.91-8.44a2.44,2.44,0,0,1,.69.28c1.75,1.06,2,3.47,2.06,5.5C148.57,315.28,148.48,321,148.42,326.78Z"
                transform="translate(-21.75 -7.18)"
                opacity="0.26"
                fill="url(#orders-empty-linear-gradient-14)"
            >
            </path>
            <path d="M156,298.37a15.82,15.82,0,0,1,1.74-3.61,5.33,5.33,0,0,1,3.2-2.25,3.89,3.89,0,0,1-.74,1.68,27.78,27.78,0,0,0-3.4,7.94c-.22.86-2,4-2.73,1.79C153.71,302.65,155.59,299.56,156,298.37Z" transform="translate(-21.75 -7.18)" fill="var(--color-red)"></path>
            <path
                d="M199.16,301.15a29.94,29.94,0,0,1,9.1,1.35,4.2,4.2,0,0,1,2.09,1.36,4.69,4.69,0,0,1,.67,2.05c2,12.74-.34,25.73-.09,38.62.09,4.94.57,9.85.95,14.77a2.1,2.1,0,0,1-.35,1.69,2,2,0,0,1-1.23.45,51.77,51.77,0,0,1-9.15.09c-12-.65-27.89,1.29-39.73-.47-1.77-15,2.54-33.37,4.88-48.29.47-3,.12-7.1,1.34-9.87s4.22-2.3,6.94-2.29Q186.87,300.65,199.16,301.15Z"
                transform="translate(-21.75 -7.18)"
                fill="#606161"
            >
            </path>
            <circle cx="251.14" cy="167.46" r="49.57" fill="var(--color-midnight-light)"></circle>
            <polygon points="265.54 180.85 232.04 180.85 235.28 151.67 261.22 151.67 265.54 180.85" fill="#fff"></polygon>
            <path d="M275.52,160.56c0-.08-1.17-8-5.91-7.4-4.95.63-5.12,6.6-5.12,6.85l-3.13,0c0-3.09,1.73-9.13,7.85-9.91,6.3-.77,8.9,6.25,9.4,10.08Z" transform="translate(-21.75 -7.18)" fill="#fff"></path>
            <circle cx="266.95" cy="179.45" r="11.55" fill="var(--color-green)"></circle>
            <circle cx="268.49" cy="180.55" r="11.55" fill="#fff"></circle>
            <path d="M288.44,194.54a1.57,1.57,0,0,1-1.1-.46L282.28,189a1.56,1.56,0,0,1,2.21-2.21l3.87,3.88,7.92-9.08a1.56,1.56,0,0,1,2.36,2l-9,10.34a1.58,1.58,0,0,1-1.13.54Z" transform="translate(-21.75 -7.18)" fill="var(--color-green)"></path>
            <polygon points="250.85 401.55 203.33 401.55 208.9 342.59 243.52 341.71 250.85 401.55" fill="var(--color-sand-dark)"></polygon>
            <polygon points="250.85 401.55 203.33 401.55 208.9 342.59 243.52 341.71 250.85 401.55" fill="url(#orders-empty-linear-gradient-15)"></polygon>
            <polygon points="242.93 401.55 195.41 401.55 200.98 342.59 235.6 341.71 242.93 401.55" fill="var(--color-sand-dark)"></polygon>
            <polygon points="242.93 401.55 195.41 401.55 200.98 342.59 235.6 341.71 242.93 401.55" fill="url(#orders-empty-linear-gradient-16)"></polygon>
            <path
                d="M253.31,350.19l-.56-.06c.09-2.59,0-9.84-3.41-14a8.15,8.15,0,0,0-6.12-3.13,7.86,7.86,0,0,0-6.16,2.31c-4.19,4.1-5.1,12.35-5.28,15l-.69,0c0-.47.4-11.1,5.62-15.88a8.46,8.46,0,0,1,6.57-2.31,8.8,8.8,0,0,1,6.47,3.12C254.12,340.29,253.37,349.79,253.31,350.19Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-sand-dark)"
            >
            </path>
            <path
                d="M258.52,350.19l-.57-.06c.1-2.59,0-9.84-3.4-14a8.19,8.19,0,0,0-6.13-3.13,7.87,7.87,0,0,0-6.16,2.31c-4.18,4.1-5.09,12.35-5.28,15l-.69,0c0-.47.41-11.1,5.63-15.88a8.43,8.43,0,0,1,6.56-2.31,8.77,8.77,0,0,1,6.47,3.12C259.33,340.29,258.58,349.79,258.52,350.19Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-sand-dark)"
            >
            </path>
            <polygon points="274.61 401.55 227.09 401.55 232.66 342.59 267.28 341.71 274.61 401.55" fill="var(--color-sand-dark)"></polygon>
            <polygon points="274.61 401.55 227.09 401.55 232.66 342.59 267.28 341.71 274.61 401.55" fill="url(#orders-empty-linear-gradient-17)"></polygon>
            <polygon points="266.69 401.55 219.17 401.55 224.74 342.59 259.36 341.71 266.69 401.55" fill="var(--color-sand-dark)"></polygon>
            <polygon points="266.69 401.55 219.17 401.55 224.74 342.59 259.36 341.71 266.69 401.55" fill="url(#orders-empty-linear-gradient-18)"></polygon>
            <path
                d="M277.07,350.19l-.56-.06c.09-2.59,0-9.84-3.41-14A8.15,8.15,0,0,0,267,333a7.86,7.86,0,0,0-6.16,2.31c-4.19,4.1-5.1,12.35-5.28,15l-.69,0c0-.47.41-11.1,5.62-15.88A8.46,8.46,0,0,1,267,332.1a8.8,8.8,0,0,1,6.47,3.12C277.88,340.29,277.13,349.79,277.07,350.19Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-sand-dark)"
            >
            </path>
            <path
                d="M282.28,350.19l-.56-.06c.09-2.59,0-9.84-3.41-14a8.19,8.19,0,0,0-6.13-3.13,7.85,7.85,0,0,0-6.15,2.31c-4.19,4.1-5.1,12.35-5.29,15l-.68,0c0-.47.4-11.1,5.62-15.88a8.44,8.44,0,0,1,6.56-2.31,8.77,8.77,0,0,1,6.47,3.12C283.09,340.29,282.34,349.79,282.28,350.19Z"
                transform="translate(-21.75 -7.18)"
                fill="var(--color-sand-dark)"
            >
            </path>
        </svg>
    );
};

export default SvgOrdersEmpty;
