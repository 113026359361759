import {ApiModel} from "../../../common/api/model/ApiModel";

export class OrderTable extends ApiModel {
    dataset_id: string = "";
    project_id: string = "";
    row_count: number = 0;
    table_id: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
