export class OrderCurrency {
    static EUR: OrderCurrency = new OrderCurrency("EUR", "€");
    static USB: OrderCurrency = new OrderCurrency("USB", "$");
    static GBP: OrderCurrency = new OrderCurrency("GBP", "£");

    name: string;
    symbol: string;

    constructor(name: string, symbol: string) {
        this.name = name;
        this.symbol = symbol;
    }

    static values(): OrderCurrency[] {
        return [
            OrderCurrency.EUR,
            OrderCurrency.USB,
            OrderCurrency.GBP
        ];
    }

    static getByName(name: string): OrderCurrency | undefined {
        return this.values().find(((it) => it.name === name));
    }
}