import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ColumnFilterOperator} from "./ColumnFilterOperator";

export class StorageColumnFilter extends ApiModel {
    column_index: number = 0;
    values: string[] = [];
    operator: ColumnFilterOperator = ColumnFilterOperator.EQUALS;
    not: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            column_index: +this.column_index,
            values: this.values,
            operator: this.operator,
            not: this.not
        };
    }
}
