import React from "react";
import {useTranslation} from "react-i18next";
import {FormLayoutRows, Loader, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalTitle, ModalTitleSize} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";

const ModalUploadStorageLoading = () => {
    const {t: textStorageUpload} = useTranslation(TranslationPortalFile.STORAGE_UPLOAD);

    return (
        <ModalContent>
            <ModalTitle size={ModalTitleSize.HUGE}>
                {textStorageUpload("modal_loading.title")}
            </ModalTitle>
            <FormLayoutRows>
                <Loader/>
                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                    <span dangerouslySetInnerHTML={{__html: textStorageUpload("modal_loading.description")}}/>
                </ModalDescription>
            </FormLayoutRows>
        </ModalContent>
    );
};

export default ModalUploadStorageLoading;
