import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalTitle, ModalTitleSize} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SUPPORTED_COLUMN_SEPARATORS} from "../../../api/model/storage/StorageUploadRequest";

type ModalUploadStorageIndicationProps = {
    onSubmit: () => void;
}

const ModalUploadStorageIndication: FunctionComponent<ModalUploadStorageIndicationProps> = ({onSubmit}) => {
    const {t: textStorageUpload} = useTranslation(TranslationPortalFile.STORAGE_UPLOAD);

    return (
        <ModalContent>
            <ModalTitle size={ModalTitleSize.HUGE}>
                {textStorageUpload("modal_indication.title")}
            </ModalTitle>
            <FormLayoutRows>
                <ModalDescription>
                    <span dangerouslySetInnerHTML={{__html: textStorageUpload("modal_indication.description")}}/>
                    <br/><br/>
                    <span dangerouslySetInnerHTML={{__html: textStorageUpload("file_requirements", {separatorList: SUPPORTED_COLUMN_SEPARATORS.map(({escapedValue}) => `<span class="code">${escapedValue}</span>`).join(" - ")})}}/>
                </ModalDescription>
                <FormLayoutButtons>
                    <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={onSubmit}>
                        {textStorageUpload("modal_indication.btn_start")}
                    </Button>
                </FormLayoutButtons>
            </FormLayoutRows>
        </ModalContent>
    );
};

export default ModalUploadStorageIndication;
