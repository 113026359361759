import React, {FunctionComponent, useEffect, useState} from "react";
import {StorageUploadRequest} from "../../../api/model/storage/StorageUploadRequest";
import {ModalNew} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {ModalUploadStorageConfiguration, ModalUploadStorageConfirmation, ModalUploadStorageImportFile, ModalUploadStorageIndication, ModalUploadStorageLoading, ModalUploadStorageValidation} from "../index";
import {UploadStorageStep} from "../../../utils/UploadStorageStep";

type ModalUploadStorageProps = {
    active: boolean;
    onClose: (refresh: boolean) => void;
}

const ModalUploadStorage: FunctionComponent<ModalUploadStorageProps> = ({active, onClose}) => {
    const [currentStep, setCurrentStep] = useState<UploadStorageStep>(UploadStorageStep.INDICATION);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    const [storageUploadRequest, setStorageUploadRequest] = useState<StorageUploadRequest>(new StorageUploadRequest());
    const [fileFirstRows, setFileFirstRows] = useState<string[]>([]);
    const [isUploadSuccess, setUploadSuccess] = useState(true);

    const scrollToModalTop = () => {
        let elements = document.getElementsByClassName("modal-content");
        Array.from(new Array(elements.length)).forEach((_, index) => {
            elements[index].scrollTop = 0;
        });
    };

    useEffect(() => {
        setCurrentStep(UploadStorageStep.values()[currentStepIndex]);
        scrollToModalTop();
    }, [currentStepIndex]);

    const handleSubmitStep = (newStorageUploadRequest: StorageUploadRequest, newFileFirstRows?: string[]) => {
        setStorageUploadRequest(newStorageUploadRequest);
        newFileFirstRows && setFileFirstRows(newFileFirstRows);
        handleGoToNextStep();
    };

    const handleGoToNextStep = () => {
        setCurrentStepIndex((prev) => prev + 1);
    };

    const handleGoBack = () => {
        (currentStepIndex > 0) && setCurrentStepIndex((prev) => prev - 1);
    };

    const handleClose = () => {
        setStorageUploadRequest(new StorageUploadRequest());
        setFileFirstRows([]);
        onClose(currentStep.name === UploadStorageStep.CONFIRMATION.name && isUploadSuccess);
        setUploadSuccess(true);
        setCurrentStepIndex(0);
    };

    const handleUploadStorage = async (storageUploadRequest: StorageUploadRequest) => {
        handleGoToNextStep();
        const formDataRequest = storageUploadRequest.getFormData();
        if (formDataRequest) {
            try {
                await session.restStorage.uploadStorage(formDataRequest);
            } catch (e) {
                console.error(e);
                setUploadSuccess(false);
            }
        } else {
            setUploadSuccess(false);
        }
        handleGoToNextStep();
    };

    return (
        <ModalNew onClose={handleClose} active={active} size={currentStep.modalSize}>
            {currentStep.name === UploadStorageStep.INDICATION.name &&
            <ModalUploadStorageIndication onSubmit={handleGoToNextStep}/>}

            {currentStep.name === UploadStorageStep.IMPORT_FILE.name &&
            <ModalUploadStorageImportFile
                initStorageUploadRequest={storageUploadRequest}
                initFileFirstRows={fileFirstRows}
                onSubmit={handleSubmitStep}
            />}

            {currentStep.name === UploadStorageStep.CONFIGURATION.name &&
            <ModalUploadStorageConfiguration
                initStorageUploadRequest={storageUploadRequest}
                fileFirstRows={fileFirstRows}
                onSubmit={handleSubmitStep}
                onGoBack={handleGoBack}
            />}

            {currentStep.name === UploadStorageStep.VALIDATION.name &&
            <ModalUploadStorageValidation
                initStorageUploadRequest={storageUploadRequest}
                onSubmit={handleUploadStorage}
                onGoBack={handleGoBack}
            />}

            {currentStep.name === UploadStorageStep.LOADING.name &&
            <ModalUploadStorageLoading/>}

            {currentStep.name === UploadStorageStep.CONFIRMATION.name &&
            <ModalUploadStorageConfirmation
                isUploadSuccess={isUploadSuccess}
                onClose={handleClose}
            />}
        </ModalNew>
    );
};

export default ModalUploadStorage;
