import {pathCountings, pathOrders, pathStorages} from "./constants";
import {pathHome} from "../common/utils/constants";
import {MenuItem} from "@sirdata/ui-lib";

export enum Menu {
    HOME = "home",
    STORAGES = "storages",
    COUNTINGS = "countings",
    ORDERS = "orders"
}

export const MENU: MenuItem[] = [
    {label: Menu.HOME, path: pathHome, icon: {name: "home"}},
    {label: Menu.STORAGES, path: pathStorages, icon: {name: "storage"}},
    {label: Menu.COUNTINGS, path: pathCountings, icon: {name: "group_add"}},
    {label: Menu.ORDERS, path: pathOrders, icon: {name: "shopping_cart"}}
];

export const SHORTCUT_MENU: MenuItem[] = [];
