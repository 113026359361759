import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";

import ModalPartnerPricing from "./component/modal/ModalPartnerPricing";
import PrivateRoute from "./component/guard/PrivateRoute";
import {Access, Countings, CountingsDetails, Home, LegalNotice, Login, Orders, OrdersDetails, Sso, Storages, StoragesDetails} from "./screen/index";
import {ApiEventListener} from "./common/component/snippet";
import {Error403, Error404, Error500} from "./common/screen/index";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import {pathCountings, pathCountingsWithIds, pathOrders, pathOrdersWithId, pathStorages, pathStoragesWithId} from "./utils/constants";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {session} from "./api/ApiSession";

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<RouterMainLayout/>}>
                                <Route path={pathSso} element={<Sso/>}/>

                                <Route path={pathAccess} element={<Access/>}/>
                                <Route path={pathError403} element={<Error403/>}/>
                                <Route path={pathError404} element={<Error404/>}/>
                                <Route path={pathError500} element={<Error500/>}/>

                                <Route path="/" element={<Navigate to={pathLogin}/>}/>
                                <Route path={pathLogin} element={<Login/>}/>

                                <Route path={pathHome} element={<PrivateRoute children={<Home/>}/>}/>
                                <Route path={pathStorages} element={<PrivateRoute children={<Storages/>}/>}/>
                                <Route path={pathStoragesWithId} element={<PrivateRoute children={<StoragesDetails/>}/>}/>
                                <Route path={pathCountings} element={<PrivateRoute children={<Countings/>}/>}/>
                                <Route path={pathCountingsWithIds} element={<PrivateRoute children={<CountingsDetails/>}/>}/>
                                <Route path={pathOrders} element={<PrivateRoute children={<Orders/>}/>}/>
                                <Route path={pathOrdersWithId} element={<PrivateRoute children={<OrdersDetails/>}/>}/>

                                <Route path={pathLegalNotice} element={<LegalNotice/>}/>
                                <Route path="*" element={<Error404/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;

function RouterMainLayout() {
    return (
        <>
            <ApiEventListener/>
            <ScrollToTop/>
            <Outlet/>
            <ModalPartnerPricing/>
        </>
    );
}
