import {ApiModel} from "../../../common/api/model/ApiModel";

export const SUPPORTED_CONTENT_TYPES = [
    "text/csv",
    "text/tsv"
];

export const SUPPORTED_MAPPING_TYPES = [
    {
        name: "MD5",
        length: 32
    }, {
        name: "SHA-256",
        length: 64
    }
];

export const SUPPORTED_COLUMN_SEPARATORS = [
    {value: ",", label: "comma", escapedValue: ","},
    {value: ";", label: "semicolon", escapedValue: ";"},
    {value: "|", label: "pipe", escapedValue: "|"},
    {value: "\t", label: "tabbing", escapedValue: "\\t"}
];

export const DEFAULT_JOB_QUEUE = "INTERSECT_TABLE_TO_SEGMENT";
export const DEFAULT_CONTENT_TYPE = "text/csv";
export const DEFAULT_SEPARATOR = ",";

export class StorageUploadRequest extends ApiModel {
    name: string = "";
    content_type: string = DEFAULT_CONTENT_TYPE;
    csv_separator: string = DEFAULT_SEPARATOR;
    csv_column_names: string[] = [];
    csv_has_header: boolean = true;
    csv_mapping_column_position: number = 1;
    file: File | undefined = undefined;
    job_queue: string = DEFAULT_JOB_QUEUE;

    getFormData(): FormData | undefined {
        if (!!this.file) {
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("content_type", this.content_type);
            formData.append("csv_separator", this.csv_separator);
            formData.append("csv_column_names", JSON.stringify(this.csv_column_names));
            formData.append("csv_has_header", this.csv_has_header ? "1" : "0");
            formData.append("csv_mapping_column_position", this.csv_mapping_column_position.toString());
            formData.append("file", this.file);
            formData.append("job_queue", this.job_queue);
            return formData;
        }
    }
}

export const isValidMappingValue = (value: string): boolean => {
    return (value === value.toLowerCase()) && !!SUPPORTED_MAPPING_TYPES.find((mappingType) => mappingType.length === value.replace(/['"]+/g, "").length);
};

export const getMappingColumnPosition = (rows: string[], rowSeparator: string) => {
    const rowsMappingColumnIndexes: Map<number, boolean> = new Map();
    rows.forEach((row) => {
        const values = row.split(rowSeparator);
        const key = values.findIndex((value) => isValidMappingValue(value));
        rowsMappingColumnIndexes.set(key, true);
    });

    if (rowsMappingColumnIndexes.size === 1) {
        return rowsMappingColumnIndexes.keys().next().value + 1;
    }
    return 0;
};
