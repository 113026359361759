import {ApiModel} from "../../../common/api/model/ApiModel";
import {DEFAULT_CONTENT_TYPE} from "./StorageUploadRequest";

export class StorageUploadedFile extends ApiModel {
    bucket: string = "";
    filename: string = "";
    size_in_bytes: number = 0;
    content_type: string = DEFAULT_CONTENT_TYPE;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
