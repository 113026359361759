import {useEffect, useState} from "react";
import {PricingDetails} from "../../api/model/partner/pricing/PricingDetails";
import {session} from "../../api/ApiSession";

const usePricingDetails = () => {
    const [pricingDetails, setPricingDetails] = useState<PricingDetails>(new PricingDetails());

    useEffect(() => {
        (async function() {
            try {
                setPricingDetails(await session.getPricingDetails());
            } catch (e) {}
        })();
    }, []);

    return [pricingDetails] as const;
};

export default usePricingDetails;
