import React, {FunctionComponent, useState} from "react";
import validate from "validate.js";
import {
    Action,
    Alert,
    AlertSeverity,
    Button,
    ButtonSize,
    ButtonStyle,
    FlexContent,
    FlexContentDirection,
    FlexContentSpacing,
    ModalActions,
    ModalActionsAlignment,
    ModalContent,
    ModalHeader,
    ModalHeaderDirection,
    ModalHeaderTitle,
    ModalStepper,
    SvgUpload,
    TranslationLibFile,
    UploadPanel
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {
    DEFAULT_CONTENT_TYPE,
    DEFAULT_SEPARATOR,
    getMappingColumnPosition,
    StorageUploadRequest,
    SUPPORTED_COLUMN_SEPARATORS,
    SUPPORTED_CONTENT_TYPES
} from "../../../api/model/storage/StorageUploadRequest";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalUploadStorageImportFileProps = {
    initStorageUploadRequest: StorageUploadRequest;
    initFileFirstRows: string[];
    onSubmit: (storageUploadRequest: StorageUploadRequest, fileFirstRows?: string[]) => void;
}

const MAX_FILE_SIZE = 3000000000; // 3Gb
const FIRST_BITES_LIMIT_FOR_READER = 10000;
const FILE_FIRST_ROWS_COUNT = 6;
const ALLOWED_CONTENT_TYPES = [...SUPPORTED_CONTENT_TYPES, "text/plain", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

const ModalUploadStorageImportFile: FunctionComponent<ModalUploadStorageImportFileProps> = ({initStorageUploadRequest, initFileFirstRows, onSubmit}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textStorageUpload} = useTranslation(TranslationPortalFile.STORAGE_UPLOAD);
    const [error, setError] = useState<string>("");

    const [storageUploadRequest, setStorageUploadRequest] = useState<StorageUploadRequest>(initStorageUploadRequest);
    const [fileFirstRows, setFileFirstRows] = useState<string[]>(initFileFirstRows);

    const handleBrowseFile = (file?: File) => {
        let newError = "";
        try {
            if (file) {
                let newStorageUploadRequest = new StorageUploadRequest();
                newStorageUploadRequest.file = file;
                newStorageUploadRequest.name = file.name;
                if (!ALLOWED_CONTENT_TYPES.includes(file.type)) {
                    newError = textStorageUpload("error.file_type");
                }
                if (SUPPORTED_CONTENT_TYPES.includes(file.type)) {
                    newStorageUploadRequest.content_type = file.type;
                } else {
                    newStorageUploadRequest.content_type = DEFAULT_CONTENT_TYPE;
                }
                if (file.size > MAX_FILE_SIZE) {
                    newError = textStorageUpload("error.file_size");
                }
                setStorageUploadRequest(newStorageUploadRequest);
                if (!!newError) return;

                const reader = new FileReader();
                reader.onload = (evt) => {
                    const fileResult = (evt.target?.result as string).trim();
                    const fileFirstRows = fileResult.split(/\r\n|\r|\n/, FILE_FIRST_ROWS_COUNT);
                    setFileFirstRows(fileFirstRows);
                };
                let fileFirstPart = file.slice(0, FIRST_BITES_LIMIT_FOR_READER);
                reader.readAsBinaryString(fileFirstPart);
            }
        } catch (e) {
        } finally {
            setError(newError);
        }
    };

    const buildUploadRequest = () => {
        if (fileFirstRows.length > 0) {
            let newStorageUploadRequest: StorageUploadRequest = storageUploadRequest;
            const separator = SUPPORTED_COLUMN_SEPARATORS.find((separator) => {
                const columnsCount = fileFirstRows[0].split(separator.value).length;
                return fileFirstRows.slice(0).every((row) => {
                    const values = row.split(separator.value);
                    return ((values.length > 1) && (values.length === columnsCount));
                });
            })?.value || DEFAULT_SEPARATOR;
            const columnsCount = fileFirstRows[0].split(separator).length;

            const doesntContainPlainEmail = fileFirstRows[0].split(separator).every((value) => {
                const emailError = validate({from: value}, {from: {email: true}});
                return (!!emailError);
            });
            if (!doesntContainPlainEmail) {
                setError(textStorageUpload("error.contain_plain_email"));
                return;
            }

            let mappingColumnPosition;
            let hasHeaders = false;
            let csvColumnsNames = Array(columnsCount).fill("");

            if (fileFirstRows.length === 1) {
                mappingColumnPosition = getMappingColumnPosition(fileFirstRows.slice(0), separator);
            } else {
                mappingColumnPosition = getMappingColumnPosition(fileFirstRows.slice(1), separator);

                hasHeaders = getMappingColumnPosition(fileFirstRows.slice(0, 1), separator) !== mappingColumnPosition;
                if (hasHeaders) {
                    const columnNames = fileFirstRows[0].split(separator);
                    csvColumnsNames.splice(0, columnNames.length, ...columnNames);
                }
            }

            if (!mappingColumnPosition) {
                setError(textStorageUpload("error.hash_not_found"));
                return;
            }

            newStorageUploadRequest.csv_separator = separator;
            newStorageUploadRequest.csv_mapping_column_position = mappingColumnPosition;
            newStorageUploadRequest.csv_has_header = hasHeaders;
            newStorageUploadRequest.csv_column_names = csvColumnsNames;
            return newStorageUploadRequest;
        }
    };

    const handleSubmit = () => {
        const newStorageUploadRequest = buildUploadRequest();
        if (newStorageUploadRequest) {
            setStorageUploadRequest(newStorageUploadRequest);
            setError("");
            onSubmit(newStorageUploadRequest, fileFirstRows);
        }
    };

    return (
        <>
            <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                <ModalStepper steps={3} activeStep={0}/>
                <ModalHeaderTitle title={textStorageUpload("modal_import_file.title")}/>
            </ModalHeader>
            <ModalContent>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                    <Alert
                        title={textStorageUpload("modal_import_file.description")}
                        text={textStorageUpload("file_requirements", {separatorList: SUPPORTED_COLUMN_SEPARATORS.map(({escapedValue}) => `<span class="code">${escapedValue}</span>`).join(" - ")})}
                    />
                    {error &&
                        <Alert text={error} severity={AlertSeverity.DANGER}/>
                    }
                    <UploadPanel
                        fileName={storageUploadRequest.name}
                        onChange={handleBrowseFile}
                        onRemove={() => {
                            setStorageUploadRequest(new StorageUploadRequest());
                            setFileFirstRows([]);
                            setError("");
                        }}
                        image={SvgUpload}
                    />
                </FlexContent>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.RIGHT}>
                <Button
                    size={ButtonSize.MEDIUM}
                    style={ButtonStyle.PRIMARY_GREEN}
                    onClick={handleSubmit}
                    disabled={!storageUploadRequest.name || !fileFirstRows.length || !!error.length}
                >
                    {textCommon(Action.IMPORT.labelKey)}
                </Button>
            </ModalActions>
        </>
    );
};

export default ModalUploadStorageImportFile;
