import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Order} from "../../../api/model/order/Order";
import {ButtonBack, ButtonSize, ButtonValidate, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, FormLayoutRows, InputText, ModalActions, ModalActionsAlignment, ModalContent} from "@sirdata/ui-lib";

type ModalProcessOrderValidationProps = {
    initOrder: Order;
    onSubmitOrder: (order: Order) => void;
    onGoBack: () => void;
};

const ModalProcessOrderValidation: FunctionComponent<ModalProcessOrderValidationProps> = ({initOrder, onSubmitOrder, onGoBack}) => {
    const {t: textProcessOrder} = useTranslation(TranslationPortalFile.PROCESS_ORDER);

    const [orderName, setOrderName] = useState<string>("");

    useEffect(() => {
        const newName = initOrder.comment;
        setOrderName(newName);
    }, [initOrder]);

    const handleSubmit = () => {
        let newOrder = new Order();
        newOrder.load(initOrder);
        newOrder.comment = orderName.trim();
        onSubmitOrder(newOrder);
    };

    return (
        <>
            <ModalContent>
                <FormLayoutRows>
                    <FieldBlock label={textProcessOrder("validation.name.label")}>
                        <InputText
                            value={orderName}
                            placeholder={textProcessOrder("validation.name.placeholder")}
                            onChange={setOrderName}
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <ButtonBack onClick={onGoBack}/>
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE} alignment={FlexContentAlignment.START}>
                    <div className="form-order-cost">
                        <span>{textProcessOrder("label.cost")}</span>
                        <span className="h2">{initOrder.pendingCost || "-"}</span>
                    </div>
                    <ButtonValidate size={ButtonSize.MEDIUM} onClick={handleSubmit} disabled={!orderName}/>
                </FlexContent>
            </ModalActions>
        </>
    );
};

export default ModalProcessOrderValidation;
