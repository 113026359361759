import {ApiModel} from "../../../../common/api/model/ApiModel";

export class StorageFilterOrder extends ApiModel {
    order_id: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            order_id: this.order_id
        };
    }
}
