import {ModalNewSize} from "@sirdata/ui-lib";

export class ModalStep {
    name: string;
    modalSize?: ModalNewSize;

    constructor(name: string) {
        this.name = name;
    }

    withModalSize(modalSize: ModalNewSize): this {
        this.modalSize = modalSize;
        return this;
    }
}

