import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {AlertSeverity, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {StorageFilter} from "../../api/model/storage-filter/StorageFilter";
import {ModalDeleteWarning, ModalProcessOrder} from "../modal/index";
import {session} from "../../api/ApiSession";
import {CountingTableRow} from "./index";
import {Order} from "../../api/model/order/Order";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {UIEventManager} from "../../common/utils/UIEventManager";

type CountingsTableProps = {
    countings: StorageFilter[];
    onRefresh: () => void;
    onProcessCounting: (initCounting: StorageFilter) => void;
};

const MAX_COUNTING_ROWS_PER_PAGE = 20;

const CountingsTable: FunctionComponent<CountingsTableProps> = ({countings, onRefresh, onProcessCounting}) => {
    const {t: textCountings} = useTranslation(TranslationPortalFile.COUNTINGS);

    const [activeDeleteCounting, setActiveDeleteCounting] = useState<StorageFilter>();
    const [activeModalProcessOrder, setActiveModalProcessOrder] = useState<Order>();

    const handleDuplicateCounting = (duplicatedCounting: StorageFilter) => {
        let newCounting = new StorageFilter();
        newCounting.storage = duplicatedCounting.storage;
        newCounting.storage_id = duplicatedCounting.storage_id;
        newCounting.dimension_indexes = duplicatedCounting.dimension_indexes;
        newCounting.segment_filter = duplicatedCounting.segment_filter;
        newCounting.column_filter = duplicatedCounting.column_filter;
        newCounting.name = `${duplicatedCounting.name} (${textCountings("copy")})`;
        onProcessCounting(newCounting);
    };

    const handleProcessOrder = async (counting?: StorageFilter) => {
        if (counting) {
            try {
                const countingFullInfo = await session.restStorageFilter.getStorageFilter(counting.storage_id.toString(), counting.id.toString());
                setActiveModalProcessOrder(countingFullInfo.getOrder());
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    UIEventManager.alert(textCountings("error.get_counting"), AlertSeverity.DANGER);
                }
            }
        } else {
            setActiveModalProcessOrder(undefined);
        }
    };

    const doDeleteCounting = async (counting?: StorageFilter) => {
        if (!counting) return;
        try {
            await session.restStorageFilter.deleteStorageFilter(counting);
            setActiveDeleteCounting(undefined);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                UIEventManager.alert(textCountings("error.delete_counting"), AlertSeverity.DANGER);
            }
        } finally {
            onRefresh();
        }
    };

    return (
        <>
            <Table
                columns={[
                    {label: textCountings("table.headers.status"), width: 15},
                    {label: textCountings("table.headers.id"), width: 10},
                    {label: textCountings("table.headers.name")},
                    {label: textCountings("table.headers.creation_date"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                    {label: textCountings("table.headers.actions"), width: 10, styles: TableColumnStyle.ALIGN_CENTER}
                ]}
                pagination={MAX_COUNTING_ROWS_PER_PAGE}
            >
                {countings.map((counting) =>
                    <CountingTableRow
                        key={counting.id}
                        counting={counting}
                        actions={{
                            onDuplicate: () => handleDuplicateCounting(counting),
                            onProcessOrder: () => handleProcessOrder(counting),
                            onDelete: () => setActiveDeleteCounting(counting)
                        }}
                    />
                )}
            </Table>
            <ModalProcessOrder initOrder={activeModalProcessOrder} onClose={() => handleProcessOrder(undefined)}/>
            <ModalDeleteWarning active={!!activeDeleteCounting} message={textCountings("table.actions.delete_warning")} onClose={() => setActiveDeleteCounting(undefined)} onDelete={() => doDeleteCounting(activeDeleteCounting)}/>
        </>
    );
};

export default CountingsTable;
