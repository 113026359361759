export enum StorageErrorCode {
    NONE = "",
    FAIL_GET_STORAGE = "fail_get_storage",
    FAIL_INTERSECT_DATA = "fail_intersect_data",
    INVALID_MAPPING_TYPE = "invalid_mapping_type",
    INVALID_CONTENT_TYPE = "invalid_content_type",
    FAIL_LOAD = "fail_load",
    CASE_ERROR_LIMIT_REACHED = "case_error_limit_reached",
    FORMAT_ERROR_LIMIT_REACHED = "format_error_limit_reached",
    FAIL_VERIFY_DATA = "fail_verify_data"
}
