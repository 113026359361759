export enum OrderLimitType {
    NONE = "NONE",
    GLOBAL = "GLOBAL",
    PER_COLUMN = "PER_COLUMN"
}

export const ORDER_LIMIT_TYPES: OrderLimitType[] = [
    OrderLimitType.NONE,
    OrderLimitType.GLOBAL,
    OrderLimitType.PER_COLUMN
];