import {ApiModel} from "../../../../common/api/model/ApiModel";
import {OrderSortOrder} from "./OrderSortOrder";
import {OrderSortType} from "./OrderSortType";

export class RequestedOrdersQuery extends ApiModel {
    storage_id: number | undefined;
    filter_id: number | undefined;
    size: number = 200;
    page: number = 0;
    order_by: string = OrderSortType.CREATION;
    sort_order: string = OrderSortOrder.DESCENDANT;
    private _status: string | undefined = "";
    private _search: string | undefined = "";
    private _start_date: string | undefined = "";
    private _end_date: string | undefined = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get status(): string {
        return this._status ? this._status : "";
    }

    set status(status: string) {
        this._status = !!status ? status : undefined;
    }

    get search(): string {
        return this._search ? this._search : "";
    }

    set search(search: string) {
        this._search = !!search ? search : undefined;
    }

    get start_date(): string {
        return this._start_date ? this._start_date : "";
    }

    set start_date(start_date: string) {
        this._start_date = !!start_date ? start_date : undefined;
    }

    get end_date(): string {
        return this._end_date ? this._end_date : "";
    }

    set end_date(end_date: string) {
        this._end_date = !!end_date ? end_date : undefined;
    }

    getJsonParameters(): {} {
        return {
            storage_id: this.storage_id,
            filter_id: this.filter_id,
            size: this.size,
            page: this.page,
            order_by: this.order_by,
            sort_order: this.sort_order,
            status: this.status,
            search: this.search,
            start_date: this.start_date,
            end_date: this.end_date
        };
    }
}
