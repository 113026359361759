import {ApiModel} from "../../../common/api/model/ApiModel";
import {OrderStatus} from "./OrderStatus";
import {OrderLimitPerColumn} from "./OrderLimitPerColumn";
import {OrderCurrency} from "./OrderCurrency";
import {StorageFilter} from "../storage-filter/StorageFilter";
import {pathOrdersWithId} from "../../../utils/constants";
import {OrderTable} from "./OrderTable";
import {OrderErrorCode} from "./OrderErrorCode";
import {Formatter} from "../../../common/utils/Formatter";
import {PathHelper} from "../../../common/utils/PathHelper";

export class Order extends ApiModel {
    private _comment: string | null = "";
    private _cost: number | null = 0;
    creation_ts: string = "";
    currency: string = OrderCurrency.EUR.name;
    discount: number = 0;
    discount_reason: string = "";
    download_date: string | null = "";
    downloadable: boolean = false;
    error_code: string = "";
    error_message: string = OrderErrorCode.NONE;
    private _filter: StorageFilter = new StorageFilter();
    filter_id: number = 0;
    id: number = 0;
    ip_address: string = "";
    last_update_ts: string = "";
    private _limit: number | null = 0;
    limit_per_column: OrderLimitPerColumn | null = null;
    partner_id: number = 0;
    row_count: number = 0;
    status: string = OrderStatus.PENDING;
    storage_id: number = 0;
    table: OrderTable | null = new OrderTable();
    user_id_requested: string | null = null;
    pendingCost: string | null = null;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get comment(): string {
        return this._comment ? this._comment : "";
    }

    set comment(comment: string) {
        this._comment = !!comment ? comment : null;
    }

    get limit(): number {
        return this._limit ? this._limit : 0;
    }

    set limit(limit: number) {
        this._limit = !!limit ? limit : null;
    }

    get cost(): number {
        return Formatter.convertCentsToUnits(this._cost ? this._cost : 0);
    }

    set cost(cost: number) {
        this._cost = !!cost ? cost : null;
    }

    get filter(): StorageFilter {
        return this._filter ? this._filter : new StorageFilter();
    }

    set filter(filter: StorageFilter) {
        this._filter = new StorageFilter(filter);
    }

    getJsonParameters(): {} {
        return {
            comment: this.comment,
            limit: this.limit,
            limit_per_column: this.limit_per_column ? this.limit_per_column.getJsonParameters() : this.limit_per_column
        };
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getRoute(): string {
        return PathHelper.buildPathWithId(pathOrdersWithId, this.id);
    }

    isCancellable(): boolean {
        return ![OrderStatus.ACCEPTED, OrderStatus.CANCELED].includes(this.status as OrderStatus);
    }

    isDownloadable(): boolean {
        return this.status === OrderStatus.ACCEPTED && this.downloadable;
    }

    getOrderCurrencySymbol(): string {
        return OrderCurrency.getByName(this.currency)?.symbol || "";
    }
}
