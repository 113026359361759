import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Formatter} from "../../../../common/utils/Formatter";
import {PartnerPricing} from "./PartnerPricing";
import {Pricing} from "./Pricing";
import {PricingDetailsStep} from "./PricingDetailsStep";

export class PricingDetails extends ApiModel {
    partner_pricing: PartnerPricing = new PartnerPricing();
    _pricing: Pricing = new Pricing();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "partner_pricing") {
                    this[key] = new PartnerPricing(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get pricing(): Pricing {
        return new Pricing(this._pricing);
    }

    set pricing(pricing: Pricing) {
        this._pricing = pricing;
    }

    private getPrice(initialPrice: number): number {
        let price = initialPrice;
        if (this.partner_pricing.discount) {
            price = price * (100 - this.partner_pricing.discount) / 100;
        }
        return Formatter.convertCentsToUnits(price);
    }

    getSteps(): PricingDetailsStep[] {
        const stepList: PricingDetailsStep[] = [];

        this.pricing.steps_price.forEach((step, index) => {
            const prevStep = this.pricing.steps_price[index - 1];
            const min = prevStep?.limit || 0;
            const max = step.limit || 0;
            const price = this.getPrice(step.volume_cost_per_unit || 0);

            stepList.push({min, max, price});
        });

        return stepList;
    }

    getFixedPrice(): number {
        return this.getPrice(this.pricing.volume_cost_per_unit);
    }

    isStepPricePerUnit(): boolean {
        return this.pricing.step_price_is_per_unit;
    }

    private getStepCpm(count: number) {
        const steps = this.getSteps();
        const foundStep = steps.find((step) => count >= step.min && count <= step.max);
        const lastStep = steps[steps.length - 1];

        return foundStep ? foundStep.price : lastStep.price;
    }

    getCostForVolume(count: number): string {
        if (!count) return "-";
        const costVolume = count > 1000 ? count : 1000;

        const cpm = this.isStepPricePerUnit() ? this.getStepCpm(costVolume) : this.getFixedPrice();

        return Formatter.formatNumber((costVolume / 1000) * cpm, "0,0.[00]") + "€";
    }
}
