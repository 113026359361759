import {Locale} from "../common/utils/Locale";
import {usePreprod} from "../common/utils/helper";

export const accountRoot = (usePreprod ? "https://account-preprod.sirdata.io" : "https://account.sirdata.io");
export const audienceHome = (usePreprod ? "https://audience-preprod.sirdata.io/home" : "https://audience.sirdata.io/home");

export const bytesToSize = (bytes: number) => {
    const sizes = Locale.isFrench() ? ["octets", "Ko", "Mo", "Go", "To"] : ["bytes", "Kb", "Mb", "Gb", "Tb"];
    if (bytes === 0) return "0";

    const sizesIndex = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, sizesIndex)).toFixed(2)) + " " + sizes[sizesIndex];
};

