import React, {FunctionComponent} from "react";

const SvgCountings: FunctionComponent = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 150.03 119.12"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="countings-linear-gradient"
                    x1="346.78"
                    y1="-130.78"
                    x2="202.62"
                    y2="-31.92"
                    gradientTransform="matrix(1, -0.1, 0.1, 1, -181.12, 186.4)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"/>
                    <stop offset="0.08" stopOpacity="0.69"/>
                    <stop offset="0.21" stopOpacity="0.32"/>
                    <stop offset="1" stopOpacity="0"/>
                </linearGradient>
                <linearGradient
                    id="countings-linear-gradient-2"
                    x1="353.13"
                    y1="-121.52"
                    x2="208.97"
                    y2="-22.66"
                    xlinkHref="#countings-linear-gradient"
                />
                <linearGradient
                    id="countings-linear-gradient-3"
                    x1="353.24"
                    y1="-121.36"
                    x2="209.08"
                    y2="-22.5"
                    xlinkHref="#countings-linear-gradient"
                />
                <linearGradient
                    id="countings-linear-gradient-4"
                    x1="355.49"
                    y1="-118.08"
                    x2="211.33"
                    y2="-19.22"
                    xlinkHref="#countings-linear-gradient"
                />
                <linearGradient
                    id="countings-linear-gradient-5"
                    x1="68.54"
                    y1="83.97"
                    x2="20.47"
                    y2="170.22"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#countings-linear-gradient"
                />
                <linearGradient
                    id="countings-linear-gradient-6"
                    x1="82.07"
                    y1="62.8"
                    x2="107.72"
                    y2="62.8"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#eaeaea"/>
                    <stop offset="1" stopColor="#a3a3a3"/>
                </linearGradient>
                <linearGradient
                    id="countings-linear-gradient-7"
                    x1="-312.67"
                    y1="496.34"
                    x2="-305.58"
                    y2="506.8"
                    gradientTransform="matrix(0.91, 0.58, -0.41, 0.91, 572.22, -204.95)"
                    xlinkHref="#countings-linear-gradient-6"
                />
                <linearGradient
                    id="countings-linear-gradient-8"
                    x1="-310.08"
                    y1="500.67"
                    x2="-301.95"
                    y2="505.21"
                    gradientTransform="matrix(0.91, 0.58, -0.41, 0.91, 572.22, -204.95)"
                    xlinkHref="#countings-linear-gradient-6"
                />
                <linearGradient
                    id="countings-linear-gradient-9"
                    x1="-761.81"
                    y1="755.58"
                    x2="-743.85"
                    y2="768.3"
                    gradientTransform="matrix(0.88, 0.31, -0.51, 0.89, 1132.83, -394.01)"
                    xlinkHref="#countings-linear-gradient"
                />
                <linearGradient
                    id="countings-linear-gradient-10"
                    x1="90.52"
                    y1="23.84"
                    x2="103.33"
                    y2="41.65"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#countings-linear-gradient"
                />
                <linearGradient
                    id="countings-linear-gradient-11"
                    x1="82.3"
                    y1="30.33"
                    x2="96.06"
                    y2="45.51"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01" stopColor="#fff"/>
                    <stop offset="1" stopColor="#fff" stopOpacity="0"/>
                </linearGradient>
            </defs>
            <path
                d="M151.19,120.13c-5.34,7.26-14.28,15.67-23.47,13.91-41.62-8-39.08,2.44-74.43-1-4.37-.44-9.33-1.08-15-2a32.31,32.31,0,0,1-14.89-6.6c-.74-.58-1.48-1.18-2.22-1.82-4.35-3.76-8.37-8.54-8.23-14.26.18-7.09,2.83-13.92,5.7-20.31,3.88-8.63,9.28-17.73,7.85-27.34C25.07,51.2,27,39.55,32,31.4c8-12.81,22-15.93,36-16.22,9.25-.19,18.77.36,27.2,4.17,8.86,4,16,11.4,25,14.83,6,2.26,12.56,2.7,18.43,5.25,7.39,3.21,13.09,9.57,16.79,16.73s5.56,15.11,6.95,23C164.76,92.55,159.21,109.24,151.19,120.13Z"
                transform="translate(-12.93 -15.15)"
                fill="#68e1fd"
                opacity="0.18"
                style={{isolation: "isolate"}}
            />
            <polygon points="71.91 47.09 37.87 39.82 37.87 29.29 38.31 29.29 38.31 39.46 72 46.66 71.91 47.09" fill="var(--color-blue)"/>
            <polygon points="115.97 65.82 108.77 63.65 109.97 42.31 117.16 44.47 115.97 65.82" fill="var(--color-blue)"/>
            <polygon points="124.36 68.34 117.17 66.18 117.77 55.51 124.96 57.67 124.36 68.34" fill="var(--color-pink)"/>
            <polygon points="132.57 70.81 125.38 68.64 127.05 38.82 134.25 40.98 132.57 70.81" fill="var(--color-green)"/>
            <polygon points="140.84 73.29 133.65 71.13 135.82 32.38 143.02 34.54 140.84 73.29" fill="var(--color-yellow)"/>
            <polygon points="115.97 65.82 108.77 63.65 109.97 42.31 117.16 44.47 115.97 65.82" fill="url(#countings-linear-gradient)"/>
            <polygon points="124.36 68.34 117.17 66.18 117.77 55.51 124.96 57.67 124.36 68.34" fill="url(#countings-linear-gradient-2)"/>
            <polygon points="132.57 70.81 125.38 68.64 127.05 38.82 134.25 40.98 132.57 70.81" fill="url(#countings-linear-gradient-3)"/>
            <polygon points="140.84 73.29 133.65 71.13 135.82 32.38 143.02 34.54 140.84 73.29" fill="url(#countings-linear-gradient-4)"/>
            <path
                d="M70.76,125.14l-12.63,5.69c-1.61.73-3.22,1.46-4.84,2.16-4.37-.44-9.33-1.08-15-2a32.31,32.31,0,0,1-14.89-6.6c-.74-.58-1.48-1.18-2.22-1.82l24.78-3.76a48.7,48.7,0,0,0,8.62-1.87c1-.34,5.89-.71,4.11,1.82a2.19,2.19,0,0,1-.89.63l-8.14,3.94A3.88,3.88,0,0,0,48,124.56a1.35,1.35,0,0,0,.25,1.87,1.91,1.91,0,0,0,1,.17c7.7-.23,15.13-3.26,22.84-3.31C72.47,124,71.54,124.79,70.76,125.14Z"
                transform="translate(-12.93 -15.15)"
                fill="url(#countings-linear-gradient-5)"
            />
            <path d="M82.07,67.33l7.85-14.19a.54.54,0,0,1,.57-.27l16.6,2.65a.78.78,0,0,1,.56,1.09l-8.9,16.12Z" transform="translate(-12.93 -15.15)" fill="url(#countings-linear-gradient-6)"/>
            <path d="M65.15,68.1l.21.74a.81.81,0,0,0,.5.55l14.85,5.5a1.24,1.24,0,0,0,.59.09l16.48-1a.61.61,0,0,0,.56-.66l0-.71Z" transform="translate(-12.93 -15.15)" fill="url(#countings-linear-gradient-7)"/>
            <path d="M81.64,67.23,89.49,53a.52.52,0,0,1,.56-.27l16.61,2.64a.78.78,0,0,1,.55,1.09L98.32,72.62Z" transform="translate(-12.93 -15.15)" fill="#473f47"/>
            <path d="M98.32,72.62,81,73.61a.86.86,0,0,1-.38-.06L65.15,68.1l16.49-.87Z" transform="translate(-12.93 -15.15)" fill="url(#countings-linear-gradient-8)"/>
            <polygon points="60.59 52.8 74.61 57.24 82.16 56.9 68.59 52.35 60.59 52.8" fill="#473f47"/>
            <polygon points="57.49 54.46 63.3 53.9 70.33 56.09 64.55 56.82 57.49 54.46" fill="#473f47"/>
            <path d="M71.31,70.28a1.92,1.92,0,0,0,.83.7c.5.19,2.66,1,2.66,1a.7.7,0,0,0,.6-.21Z" transform="translate(-12.93 -15.15)" fill="#eaeaea"/>
            <polygon points="69.27 51.73 84.8 56.83 93.06 41.52 76.62 38.42 69.27 51.73" fill="#fff"/>
            <path
                d="M91,56.8a1.63,1.63,0,0,1,.39.13.24.24,0,0,0,.17,0"
                transform="translate(-12.93 -15.15)"
                fill="none"
                stroke="#f8f8f8"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.12"
                opacity="0.43"
            />
            <path
                d="M56.77,115.91a1.86,1.86,0,0,0,1.9,3,.84.84,0,0,0,.36-.19c.23-.21.26-.56.47-.78a1.72,1.72,0,0,1,.77-.33,5.67,5.67,0,0,0,1.38-.72.83.83,0,0,0,.17-.15.59.59,0,0,0,.07-.25,1.82,1.82,0,0,0-.2-1.31,1.34,1.34,0,0,0-.46-.36,2.16,2.16,0,0,0-1.09-.32,3,3,0,0,0-.88.19,6.87,6.87,0,0,0-2.21,1.07"
                transform="translate(-12.93 -15.15)"
                fill="var(--color-lavender-dark)"
            />
            <path d="M58.32,112.35,57,116.8s.25,1.12,1.66.65,2.6-4,2.6-4Z" transform="translate(-12.93 -15.15)" fill="#fcbcb8"/>
            <path
                d="M72.42,121.65a8.13,8.13,0,0,1,1.1-.07,1.39,1.39,0,0,1,1,.45,1.34,1.34,0,0,1-.26,1.74,3.34,3.34,0,0,1-1.77.7,2.75,2.75,0,0,1-1.21,0c-.5-.14-1-.54-1.47-.4a2.34,2.34,0,0,0-.58.36,1.7,1.7,0,0,1-2.24-.27,2.25,2.25,0,0,1-.31-.69,2,2,0,0,1,.55-2.18c.78-.54,1.38-.17,2.13.08A7.34,7.34,0,0,0,72.42,121.65Z"
                transform="translate(-12.93 -15.15)"
                fill="var(--color-lavender-dark)"
            />
            <path d="M71.77,117.34l-2,4.63a2.79,2.79,0,0,1-1.91.36l-.8-.29.69-6.47Z" transform="translate(-12.93 -15.15)" fill="#fcbcb8"/>
            <path
                d="M86.28,70.24c.13-.17.3-.36.51-.33s.25.11.38.08a.45.45,0,0,0,.19-.15,6.54,6.54,0,0,1,1-.82,1.73,1.73,0,0,1,.77-.37,1,1,0,0,1,1,.55,1.65,1.65,0,0,1,.13,1.18,4.13,4.13,0,0,1-.48,1.1,3.55,3.55,0,0,1-.5.7,7.91,7.91,0,0,1-2.4,1.13.71.71,0,0,1-.28.09c-.17,0-.3-.15-.4-.29a2.79,2.79,0,0,1-.54-1.44A2.47,2.47,0,0,1,86.28,70.24Z"
                transform="translate(-12.93 -15.15)"
                fill="#fcbcb8"
            />
            <path
                d="M78.5,65.22a.41.41,0,0,1,.29,0,.86.86,0,0,1,.13.14c.17.17.45.18.67.27a1.1,1.1,0,0,1,.57.71,3.27,3.27,0,0,1,.11.94,1.32,1.32,0,0,1-.06.49.42.42,0,0,1-.37.29.8.8,0,0,1-.38-.16,1.11,1.11,0,0,0-.74-.11,4.65,4.65,0,0,0-.94.42.81.81,0,0,1-1.28-.4c-.28-.57-.58-1.22,0-1.69A4.13,4.13,0,0,1,78.5,65.22Z"
                transform="translate(-12.93 -15.15)"
                fill="#fcbcb8"
            />
            <path d="M60.7,86.5a63.68,63.68,0,0,0,.84,7.27c.54,2.39.87,5.1.87,5.1l-5,13.88s3,2,5,1.2,6.89-11.42,6.89-11.42l1.88-12.12-5-5.53Z" transform="translate(-12.93 -15.15)" fill="#473f47"/>
            <path d="M74.47,85.53S78,96.81,78,99.74s-4.27,17.35-4.27,17.35-4.63,1.52-5.71,0L67,115.57,69.57,101s-3.69-7.59-4.34-9.55a20.46,20.46,0,0,1-.85-3.68Z" transform="translate(-12.93 -15.15)" fill="#473f47"/>
            <path
                d="M73,54.65a7.33,7.33,0,0,1,.79,2.56,2.55,2.55,0,0,1-1.06,2.34,2.64,2.64,0,0,0-.63.38c-.67.67.1,1.87-.29,2.72a1.34,1.34,0,0,1-1.65.6,2.19,2.19,0,0,1-1.23-1.41,6.45,6.45,0,0,1-.22-1.91l-.07-3.83a4.83,4.83,0,0,1,.4-2.5,2.59,2.59,0,0,1,2.38-1.24C72.36,52.49,72.64,53.89,73,54.65Z"
                transform="translate(-12.93 -15.15)"
                fill="#fcbcb8"
            />
            <path
                d="M86.78,73.76s-7.59,6.56-8.13,6.56-3.91-4.12-3.91-4.12l-.27,9.33a22.79,22.79,0,0,1-2.92,1.4A13,13,0,0,1,66,88,11.08,11.08,0,0,1,60.7,86.5l2.27-22s4.3-3,5.77-2.53a9.42,9.42,0,0,0,3.18,0c2.12-.27,4.29,2.53,4.29,2.53l2.82,9,6.61-2.28A2.44,2.44,0,0,1,86.78,73.76Z"
                transform="translate(-12.93 -15.15)"
                fill="var(--color-lavender-dark)"
            />
            <path
                d="M72.33,50.49a7.38,7.38,0,0,0-3.48-1.08,4.87,4.87,0,0,0-3.8,1.07c-1.44,1.32-1.59,3.51-1.57,5.46s.05,4.09-1.16,5.61c-1,1.29-2.71,1.82-4,2.81a5.48,5.48,0,0,0-2.27,4c-.31,6,9.1,3.4,12.2,2.08a4.88,4.88,0,0,0,1.49-.89,4.13,4.13,0,0,0,1-1.69,8.68,8.68,0,0,0-.35-6.49c-.14-.3-.3-.67-.1-.94a1,1,0,0,1,.4-.27A2.16,2.16,0,0,0,72,58.18a3.53,3.53,0,0,0-.82-1.65,1.87,1.87,0,0,1-.38-1.75c.22-.47.74-.7,1.12-1a2.74,2.74,0,0,0,.62-3A1.23,1.23,0,0,0,72.33,50.49Z"
                transform="translate(-12.93 -15.15)"
                fill="#f7a463"
            />
            <path d="M74.74,76.2" transform="translate(-12.93 -15.15)" fill="#f7a463"/>
            <path d="M74.74,76.2l-.27,9.33a22.79,22.79,0,0,1-2.92,1.4l.7-13.79Z" transform="translate(-12.93 -15.15)" fill="#020202" opacity="0.1"/>
            <path d="M158.82,90.79,116.89,78l0-.3c.07-1.46,1.72-35.85,2.22-39.58l.77.11c-.48,3.51-2,35.72-2.2,39.2l41.35,12.59Z" transform="translate(-12.93 -15.15)" fill="var(--color-blue)"/>
            <g opacity="0.41">
                <polygon points="75.46 49.63 73.71 49.02 74.6 47.47 76.35 48.08 75.46 49.63" fill="var(--color-blue)"/>
            </g>
            <polygon points="77.2 50.24 75.45 49.63 77.02 46.87 78.77 47.49 77.2 50.24" fill="var(--color-pink)"/>
            <g opacity="0.5">
                <polygon points="78.93 50.86 77.18 50.24 79.49 46.21 81.24 46.83 78.93 50.86" fill="var(--color-lime)"/>
            </g>
            <polygon points="80.67 51.47 78.92 50.85 81.93 45.58 83.68 46.2 80.67 51.47" fill="var(--color-yellow)"/>
            <polygon points="82.41 52.08 80.66 51.46 84.38 44.95 86.13 45.56 82.41 52.08" fill="var(--color-purple-light)"/>
            <polygon points="114.84 65.44 107.64 63.28 108.84 41.94 116.04 44.1 114.84 65.44" fill="var(--color-blue)"/>
            <polygon points="123.24 67.97 116.04 65.81 116.64 55.13 123.84 57.3 123.24 67.97" fill="var(--color-pink)"/>
            <polygon points="131.45 70.43 124.25 68.27 125.92 38.45 133.12 40.61 131.45 70.43" fill="var(--color-lime)"/>
            <polygon points="139.72 72.92 132.52 70.76 134.69 32.01 141.89 34.17 139.72 72.92" fill="var(--color-yellow)"/>
            <path d="M61.25,42.37c0,3-1.94,5.3-4.35,5.1h0l-11.18-.94c-2.41-.2-4.36-2.81-4.36-5.84s2-5.3,4.36-5.1l1.6.13c0-.14,0-.29,0-.44,0-2.78,1.8-4.88,4-4.69s4,2.59,4,5.37h0a3.17,3.17,0,0,1,0,.44l1.61.14C59.3,36.74,61.25,39.35,61.25,42.37Z" transform="translate(-12.93 -15.15)" fill="#fff"/>
            <path
                d="M61.25,42.37c0,3-1.94,5.3-4.35,5.1h0l-11.18-.94c-2.41-.2-4.36-2.81-4.36-5.84s2-5.3,4.36-5.1l1.6.13c0-.14,0-.29,0-.44,0-2.78,1.8-4.88,4-4.69s4,2.59,4,5.37h0a3.17,3.17,0,0,1,0,.44l1.61.14C59.3,36.74,61.25,39.35,61.25,42.37Z"
                transform="translate(-12.93 -15.15)"
                fill="var(--color-blue)"
                opacity="0.53"
                style={{isolation: "isolate"}}
            />
            <path d="M59.84,42.81c0,3-1.94,5.3-4.35,5.1h0L44.31,47C41.9,46.77,40,44.16,40,41.13s1.95-5.3,4.36-5.1l1.6.13c0-.15,0-.29,0-.44,0-2.78,1.79-4.88,4-4.69s4,2.59,4,5.37h0c0,.15,0,.3,0,.44l1.6.14C57.89,37.18,59.84,39.79,59.84,42.81Z" transform="translate(-12.93 -15.15)" fill="#fff"/>
            <polygon points="79.76 42.79 75.44 34.92 76.78 26 77.22 26.07 75.9 34.84 80.15 42.58 79.76 42.79" fill="var(--color-pink)"/>
            <rect
                x="105.42"
                y="62.94"
                width="0.44"
                height="16.78"
                transform="translate(-4.67 138.43) rotate(-74.21)"
                fill="var(--color-blue)"
            />
            <path d="M95.4,27.92a4.17,4.17,0,0,1,2,1l2.55-3.57c-3.67-3.54-9.26-2.45-12.49,2.43a13,13,0,0,0-1.09,2l3.33,1.54C90.88,28.84,93.2,27.45,95.4,27.92Z" transform="translate(-12.93 -15.15)" fill="var(--color-blue)"/>
            <path d="M99.91,25.37l-2.55,3.57a4.17,4.17,0,0,0-2-1c-2.2-.47-4.52.92-5.74,3.43l-1-.46h0l.07-.16-2.32-1.07a13.18,13.18,0,0,1,1-1.85C90.65,22.92,96.24,21.83,99.91,25.37Z" transform="translate(-12.93 -15.15)" fill="url(#countings-linear-gradient-10)"/>
            <path d="M90.2,39l-2.54,3.56a8.81,8.81,0,0,1-.7-.94l2.31-3.25a7,7,0,0,1-1.16-5.81,8.08,8.08,0,0,1,.55-1.66l1,.47A7.52,7.52,0,0,0,89,33.17,7,7,0,0,0,90.2,39Z" transform="translate(-12.93 -15.15)" fill="var(--color-blue)"/>
            <path d="M91.72,45a7.37,7.37,0,0,1-4.06-2.47l1.61-4.19.93.62c1.92,2.38,5.21,2.2,7.33-.42s2.29-6.66.37-9a4.77,4.77,0,0,0-.53-.57L99,24.75l.93.61c3.67,3.55,4,10.38.78,15.27C98.46,44,95,45.7,91.73,45Z" transform="translate(-12.93 -15.15)" fill="#AB6993"/>
            <path d="M94.47,27.29a4.36,4.36,0,0,1,2,1L99,24.75c-3.67-3.54-9.26-2.46-12.49,2.43a12.37,12.37,0,0,0-1.08,2l3.32,1.55C90,28.22,92.28,26.82,94.47,27.29Z" transform="translate(-12.93 -15.15)" fill="var(--color-blue)"/>
            <path d="M89.27,38.35,87,41.6l-.23.31C84,38.76,83.48,33.6,85.4,29.18l1,.47,2.32,1.07-.07.16h0a7.8,7.8,0,0,0-.55,1.65A7,7,0,0,0,89.27,38.35Z" transform="translate(-12.93 -15.15)" fill="var(--color-blue)"/>
            <path d="M89.27,38.35,87,41.6l-.23.31C84,38.76,83.48,33.6,85.4,29.18l1,.47,2.32,1.07-.07.16h0a7.8,7.8,0,0,0-.55,1.65A7,7,0,0,0,89.27,38.35Z" transform="translate(-12.93 -15.15)" fill="url(#countings-linear-gradient-11)"/>
            <path d="M90.79,44.39a7.42,7.42,0,0,1-4.06-2.48l2.54-3.57c1.93,2.39,5.21,2.2,7.33-.41s2.3-6.67.37-9a4.11,4.11,0,0,0-.53-.57L99,24.74c3.67,3.54,4,10.38.78,15.27-2.23,3.37-5.7,5.06-9,4.36Z" transform="translate(-12.93 -15.15)" fill="var(--color-pink)"/>
        </svg>
    );
};

export default SvgCountings;
