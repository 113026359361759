import {Action, ActionMenuItem, ActionsMenu, ButtonOk, ButtonSize, IconTooltip, InputNumber, Tag, TagSize, TagStyle, TagTextTransform, TranslationLibFile} from "@sirdata/ui-lib";
import clsx from "clsx";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataType} from "../../api/model/segment/DataType";
import {Segment} from "../../api/model/segment/Segment";
import {SEGMENT_LIMIT_MAX_VALUE, SEGMENT_LIMIT_MIN_VALUE} from "../../api/model/storage-filter/segment/SegmentFilterSegment";
import {onKeyEnter} from "../../common/utils/form";
import {Formatter} from "../../common/utils/Formatter";
import {TranslationPortalFile} from "../../utils/constants";

type TagSegmentProps = {
    segment: Segment;
    limit?: number | null;
    isHighlighted?: boolean;
    onChangeLimit?: (limit: number | null) => void;
    onMoveFirstPosition?: () => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onRemove?: () => void;
};

const DEFAULT_SEGMENT_LIMIT = 10000;

const TagSegment: FunctionComponent<TagSegmentProps> = ({segment, limit, isHighlighted, onChangeLimit, onMoveFirstPosition, onMoveUp, onMoveDown, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textProcessCounting} = useTranslation(TranslationPortalFile.PROCESS_COUNTING);

    const [segmentLimit, setSegmentLimit] = useState<number>(limit || DEFAULT_SEGMENT_LIMIT);
    const [actionsMenuItems, setActionsMenuItems] = useState<ActionMenuItem[]>([]);

    const [isShowLimitField, setShowLimitField] = useState(false);

    useEffect(() => {
        let newActionsMenuItems: ActionMenuItem[] = [];
        if (!!onMoveFirstPosition) {
            newActionsMenuItems = [...newActionsMenuItems, {label: textProcessCounting("segment_actions.move_to_top"), onClick: onMoveFirstPosition}];
        }
        if (!!onChangeLimit) {
            newActionsMenuItems = [...newActionsMenuItems, {label: textProcessCounting(`segment_actions.${!!limit ? "edit_limit" : "define_limit"}`), onClick: () => setShowLimitField(true)}];
            if (!!limit) {
                newActionsMenuItems = [...newActionsMenuItems, {label: textProcessCounting("segment_actions.delete_limit"), onClick: () => {
                    onChangeLimit(null); setSegmentLimit(DEFAULT_SEGMENT_LIMIT);
                }}];
            }
        }
        if (!!onRemove) {
            newActionsMenuItems = [...newActionsMenuItems, {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, separator: true, critical: true}];
        }
        setActionsMenuItems(newActionsMenuItems);
    }, [segment, limit]); // eslint-disable-line

    const handleSubmitLimit = (limit: number) => {
        if (!onChangeLimit) return;
        onChangeLimit(limit);
        setShowLimitField(false);
    };

    const typeItem = DataType.getByName(segment.data_type);
    if (!typeItem) return (<></>);

    return (
        <div className={clsx("form-counting-segment", {"highlighted": isHighlighted})}>
            <div className="form-counting-segment-name">
                <Tag
                    icon={typeItem.icon}
                    label={typeItem.name}
                    textTransform={TagTextTransform.UPPERCASE}
                    customColor={{backgroundColor: typeItem.color, borderColor: typeItem.color}}
                />
                <span>{segment.name} ({segment.id})</span>
            </div>
            {(onChangeLimit && isShowLimitField) ?
                <div className="form-counting-segment-actions">
                    <InputNumber
                        value={segmentLimit}
                        minValue={SEGMENT_LIMIT_MIN_VALUE}
                        maxValue={SEGMENT_LIMIT_MAX_VALUE}
                        onChange={(value) => setSegmentLimit(value)}
                        onKeyDown={(e) => onKeyEnter(e, () => handleSubmitLimit(segmentLimit))}
                    />
                    <ButtonOk
                        cssClass="form-counting-segment-actions__button"
                        size={ButtonSize.XSMALL}
                        onClick={() => handleSubmitLimit(segmentLimit)}
                    />
                </div> :
                <div className="form-counting-segment-actions">
                    {limit && <Tag cssClass="form-counting-segment-actions__tag" label={Formatter.formatNumber(limit)} size={TagSize.XSMALL} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>}
                    {onMoveUp && <IconTooltip icon={{name: "arrow_upward"}} text={textProcessCounting("segment_actions.move_up")} onClick={onMoveUp}/>}
                    {onMoveDown && <IconTooltip icon={{name: "arrow_downward"}} text={textProcessCounting("segment_actions.move_down")} onClick={onMoveDown}/>}
                    {!!actionsMenuItems.length &&
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                        items={actionsMenuItems}
                    />
                    }
                </div>
            }
        </div>
    );
};

export default TagSegment;
