import {ApiModel} from "../../../../common/api/model/ApiModel";
import {StorageSortType} from "./StorageSortType";
import {StorageSortOrder} from "./StorageSortOrder";

export class StoragesQuery extends ApiModel {
    sort_by: string = StorageSortType.RECENCY.field;
    sort_order: string = StorageSortOrder.DESCENDANT;
    search: string = "";
    size: number = 500;
    page: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            search: this.search,
            size: this.size,
            page: this.page
        };
    }
}
