import {Rest} from "../../common/api/rest/Rest";
import {StorageFilter} from "../model/storage-filter/StorageFilter";
import {DimensionValue} from "../model/storage-filter/DimensionValue";
import {StorageFilterListQuery} from "../model/storage-filter/search/StorageFilterListQuery";

export class RestStorageFilter extends Rest {
    getStorageFilters(query: StorageFilterListQuery, storageId?: string): Promise<StorageFilter[]> {
        return this._client.get(storageId ? `customer/storage/${storageId}/filter` : "customer/filter", StorageFilter, {queryParams: query.getJsonParameters()}) as Promise<StorageFilter[]>;
    }

    getStorageFilter(storageId: string, storageFilterId: string): Promise<StorageFilter> {
        return this._client.get(`customer/storage/${storageId}/filter/${storageFilterId}`, StorageFilter) as Promise<StorageFilter>;
    }

    getDimensionValues(storageFilter: StorageFilter, dimensionIndex: number): Promise<DimensionValue[]> {
        const storageId = storageFilter.storage_id.toString();
        const filterId = storageFilter.id.toString();
        const dimension = dimensionIndex.toString();
        return this._client.get(`customer/storage/${storageId}/filter/${filterId}/dimension/${dimension}/values`, DimensionValue) as Promise<DimensionValue[]>;
    }

    createStorageFilter(storageFilter: StorageFilter): Promise<StorageFilter> {
        const storageId = storageFilter.storage_id.toString();
        return this._client.post(`customer/storage/${storageId}/filter`, storageFilter, StorageFilter) as Promise<StorageFilter>;
    }

    deleteStorageFilter(storageFilter: StorageFilter) {
        const storageId = storageFilter.storage_id.toString();
        const filterId = storageFilter.id.toString();
        return this._client.delete(`customer/storage/${storageId}/filter/${filterId}`);
    }

    downloadStorageFilter(storageFilter: StorageFilter): Promise<string> {
        const storageId = storageFilter.storage_id.toString();
        const filterId = storageFilter.id.toString();
        return this._client.get(`customer/storage/${storageId}/filter/${filterId}/counting`) as Promise<string>;
    }
}
