import {ModalNewSize} from "@sirdata/ui-lib";
import {ModalStep} from "./ModalStep";

export class UploadStorageStep extends ModalStep {
    static INDICATION: UploadStorageStep = new UploadStorageStep("INDICATION");
    static IMPORT_FILE: UploadStorageStep = new UploadStorageStep("IMPORT_FILE");
    static CONFIGURATION: UploadStorageStep = new UploadStorageStep("CONFIGURATION").withModalSize(ModalNewSize.MAX_WIDTH);
    static VALIDATION: UploadStorageStep = new UploadStorageStep("VALIDATION");
    static LOADING: UploadStorageStep = new UploadStorageStep("LOADING");
    static CONFIRMATION: UploadStorageStep = new UploadStorageStep("CONFIRMATION");

    static values(): UploadStorageStep[] {
        return [
            UploadStorageStep.INDICATION,
            UploadStorageStep.IMPORT_FILE,
            UploadStorageStep.CONFIGURATION,
            UploadStorageStep.VALIDATION,
            UploadStorageStep.LOADING,
            UploadStorageStep.CONFIRMATION
        ];
    }
}