import {Color, IconProps} from "@sirdata/ui-lib";

export class DataType {
    static INTENT: DataType = new DataType("Intent", Color.BLUE, {name: "ads_click", outlined: true});
    static INTEREST: DataType = new DataType("Interest", Color.PINK, {name: "lightbulb", outlined: true});
    static DECLARATIVE: DataType = new DataType("Declarative", Color.LIME, {name: "fact_check", outlined: true});
    static INFERRED: DataType = new DataType("Inferred", Color.YELLOW, {name: "data_usage", outlined: true});
    static MODELING: DataType = new DataType("Modeling", Color.PURPLE_LIGHT, {name: "view_in_ar", outlined: true});
    static LOCATION: DataType = new DataType("Location", Color.ORANGE, {name: "location_on", outlined: true});

    name: string;
    color: Color;
    icon: IconProps;

    constructor(name: string, color: Color, icon: IconProps) {
        this.name = name;
        this.color = color;
        this.icon = icon;
    }

    static values(): DataType[] {
        return [
            DataType.INTENT,
            DataType.INTEREST,
            DataType.DECLARATIVE,
            DataType.INFERRED,
            DataType.MODELING,
            DataType.LOCATION
        ];
    }

    static getByName(name: string): DataType | undefined {
        return this.values().find(((it) => it.name === name));
    }
}