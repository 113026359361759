import {ApiModel} from "../../../common/api/model/ApiModel";
import {DataType} from "./DataType";
import {Segment} from "./Segment";

export class Segments extends ApiModel {
    data_types: DataType[] = [];
    segments: Segment[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
